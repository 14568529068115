import React from 'react'
import dgnote from "../../assets/images/dgnote.jpg"

import Odex from "../../assets/images/ODex.jpg"

const Edo = () => {
  return (
    <>
    <div>
    <div className="content">
  <section className="cargo-consolidation">
    <div className="container">
      <div className="breadcrumb-wrap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.php">Home</a>
            </li>
            <li className="breadcrumb-item">Digital Offerings</li>
            <li className="breadcrumb-item active" aria-current="page">
              E-DO
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-md-flex justify-content-between">
            <div className="d-md-flex align-items-center">
              <h2 className="text-capitalize">
                {/*<span class="global-icn">*/}
                {/*	<img src="assets/images/global-icon.svg">*/}
                {/*</span>*/}
                E-DO
              </h2>
              {/*<h6 style="padding-left: 20px;" class="mb-0">Pay import invoices and receive electronic delivery order online</h6>*/}
            </div>
            <div className="btn-wrap">
              <a
                href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                className="btn btn-book btn-primary"
              >
                <span>
                  <img src="https://teamglobal.in/assets/images/book-icon.svg"  alt='book'/>
                </span>
                Book Now
              </a>
              {/*<a href="#" class="btn btn-primary">*/}
              {/*	<span>*/}
              {/*		<img src="assets/images/Quote-icon.svg">*/}
              {/*	</span>Request A Quote*/}
              {/*</a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="e-do-content">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <span className="e-do">
            <a href="https://www.dgnote.com/site/index">
              <img src={dgnote} className="img-fluid" alt="dgnote"/>
            </a>
            <h4>Dgnote</h4>
            <p className="desc">
              DgNote is a tech-enabled process efficiency and automation
              services provider.
            </p>
            <div className="booking-link">
              <a
                href="https://www.dgnote.com/site/index"
                className="btn btn-primary Check-btn"
              >
                Login
              </a>
            </div>
          </span>
        </div>
        <div className="col-md-6">
          <span className="e-do">
            <a href="http://odex.co/e-Do.php">
              <img src={Odex} className="img-fluid" alt='Odex'/>
            </a>
            <h4>ODex</h4>
            <p className="desc">
              ODeX one stop solution for the entire shipping industry to receive
              documents and make payments electronically.
            </p>
            <div className="booking-link">
              <a
                href="http://odex.co/e-Do.php"
                className="btn btn-primary Check-btn"
              >
                Login
              </a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </section>
</div>

    </div>
    </>
  )
}

export default Edo
