import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import member1 from "../../assets/images/about/member1.png";
import member2 from "../../assets/images/about/member2.png";
import member3 from "../../assets/images/about/member3.png";
import member4 from "../../assets/images/about/member4.png";
import member6 from "../../assets/images/about/member6.png";
import member8 from "../../assets/images/about/member8.png";
import member11 from "../../assets/images/about/member11.png";
import jacob from "../../assets/images/about/jacob-thomas.jpg";
import rampal from "../../assets/images/about/rampal-tandon.jpg";
import sandeep from "../../assets/images/about/sandeep.png";
import manoj from "../../assets/images/about/manoj-goplani.png";
import gayatri from "../../assets/images/about/gayatri-shroff.jpg";
import sujit from "../../assets/images/about/sujit-baral.jpg";
import naveen from "../../assets/images/about/naveen.png";
import teamglobal from "../../assets/images/teamGloble.jpg";
import Miyawaki4 from "../../assets/images/Miyawaki4.jpg";
import Miyawaki2 from "../../assets/images/Miyawaki2.jpg";
import Miyawaki from "../../assets/images/Miyawaki.jpg";
import ButterflyGarden from "../../assets/images/Butterfly-garden.jpg";
import biogas from "../../assets/images/biogas.jpg";
import SolarPanel from "../../assets/images/SolarPanel.JPG";


const Success = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Success";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Success";
  }, []);

  return (
    <>
      <div className="content">
        <section className="contact-head">
          <div className="cargo-consolidation about-txt">
            <div className="container">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <a href="/">Home</a>
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Sustainability
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      ESG Success Stories
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex align-items-center">
                    <h2 className="text-capitalize">ESG Success Stories</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-tabs-sec">
          <div className="container">
            <div className="">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    href="esg-framework"
                    className="nav-link"
                    id="esg-framework-tab"
                  >
                    Framework
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-governance-structure-and-roadmap"
                    className="nav-link"
                    id="esg-governance-structure-and-roadmap-tab"
                  >
                    Governance Structure and Roadmap
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="esg-focus-area-and-goals"
                    className="nav-link "
                    id="esg-focus-area-and-goals-tab"
                  >
                    {" "}
                    Focus Area and Goals
                  </a>
                </li>

                {/*                 
                <li className="nav-item">
                  <a
                    href="policies-and-commitments"
                    className="nav-link "
                    id="policies-and-commitments"
                  >
                    POLICIES AND COMMITMENTS
                  </a>
                </li> */}

                <li className="nav-item">
                  <a className="nav-link " href="leadership-commitment">
                    Leadership Commitment
                  </a>
                </li>
                {/*
                <li className="nav-item">
                  <a
                    href="esg-milestone"
                    className="nav-link "
                    id="esg-milestone-tab"
                  >
                    Milestones
                  </a>
              </li> */}

                <li className="nav-item">
                  <a
                    href="success-stories"
                    className="nav-link active"
                    id="success-stories-tab"
                  >
                    Success Stories
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show "
                  id="tablevel1"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="about-tab-content">
                    {/* <h2 className="team-title text-capitalize text-center">
                      As Teamglobal, we strive to offer our customers a well
                      defined and a well structured logistics services.
                    </h2> */}
                    <h2 className="team-title text-capitalize text-center">
                      ESG Success Stories
                    </h2>
                    <div className="row">
                      <div className="col-md-12">
                        <figure className="about-banner">
                          <img src={teamglobal} alt="Team Photo" />
                        </figure>
                      </div>
                      <div className="col-md-12">
                        <h4 className="post-title">
                          Teamglobal has been certified and affiliated with
                          listed agencies and associations
                        </h4>
                        <p className="">
                          {" "}
                          Teamglobal is an integrated Logistics services
                          provider headquartered in India. We are offering
                          services in multiple segments of logistics Viz. Sea
                          Freight, Airfreight, Project Cargo Transportation,
                          Cargo Terminals and Coastal Shipping. It has 18
                          offices in India, Bangladesh, Kenya and Tanzania.
                        </p>
                        <h6 className="text-capitalize">accreditation</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        {/* <div className="col-md-12">
                                    <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                    <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                    <h6 className="text-capitalize">accreditation</h6>


                                </div> */}
                      </div>
                    </div>
                    {/* <!--<h2 className="team-title text-capitalize text-center">Our Team</h2>--> */}
                    <div className="team-wrap">
                      <h4 className="team-title text-capitalize text-start">
                        Committee Leaders
                      </h4>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member1} alt="" />
                            </figure>
                            <strong className="d-block">Nityam Khosla</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member2} alt="" />
                            </figure>
                            <strong className="d-block">Vivek Kele</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member3} alt="" />
                            </figure>
                            <strong className="d-block">
                              Rajesh Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Export LCL Console
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member4} alt="" />
                            </figure>
                            <strong className="d-block">
                              Hemant Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Logistics
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={jacob} alt="" />
                            </figure>
                            <strong className="d-block">Jacob Thomas</strong>
                            <span className="user-pos">
                              Director - Commercial
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member6} alt="" />
                            </figure>
                            <strong className="d-block">Tejan Lakhia</strong>
                            <span className="user-pos">
                              Sr. VP - FCL Sea Freight
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                      <h4 className="team-title text-capitalize text-start">
                        {" "}
                        Committee Member{" "}
                      </h4>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member11} alt="" />
                            </figure>
                            <strong className="d-block">Shibu Paul</strong>
                            <span className="user-pos">
                              {" "}
                              Sr. VP - WWA Business Development
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={rampal} alt="" />
                            </figure>
                            <strong className="d-block">Rampal Tandon</strong>
                            <span className="user-pos">
                              VP - Freight Management
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member8} alt="" />
                            </figure>
                            <strong className="d-block">
                              Gaurang Timbadiya
                            </strong>
                            <span className="user-pos">VP - Finance</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
                        </div> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sandeep} alt="" />
                            </figure>
                            <strong className="d-block">
                              Sandeep Palodkar
                            </strong>
                            <span className="user-pos">
                              VP - HR/IR, Admin, Business Excellence
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={manoj} alt="" />
                            </figure>
                            <strong className="d-block">Manoj Gopalani</strong>
                            <span className="user-pos">
                              VP - Information Technology
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={gayatri} alt="" />
                            </figure>
                            <strong className="d-block">Gayatri Shroff</strong>
                            <span className="user-pos">GM - Commercial</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sujit} alt="" />
                            </figure>
                            <strong className="d-block">Sujit Baral</strong>
                            <span className="user-pos">
                              GM - Regional Head –South
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={naveen} alt="" />
                            </figure>
                            <strong className="d-block">Naveen Kanaujia</strong>
                            <span className="user-pos">GM – Imports</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                      <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                        <div classNameName="col-md-6">
                          {/* <ol classNameName=" ">
                                    <li className="">POSH</li>
                                    <li className="">Cyber Security</li>
                                    <li className="">Human Resource</li>
                                    <li className="">IT Policy</li>
                                    <li className="">ESG Policy</li>
                                </ol> */}

                          <ol className="list-group">
                            <li className="list-group-item">
                              POSH
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              Cyber Security
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              Human Resource
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              IT Policy
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              ESG Policy
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                      <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                        <div classNameName="col-md-6">
                          <h6 className="text-capitalize">Focus Area</h6>

                          <p className="">
                            {" "}
                            Teamglobal is an integrated Logistics services
                            provider headquartered in India. We are offering
                            services in multiple segments of logistics Viz. Sea
                            Freight, Airfreight, Project Cargo Transportation,
                            Cargo Terminals and Coastal Shipping. It has 18
                            offices in India, Bangladesh, Kenya and Tanzania.
                          </p>
                        </div>

                        <div classNameName="col-md-6">
                          <h6 className="text-capitalize">Goals</h6>

                          <p className="">
                            {" "}
                            Teamglobal is an integrated Logistics services
                            provider headquartered in India. We are offering
                            services in multiple segments of logistics Viz. Sea
                            Freight, Airfreight, Project Cargo Transportation,
                            Cargo Terminals and Coastal Shipping. It has 18
                            offices in India, Bangladesh, Kenya and Tanzania.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                   <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                      </div>
                      <div className="team-wrap">
                        <div classNameName="row">
                          {/* <h4 class="milest-title text-center">2023</h4> */}
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2023</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2022</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2021</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2020</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2019</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2018</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2017</span>
                            </b>
                          </div>

                          <div classNameName="col-md-4">
                            <b>
                              <span>-2016</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2015</span>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      {/* <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2> */}
                      <h2 className="team-title text-capitalize text-center">
                        ESG Success Stories
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h5
                            className="text-capitalize text-center mt-2"
                            style={{ fontWeight: "bold" }}
                          >
                            Environmental Initiatives
                          </h5>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                   <h6 className="text-capitalize">accreditation</h6>  */}
                        </div>
                      </div>
                      {/* <div className="team-wrap">
                        <div classNameName="row">
                        
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2023</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2022</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2021</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2020</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2019</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2018</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2017</span>
                            </b>
                          </div>

                          <div classNameName="col-md-4">
                            <b>
                              <span>-2016</span>
                            </b>
                          </div>
                          <div classNameName="col-md-4">
                            <b>
                              <span>-2015</span>
                            </b>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="container">
                      <div className="about-tab-content milestones-wrapper">
                         
                              </div>
                              </div> */}

                      <div className="about-tab-content milestones-wrapper">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="miles-point">
                              {/* <h6>2022</h6> */}
                              {/* <p class="desc"> */}
                              <div class="row">
                                {/* <h6>2023</h6> */}
                                <div class="col-md-2 how-img">
                                  <img
                                    src={Miyawaki4}
                                    class="rounded-circle img-fluid"
                                    alt="Miyawaki Forests and Butterfly Garden"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div class="col-md-10">
                                  <h4>
                                    Miyawaki Forests and Butterfly Garden:
                                  </h4>
                                  {/* <h4 class="subheading">GetLance is a great place to find more clients, and to run and grow your own freelance business.</h4> */}
                                  <p class="text-muted">
                                    Globicon Terminals’ commitment to
                                    sustainability is illustrated through the
                                    establishment of Miyawaki forests and a
                                    butterfly garden within its operational
                                    space. These green initiatives not only
                                    contribute to environmental conservation but
                                    also create a positive and rejuvenating
                                    atmosphere for all stakeholders. By
                                    embracing such practices, Globicon Terminals
                                    sets a commendable example for businesses
                                    looking to integrate eco-friendly solutions
                                    into their operations. Globicon Terminals
                                    has established two Miyawaki forests, each
                                    contributing to the company's commitment to
                                    environmental stewardship.
                                  </p>

                                  {/*   <h6 style={{ marginBottom: "50px" }}>
                                    Starc
                                  </h6> */}
                                </div>
                                <br />
                                <br />
                                <br />
                                <div class="col-md-2 how-img">
                                  <img
                                    src={Miyawaki}
                                    class="rounded-circle img-fluid"
                                    alt="Miyawaki Forest 1"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div class="col-md-10">
                                  <h4>Miyawaki Forest 1:</h4>
                                  <p class="text-muted">
                                    The first Miyawaki forest at Globicon
                                    Terminals covers an impressive area of 3150
                                    square feet, making it a substantial green
                                    space within the industrial complex. A total
                                    of 882 trees have been meticulously planted
                                    in this area, following the Miyawaki
                                    method—a Japanese afforestation technique
                                    known for its ability to create thriving,
                                    self-sustaining ecosystems in a relatively
                                    short time.
                                  </p>
                                  {/*  <h6 style={{ marginBottom: "50px" }}>
                                    Danny
                                  </h6> */}
                                </div>
                              </div>
                            </div>

                            <div className="miles-point">
                              <div class="row">
                                {/* <h6>2022</h6> */}
                                <div class="col-md-2 how-img">
                                  <img
                                    src={Miyawaki2}
                                    class="rounded-circle img-fluid"
                                    alt="Miyawaki Forest 2"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div class="col-md-10">
                                  <h4>Miyawaki Forest 2:</h4>
                                  <p class="text-muted">
                                    Building on the success of the first
                                    initiative, Globicon Terminals has extended
                                    its green footprint with a second Miyawaki
                                    forest. Spanning an area of 3546 square
                                    feet, this forest is home to 900 trees
                                    planted using the Miyawaki method. The
                                    expansion of this initiative showcases the
                                    company's commitment to creating resilient
                                    and diverse ecosystems within its
                                    operational space.
                                  </p>
                                </div>
                              </div>
                              <br />
                              <br />
                              <br />

                              <div class="row">
                                <div class="col-md-2 how-img">
                                  <img
                                    src={ButterflyGarden}
                                    class="rounded-circle img-fluid"
                                    alt="Butterfly Garden"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div class="col-md-10">
                                  <h4>Butterfly Garden:</h4>
                                  <p class="text-muted">
                                    In addition to the Miyawaki forests,
                                    Globicon Terminals has also established a
                                    vibrant butterfly garden within its
                                    premises. Covering an area of 946 square
                                    feet, this garden is adorned with 250 flower
                                    shrubs, creating a welcoming habitat for
                                    butterflies and other pollinators.
                                  </p>
                                  {/*<h6>William Shakespeare</h6>*/}
                                </div>
                              </div>
                            </div>
                            <div className="miles-point">
                              <div class="row">
                                {/* <h6>2022</h6> */}
                                <div class="col-md-2 how-img">
                                  <img
                                    src={biogas}
                                    class="rounded-circle img-fluid"
                                    alt="Miyawaki Forest 2"
                                    style={{
                                      objectFit: "Cover",
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div class="col-md-10">
                                  <h4>Bio Gas:</h4>
                                  <p class="text-muted">
                                    Globicon Terminals has installed a
                                    state-of-the-art biogas plant to harness
                                    energy from the wet waste generated in its
                                    canteen. The plant has a remarkable capacity
                                    to process 40 kilograms of wet waste daily,
                                    ensuring efficient utilization of organic
                                    material that would otherwise contribute to
                                    landfills. By converting this waste into
                                    biogas, the company not only addresses waste
                                    management concerns but also promotes a
                                    sustainable energy source.
                                  </p>
                                </div>
                              </div>
                              <br />
                              <br />
                              <br />

                              <div class="row">
                                <div class="col-md-2 how-img">
                                  <img
                                    src={SolarPanel}
                                    class="rounded-circle img-fluid"
                                    alt="Butterfly Garden"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div class="col-md-10">
                                  <h4>Solar Power:</h4>
                                  <p class="text-muted">
                                    Globicon Terminals has embraced solar power
                                    as a key component of its energy strategy.
                                    With a total solar panel capacity of 750
                                    kilowatts (KW), the company is not only
                                    reducing its carbon footprint but also
                                    actively contributing to the broader shift
                                    towards renewable energy. This initiative,
                                    featuring net metering, grid contribution,
                                    and meticulous monitoring, exemplifies
                                    Globicon Terminals' commitment to
                                    environmentally conscious business
                                    practices.
                                  </p>
                                  {/*<h6>William Shakespeare</h6>*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Success;
