import React from "react";

const Videos = () => {
  return (
    <>
      {/* <li classNameName="nav-item">
								<a classNameName="nav-link" href="#"> 
									Media
								<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" className="svg-inline--fa fa-angle-left fa-w-6 fa-2x"><path fill="#333333" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" classNameName=""></path></svg>	
								</a>
									<ul className="sub-menu">           

									<li><a href="videos.php">Videos</a></li>
									</ul>								
							</li> */}

      <div className="content">
        {/* <section classNameName="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="..//">
                      <a href="/">Home</a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="../videos">
                      <a href="videos.php?page=1">Videos</a>
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize">
                    {" "}
                    Awards and Accomplishments
                  </h2>
                  <div className="btn-wrap">
                    <form
                      className="news-search"
                      action="search-videos.php"
                      method="GET"
                    >
                      <div className="d-md-flex align-items-center justify-content-between">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="searchinput"
                            id="inputCity"
                            placeholder="Search specific"
                          />
                          
                          <button type="submit" className="search-btn">
                            <Link to="../search">
                              <img src={search} />
                            </Link>
                          </button>
                        </div>
                        <div className="form-group ">
                          <select id="inputState" className="form-control">
                            <option selected="">Sort By Date</option>
                            <option>...</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section class="cargo-consolidation">
					<div class="container">
						<div class="breadcrumb-wrap">
							<nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li> 
								<li class="breadcrumb-item active" aria-current="page">Videos</li>
							  </ol>
							</nav>
						
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="d-md-flex justify-content-between">
									<h2 class="text-capitalize"> Awards and Accomplishments</h2>
									<div class="btn-wrap">
										{/* <form class="news-search" action="search-videos.php" method="GET">
   
   
										  <div class="d-md-flex align-items-center justify-content-between">
											<div class="form-group"> 
                      <input
                            type="text"
                            className="form-control"
                            name="searchinput"
                            id="inputCity"
                            placeholder="Search specific"
                          />
											
                      <button type="submit" className="search-btn">
                            <Link to="../search">
                              <img src={search} />
                            </Link>
                          </button>
											</div>
											<div class="form-group "> 
											  <select id="inputState" class="form-control">
												<option selected>Sort By Date</option>
												<option>...</option>
											  </select>
											</div>
											 
										  </div>
										</form> */}
										
									</div>
								</div>
							</div>						
						</div>
						
					</div>
				</section>    
        <section className="recent-news-sec">
          <div className="container">
            <div className="post-row">
              <div className="row">
                <div className="col-md-6 ">
                  <iframe
                   title="youtube"
                    allowfullscreen=""
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/PiAaEqLu9V8"
                  ></iframe>
                  <div className="news-details">
                    <p>
                      <span>22 June 2021 - by Teamglobal</span>
                    </p>
                    <h2>Awards 2018 - ET Now 6th Samudra Manthan</h2>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <iframe
                   title="youtube"
                    allowfullscreen=""
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/3FZ8Wv-Pspc"
                  ></iframe>
                  <div className="news-details">
                    <p>
                      <span>22 June 2021 - by Teamglobal</span>
                    </p>

                    <h2>MALA AWARDS 2016</h2>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <iframe
                   title="youtube"
                    allowfullscreen=""
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/iLyi0eZjM1w"
                  ></iframe>
                  <div className="news-details">
                    <p>
                      <span>22 June 2021 - by Teamglobal</span>
                    </p>
                    <h2>MALA Awards 2015</h2>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <iframe
                  title="youtube"
                    allowfullscreen=""
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/2fO_QNxeE_0"
                  ></iframe>
                  <div className="news-details">
                    <p>
                      <span>22 June 2021 - by Teamglobal</span>
                    </p>
                    <h2>MALA Awards 2014</h2>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <iframe
                   title="youtube"
                    allowfullscreen=""
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/HVtXFoi9MeU"
                  ></iframe>
                  <div className="news-details">
                    <p>
                      <span>22 June 2021 - by Teamglobal</span>
                    </p>

                    <h2>MALA Awards 2013</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="recent-news-sec"
          style={{ backgroundColor: " #F4FAFF", paddingTop: "50px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h4 className="text-capitalize">CSR Activity</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "#F4FAFF " }}>
          <section className="recent-news-sec" style={{ marginTop: "0" }}>
            <div className="container">
              <div className="post-row">
                <div className="row">
                  <div className="col-md-6 ">
                    <iframe
                      allowfullscreen=""
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/WpVq3btbCFI"
                    ></iframe>
                    <div className="news-details">
                      <h2>Teamglobal Cochin</h2>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <iframe
                      allowfullscreen=""
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/rzxbyn0PJNU"
                    ></iframe>
                    <div className="news-details">
                      <h2>Teamglobal Delhi</h2>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <iframe
                      allowfullscreen=""
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/0b7JmIKeWtc"
                    ></iframe>
                    <div className="news-details">
                      <h2>Teamglobal Kolkata</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </>
  );
};
export default Videos;
