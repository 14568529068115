import React, { useEffect } from "react";
import photo from "../../assets/images/about/team-photo.jpg";

const Carrer = () => {
  return (
    <>
    <div className="content">
      <section className="cargo-consolidation">
        <div className="container">
          <div className="breadcrumb-wrap">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="./">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Career
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-md-flex justify-content-between">
                <h2 className="text-capitalize"> Careers</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-tabs-sec pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <figure class="about-banner">
                <img src={photo} alt="" />
              </figure>
            </div>
            <div className="col-md-6">
              <h4 className="post-title">
                Teamglobal is a leading logistic service provider in India and
                offers services to and from destinations globally by sea and by
                air
              </h4>
              <p class="">
                {" "}
                The management of Teamglobal understands and appreciates that
                employee talent is the key for our company’s long-term
                development. Owing to this, the management of Teamglobal is
                determined to become model employer trusted by our employees
                across geographies, culture and back ground. teamglobal, a
                people-oriented company, concentrates on the win-win situation
                of the company and its employees and looks at it as our
                company’s main strategic objective. Teamglobal, the platform for
                passion and dream.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="teamglobal-logistics">
        <div class="container">
          <div class="current-openings">
            <div class="text-center">
              <h4>Teamglobal Logistics Pvt Ltd – Current Openings</h4>
            </div>
            <div class="box">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Sr.no</th>
                    <th scope="col" class="cust-width-td">Position Title</th>
                    <th scope="col">Office Location</th>
                    <th scope="col">Experience Required</th>
                    <th scope="col">Upload Your Resume On</th>
                  </tr>
                </thead>
                <tbody>

                  <tr><td scope="row">01</td> <td>FCL Export Sales</td> <td>Bangalore</td> <td>5 to 8 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=FCL Export Sales - Bangalore (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">02</td> <td>All Product Sales</td> <td>Baroda</td> <td>5 to 7 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=All Product Sales - Baroda (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">03</td> <td>Air Sales </td> <td>Chennai</td> <td>5 to 8 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=Air Sales  - Chennai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">04</td> <td>Export Operations </td> <td>Chennai</td> <td>1 to 2 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=Export Operations  - Chennai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">05</td> <td>LCL Export Customer Service</td> <td>Hyderabad</td> <td>2 to 3 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=LCL Export Customer Service - Hyderabad (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">06</td> <td>Integrated Logistics Sales</td> <td>Mumbai</td> <td>2 to 4 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=Integrated Logistics Sales - Mumbai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">07</td> <td>Import Documentation</td> <td>Mumbai</td> <td>1 to 2 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=Import Documentation - Mumbai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">08</td> <td>All Product Sales</td> <td>Mumbai</td> <td>1 to 6 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=All Product Sales - Mumbai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">09</td> <td>Air Export Operations</td> <td>Mumbai</td> <td>1 to 2 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=Air Export Operations - Mumbai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">10</td> <td>LCL Import Sales</td> <td>Mumbai</td> <td>2 to 5 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=LCL Import Sales - Mumbai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>
                  <tr><td scope="row">11</td> <td>FCL Export Sales</td> <td>Mumbai</td> <td>2 to 3 Years</td> <td><a href="mailto:meghnas@teamglobal.in?subject=FCL Export Sales - Mumbai (upload your resume)" target="_blank">meghnas@teamglobal.in</a></td></tr>





                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};
export default Carrer;
