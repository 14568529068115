import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import member1 from "../../assets/images/about/member1.png"
import member2 from "../../assets/images/about/member2.png"
import member3 from "../../assets/images/about/member3.png"
import member4 from "../../assets/images/about/member4.png"
import member6 from "../../assets/images/about/member6.png"
import member8 from "../../assets/images/about/member8.png"
import member11 from "../../assets/images/about/member11.png"
import jacob from "../../assets/images/about/jacob-thomas.jpg"
import rampal from "../../assets/images/about/rampal-tandon.jpg"
import sandeep from "../../assets/images/about/sandeep.png"
import manoj from "../../assets/images/about/manoj-goplani.png"
import gayatri from "../../assets/images/about/gayatri-shroff.jpg"
import sujit from "../../assets/images/about/sujit-baral.jpg"
import naveen from "../../assets/images/about/naveen.png"
import teamglobal from "../../assets/images/teamGloble.jpg";


import Twlogo from '../../assets/images/TW-Logo.svg';


const Policy = () => {
    useEffect(() => {

        const metaDescriptionTag = document.querySelector(
            'head > meta[name="description"]'
          );
          document.title="Policy";
         
         // console.log("metaDescriptionTag",metaDescriptionTag);
             metaDescriptionTag.content = "Policy";
          
    
    },[]);

    return (
        <>

            <div className="content">

                <section className="contact-head">
                    <div className="cargo-consolidation about-txt">
                        <div className="container">
                            <div className="breadcrumb-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                        <Link to="../sustainability">
                                            
                                            <a href="/">Sustainability</a>
                                            </Link>
                                            </li>
                                        <li className="breadcrumb-item active" aria-current="page">POLICIES AND COMMITMENTS

                                        </li>
                                    </ol>
                                </nav>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-md-flex align-items-center">
                                        <h2 className="text-capitalize">POLICIES AND COMMITMENTS

                                        </h2>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                <section className="page-tabs-sec">
                    <div className="container">
                        <div className="">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link " href="context-and-message">CONTEXT  AND MESSAGE</a>
                                </li>
                                <li className="nav-item">
                                    <a href="esg-governance-structure-and-roadmap" className="nav-link" id="esg-governance-structure-and-roadmap-tab"> Esg Governance Structure and Roadmap</a>
                                </li>

                                {/* <li className="nav-item">
                                    <a href="policies-and-commitments" className="nav-link active" id="policies-and-commitments">POLICIES AND COMMITMENTS</a>
                                </li> */}

                                <li className="nav-item">
                                    <a href="esg-framework" className="nav-link" id="esg-framework-tab">ESG FRAMEWORK</a>
                                </li>
                                <li className="nav-item">
                                    <a href="esg-milestone" className="nav-link" id="esg-milestone-tab">Esg Milestones</a>
                                </li>
                                <li className="nav-item">
                                    <a href="success-stories" className="nav-link" id="success-stories-tab">SUCCESS STORIES</a>
                                </li>

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show " id="tablevel1" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="about-tab-content">
                                        <h2 className="team-title text-capitalize text-center">As Teamglobal, we strive to offer our customers a well defined and a well structured logistics services.</h2>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <figure className="about-banner"><img src={teamglobal} alt="Team Photo" /></figure>
                                            </div>
                                            <div className="col-md-12">
                                                <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                                <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                <h6 className="text-capitalize">accreditation</h6>


                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="tab-pane fade show " id="tablevel2" role="tabpanel" aria-labelledby="team-tab">
                                    <div className="team-tab-content">
                                        <div className="about-tab-content">
                                            <h2 className="team-title text-capitalize text-center">As Teamglobal, we strive to offer our customers a well defined and a well structured logistics services.</h2>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <figure className="about-banner"><img src={teamglobal} alt="Team Photo" /></figure>
                                                </div>
                                                {/* <div className="col-md-12">
                                    <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                    <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                    <h6 className="text-capitalize">accreditation</h6>


                                </div> */}
                                            </div>

                                        </div>
                                        {/* <!--<h2 className="team-title text-capitalize text-center">Our Team</h2>--> */}
                                        <div className="team-wrap">
                                            <h4 className="team-title text-capitalize text-start">Committee Leaders</h4>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member1} alt="" /></figure>
                                                        <strong className="d-block">Nityam Khosla</strong>
                                                        <span className="user-pos">Director</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member2} alt="" /></figure>
                                                        <strong className="d-block">Vivek Kele</strong>
                                                        <span className="user-pos">Director</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member3} alt="" /></figure>
                                                        <strong className="d-block">Rajesh Bhanushali</strong>
                                                        <span className="user-pos">Director - Export LCL Console</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member4} alt="" /></figure>
                                                        <strong className="d-block">Hemant Bhanushali</strong>
                                                        <span className="user-pos">Director - Logistics</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={jacob} alt="" /></figure>
                                                        <strong className="d-block">Jacob Thomas</strong>
                                                        <span className="user-pos">Director - Commercial</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member6} alt="" /></figure>
                                                        <strong className="d-block">Tejan Lakhia</strong>
                                                        <span className="user-pos">Sr. VP - FCL Sea Freight</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="team-title text-capitalize text-start"> Committee Member  </h4>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member11} alt="" /></figure>
                                                        <strong className="d-block">Shibu Paul</strong>
                                                        <span className="user-pos"> Sr. VP - WWA Business Development</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={rampal} alt="" /></figure>
                                                        <strong className="d-block">Rampal Tandon</strong>
                                                        <span className="user-pos">VP - Freight Management</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>



                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={member8} alt="" /></figure>
                                                        <strong className="d-block">Gaurang Timbadiya</strong>
                                                        <span className="user-pos">VP - Finance</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
                                      </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={sandeep} alt="" /></figure>
                                                        <strong className="d-block">Sandeep Palodkar</strong>
                                                        <span className="user-pos">VP - HR/IR, Admin, Business Excellence</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={manoj} alt="" /></figure>
                                                        <strong className="d-block">Manoj Gopalani</strong>
                                                        <span className="user-pos">VP - Information Technology</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={gayatri} alt="" /></figure>
                                                        <strong className="d-block">Gayatri Shroff</strong>
                                                        <span className="user-pos">GM - Commercial</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={sujit} alt="" /></figure>
                                                        <strong className="d-block">Sujit Baral</strong>
                                                        <span className="user-pos">GM - Regional Head –South</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="business-card text-center">
                                                        <figure><img src={naveen} alt="" /></figure>
                                                        <strong className="d-block">Naveen Kanaujia</strong>
                                                        <span className="user-pos">GM – Imports</span>
                                                        {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="tab-pane fade show active" id="tablevel2" role="tabpanel" aria-labelledby="team-tab">
                                    <div className="team-tab-content">
                                        <div className="about-tab-content">
                                            <h2 className="team-title text-capitalize text-center">As Teamglobal, we strive to offer our customers a well defined and a well structured logistics services.</h2>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <figure className="about-banner"><img src={teamglobal} alt="Team Photo" /></figure>
                                                </div>
                                                <div className="col-md-12">
                                                    <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                                    {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                    <h6 className="text-capitalize">accreditation</h6> */}


                                                </div>
                                                <div classNameName="col-md-6">
                                                    {/* <ol classNameName=" ">
                                                        <li className="">POSH</li>
                                                        <li className="">Cyber Security</li>
                                                        <li className="">Human Resource</li>
                                                        <li className="">IT Policy</li>
                                                        <li className="">ESG Policy</li>
                                                    </ol> */}

                                                    {/* <ol className="list-group">
                                                        <li className="list-group-item">
                                                            POSH
                                                            <input style={{ marginLeft: "24px" }} className="form-check-input " type="checkbox" value="" aria-label="..." />

                                                        </li>
                                                        <li className="list-group-item">
                                                            Cyber Security
                                                            <input style={{ marginLeft: "24px" }} className="form-check-input " type="checkbox" value="" aria-label="..." />

                                                        </li>
                                                        <li className="list-group-item">
                                                            Human Resource
                                                            <input style={{ marginLeft: "24px" }} className="form-check-input " type="checkbox" value="" aria-label="..." />

                                                        </li>
                                                        <li className="list-group-item">
                                                            IT Policy
                                                            <input style={{ marginLeft: "24px" }} className="form-check-input " type="checkbox" value="" aria-label="..." />

                                                        </li>
                                                        <li className="list-group-item">
                                                            ESG Policy
                                                            <input style={{ marginLeft: "24px" }} className="form-check-input " type="checkbox" value="" aria-label="..." />

                                                        </li>
                                                    </ol> */}

<div classNameName="col-md-6">
                          <h6 className="text-capitalize">ESG Policy Statement</h6>
                          {/* <h6 className="text-capitalize">ESG Roadmap </h6> */}

                          <p className="">
                            {" "}
                            {/* Teamglobal is an integrated Logistics services
                            provider headquartered in India. We are offering
                            services in multiple segments of logistics Viz. Sea
                            Freight, Airfreight, Project Cargo Transportation,
                            Cargo Terminals and Coastal Shipping. It has 18
                            offices in India, Bangladesh, Kenya and Tanzania. */}
                            Teamworld Logistics commits to comply with all applicable environmental, social and governance-related regulations on a proactive basis. 
                            <br/>
                            
                            {/* </p>

                            <p className="">
                            {" "} */}

Teamworld Logistics will incorporate ESG considerations across its business operations to ensure that ESG risks are well addressed, and a value is created in its services and to all stakeholders, by going beyond compliance. 

{/* Teamglobal Logistics will use a strategic ESG roadmap to address priority focus areas, develop targets, monitoring mechanisms, and disclose ESG performance in accordance with global ESG standard reporting frameworks.  */}
                          </p>
                        </div>

                        <div classNameName="col-md-6">
                          <h6 className="text-capitalize">Other Policy Documents</h6>
                          {/* <h6 className="text-capitalize">ESG Roadmap </h6> */}

                          <p className="">
                            {" "}
                            This policy applies to all group companies of Teamworld Logistics in India, including Freight forwarding companies, LCL Consolidation, Container Freight Stations (CFS) and Domestic Logistics Business as all the companies share governance structure and are governed by a common Board of Directors (BoD).
                            </p>

                         
                        </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </section >




            </div >
        </>
    )
}

export default Policy