import React, { useEffect } from "react";

import book from "../../../assets/images/book-icon.svg";
import service from "../../../assets/images/other-service-icon.svg";

import cargo from "../../../assets/images/sea-cargo-coonsolidation-banner.jpg";

import project from "../../../assets/images/project-cargo-forwarding-banner.jpg";
import sea from "../../../assets/images/sea-freight-forwarding-banner.jpg";
import lcl from "../../../assets/images/lcl-opratior.svg";

import digital from "../../../assets/images/digital-offerings.svg";
import tool from "../../../assets/images/our-tools-icon.svg";
import Servicefooter from "../servicesfooter";

const ProjectCargoConsolidation = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Project Cargo Consolidation";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Project Cargo Consolidation";
  }, []);

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Services</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Project Cargo Forwarding
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize"> Project Cargo Forwarding</h2>
                  <div className="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      className="btn btn-book btn-primary"
                    >
                      <span>
                        <img src={book} />
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" className="btn btn-primary">-->
                                            <!--	<span>-->
                                                <!--		<img src="assets/images/Quote-icon.svg">-->
                                                    <!--	</span>Request A Quote-->
                                            <!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="other-services-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4 left-nav">
                <div className="service-nav ">
                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={service} />
                      </span>
                      Other Services
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="sea-cargo-consolidation">
                          Sea Cargo Consolidation
                        </a>
                      </li>
                      <li>
                        <a href="sea-freight-forwarding">
                          Sea Freight Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="air-freight">Air Freight</a>
                      </li>
                      <li className="active">
                        <a href="project-cargo-forwarding">
                          Project Cargo Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="container-freight-station">
                          Container Freight Station
                        </a>
                      </li>
                      <li>
                        <a href="coastal-shipping">Coastal Shipping</a>
                      </li>
                      
                      {/* <li>
                       <a href="transporation">Transporation</a>
                      </li> */}
                      {/* <li>
                       <a href="warehousing">Warehousing</a>
                      </li> */}
                      {/* <li>
                       <a href="customs-clearance">Customs Clearance</a>
                      </li> */}
                      {/* <li>
                       <a href="po-management">Po Management</a>
                      </li> */}
                      {/* <li>
                       <a href="pick-pack">Pick & Pack</a>
                      </li> */}
                      {/* <li>
                        <a href="quality-control-inspection">
                        Quality Control & Inspection
                        </a>
                      </li> */}
                    </ul>
                  </div>

                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} />
                      </span>
                      Ecommerce
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                          target="_blank"
                        >
                          e-booking
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                          target="_blank"
                        >
                          E-SI
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx"
                          target="_blank"
                        >
                          E-BL
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                          target="_blank"
                        >
                          proforma invoice
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                          target="_blank"
                        >
                          E-CAN
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="e-do"
                          target="_blank"
                        >
                          E-DO
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                          target="_blank"
                        >
                          reports
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://tiva.teamglobal.in/"
                          target="_blank"
                        >
                          roadbridge
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="service-block mb-0">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} />
                      </span>
                      Tools
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="global-locations">Global Locations</a>
                      </li>
                      <li>
                        <a href="warehouse-locations">Warehouse Locations</a>
                      </li>
                      <li>
                        <a
                          href="inco-terms"
                          style={{ textTransform: "inherit" }}
                        >
                          INCO Terms
                        </a>
                      </li>
                      <li>
                        <a href="shipping-glossary">Shipping Glossary</a>
                      </li>
                      <li>
                        <a href="conutry-restrictions">Country Restrictions</a>
                      </li>
                      <li>
                        <a href="freight-calculator">Freight Calculator</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="consolidation-details">
                  <figure>
                    <img src={project} alt="Project Cargo Forwarding Banner" />
                  </figure>

                  <h4>Project Cargo Forwarding</h4>
                      

                    <p>Our project division specializes in complex, high-value, heavy-lift and over-dimensional project cargo, that requires high level of expertise and competence. Our projects Team is backed by expert knowledge, vast experience and competence in handling Project Cargo and has an established reputation of executing multi-modal project movements and remote location forwarding backed by proven innovative logistics solutions and service excellence.</p>
                    <p>Our in-house logistics capabilities ensures proposing comprehensive, efficient and most suitable logistical end to end solutions to our clients thereby ensuring seamless, cost effective and timely movement of project cargo. We are catering to varied variety of clients ranging from major EPC’s, heavy equipment manufacturers and even government enterprises.</p>
                    <p>At Teamglobal our well-trained projects team believes in collaborating, offering clients tailor made solutions with transparent communication channels. Clients can be assured of TGL projects team for professional, safe and compliance proof global forwarding approach when it comes to deliverables.</p>
                    <p>We fully focus on identifying our client’s specific needs and tailoring unique solutions to suit their requirements. We see ourselves as value adding partners to our clients in their respective supply chains, in order to provide the best in terms of both cost and service.</p>
                    <p>Today’s challenging Projects requires complex, thorough planned and flawlessly executed solutions. No matter how demanding project is, we make our partners rest assured that their mind would be at ease once they have met out Teamglobal Projects Team.</p>
                 












                  {/* <p>
                    Our project division specializes in complex, high-value,
                    heavy-lift and over-dimensional project cargo, that requires
                    high level of expertise and competence. Our projects Team is
                    backed by expert knowledge, vast experience and competence
                    in handling Project Cargo and has an established reputation
                    of executing multi-modal project movements and remote
                    location forwarding backed by proven innovative logistics
                    solutions and service excellence.
                    <br />
                    <br />
                    Our in-house logistics capabilities ensures proposing
                    comprehensive, efficient and most suitable logistical end to
                    end solutions to our clients thereby ensuring seamless, cost
                    effective and timely movement of project cargo. We are
                    catering to varied variety of clients ranging from major
                    EPC’s, heavy equipment manufacturers and even government
                    enterprises.
                    <br />
                    <br />
                    At Teamglobal our well-trained projects team believes in
                    collaborating, offering clients tailor made solutions with
                    transparent communication channels. Clients can be assured
                    of TGL projects team for professional, safe and compliance
                    proof global forwarding approach when it comes to
                    deliverables.
                    <br />
                    <br />
                    We fully focus on identifying our client’s specific needs
                    and tailoring unique solutions to suit their requirements.
                    We see ourselves as value adding partners to our clients in
                    their respective supply chains, in order to provide the best
                    in terms of both cost and service.
                    <br />
                    <br />
                    Today’s challenging Projects requires complex, thorough
                    planned and flawlessly executed solutions. No matter how
                    demanding project is, we make our partners rest assured that
                    their mind would be at ease once they have met out
                    Teamglobal Projects Team.
                  </p> */}

                  {/* <!--<h4>Title 2 - Dummy Content</h4>-->
                                                                <!--<p>We have 135 direct Export services Ex various ports and ICDs in India making us the largest LCL operator in India. Our large customer base and consistent volumes ensures that we are able to maintain a very high schedule integrity.</p><p> We have dedicated Haz Cargo desk which helps our customers ship their haz cargoes faster. We have an escalation matrix in place ensuring quick resolution to service deviations.-->
                                                                    <!--</p>-->


                                                                <!--<h4>Title 3 - Dummy Content</h4>-->
                                                                <!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.<br><br>  We have Direct Services from Global cargo origins to main ports of Nava Sheva and Chennai. The other large ports of Kolkota, Tuticorin and Kochi are covered through international transshipment ports of Colombo, Singapore, Hong Kong and Dubai. We fixed day departures Ex Nava Sheva to New Delhi (ICD Patparganj and ICD Tughlakabad),ICD Ahmedabad and Ex Chennai to ICD Bangalore and ICD Hyderabad.</p>-->

                                                                    <!--<h4>Why Choose Team Global?</h4>-->
                                                                    <!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.</p>-->
 */}
                </div>

                <div className="most-service">
                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>Competitive and Credible solutions</strong>
                    {/* <!--<p>ICDs in India</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>
                      Expertise for smooth project execution from factory to
                      foundation delivery
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>
                      Team well-versed in regulations across continents
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default ProjectCargoConsolidation;
