import React, { useEffect } from "react";
import book from "../../assets/images/book-icon.svg";
import member1 from "../../assets/images/about/member1.png";
import member2 from "../../assets/images/about/member2.png";
import member3 from "../../assets/images/about/member3.png";
import member4 from "../../assets/images/about/member4.png";
import member6 from "../../assets/images/about/member6.png";
import member8 from "../../assets/images/about/member8.png";
import member11 from "../../assets/images/about/member11.png";
import jacob from "../../assets/images/about/jacob-thomas.jpg";
import rampal from "../../assets/images/about/rampal-tandon.jpg";
import sandeep from "../../assets/images/about/sandeep.png";
import manoj from "../../assets/images/about/manoj-goplani.png";
import gayatri from "../../assets/images/about/gayatri-shroff.jpg";
import sujit from "../../assets/images/about/sujit-baral.jpg";
import naveen from "../../assets/images/about/naveen.png";
import viveksingh from "../../assets/images/about/viveksingh.png";
// import Servicefooter from "../services/servicesfooter";
import ShayamSharda from '../../assets/images/shyam sharda.jpg';

const Team = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Team";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Team";
  }, []);

  return (
    <>
      <div class="content">
        <section class="cargo-consolidation about-txt">
          <div class="container">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="index">Home</a>
                  </li>
                  <li class="breadcrumb-item">About Us</li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Team
                  </li>
                </ol>
              </nav>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="d-md-flex justify-content-between align-items-center">
                  <h2 class="text-capitalize"> About Us</h2>
                  <div class="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      class="btn btn-book btn-primary"
                    >
                      <span>
                        <img src={book} />
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" class="btn btn-primary">-->
										<!--	<span>-->
										<!--		<img src="assets/images/Quote-icon.svg">-->
										<!--	</span>Request A Quote-->
										<!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="page-tabs-sec">
          <div class="container">
            <div class="">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a href="profile" class="nav-link " id="home-tab">
                    Profile
                  </a>
                </li>
                <li class="nav-item">
                  <a href="team" class="nav-link active" id="team-tab">
                    Team
                  </a>
                </li>
                <li class="nav-item">
                  <a href="mission" class="nav-link" id="contact-tab">
                    Mission
                  </a>
                </li>

                <li class="nav-item">
                  <a href="work-philosophy" class="nav-link" id="team-tab">
                    Work Philosophy
                  </a>
                </li>
                <li class="nav-item">
                  <a href="milestones" class="nav-link" id="team-tab">
                    Milestones
                  </a>
                </li>
                <li class="nav-item">
                  <a href="csr" class="nav-link" id="team-tab">
                    CSR Activities
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div class="team-tab-content">
                    {/* <!--<h2 class="team-title text-capitalize text-center">Our Team</h2>--> */}
                    <div class="team-wrap">
                      <h4 class="team-title text-capitalize text-center">
                        Our Team
                      </h4>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member1} alt="" />
                            </figure>
                            <strong class="d-block">Nityam Khosla</strong>
                            <span class="user-pos">Director</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member2} alt="" />
                            </figure>
                            <strong class="d-block">Vivek Kele</strong>
                            <span class="user-pos">Director</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member3} alt="" />
                            </figure>
                            <strong class="d-block">Rajesh Bhanushali</strong>
                            <span class="user-pos">
                              Director 
                            </span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={ShayamSharda} alt="" />
                            </figure>
                            <strong class="d-block">Shyam Sharda</strong>
                            <span class="user-pos">Group CFO</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        {/* <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member4} alt="" />
                            </figure>
                            <strong class="d-block">Hemant Bhanushali</strong>
                            <span class="user-pos">Director - Logistics</span>
                          </div>
                        </div> */}
                        {/* <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={jacob} alt="" />
                            </figure>
                            <strong class="d-block">Jacob Thomas</strong>
                            <span class="user-pos">Director - Commercial</span>
                            {/*
                          </div>
                        </div> */}
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member6} alt="" />
                            </figure>
                            <strong class="d-block">Tejan Lakhia</strong>
                            <span class="user-pos">
                              Sr. VP - FCL Sea Freight
                            </span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member11} alt="" />
                            </figure>
                            <strong class="d-block">Shibu Paul</strong>
                            <span class="user-pos">
                              {" "}
                              Sr. VP - WWA Business Development
                            </span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        {/* <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={rampal} alt="" />
                            </figure>
                            <strong class="d-block">Rampal Tandon</strong>
                            <span class="user-pos">
                              VP - Freight Management
                            </span>
                           
                          </div>
                        </div> */}

                        {/* <!--<div class="col-md-4">
								  				<div class="business-card text-center">
								  					<figure><img src="assets/images/about/naveen.png" alt="" /></figure>
								  					<strong class="d-block">Naveen Kanaujia</strong>
								  					<span class="user-pos">GM – Imports</span>-->
								  					<!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
								  			<!--</div>
								  			</div>-->
								  			<!--<div class="col-md-4">
								  				<div class="business-card text-center">
								  					<figure><img src="assets/images/about/member9.png" alt="" /></figure>
								  					<strong class="d-block">Raghavendran B</strong>
								  					<span class="user-pos">GM - Air Freight</span>
								  					
								  				</div>
								  			</div>--> */}

                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member8} alt="" />
                            </figure>
                            <strong class="d-block">Gaurang Timbadiya</strong>
                            <span class="user-pos">Sr. VP - Finance</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
								  				</div> */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={sandeep} alt="" />
                            </figure>
                            <strong class="d-block">Sandeep Palodkar</strong>
                            <span class="user-pos">
                              VP - HR/IR, Admin, Business Excellence
                            </span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={manoj} alt="" />
                            </figure>
                            <strong class="d-block">Manoj Gopalani</strong>
                            <span class="user-pos">
                              VP - Information Technology
                            </span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={gayatri} alt="" />
                            </figure>
                            <strong class="d-block">Gayatri Shroff</strong>
                            <span class="user-pos">VP - Commercial</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={sujit} alt="" />
                            </figure>
                            <strong class="d-block">Sujit Baral</strong>
                            <span class="user-pos">
                            VP - Export LCL
                            </span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={naveen} alt="" />
                            </figure>
                            <strong class="d-block">Naveen Kanaujia</strong>
                            <span class="user-pos">AVP – Imports</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        
                        <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={viveksingh} alt="" />
                            </figure>
                            <strong class="d-block">Vivek Singh</strong>
                            <span class="user-pos">National Product Head - FCL Export</span>
                            {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default Team;
