import React, { useEffect } from "react";
import sea from "../../assets/images/sea-cargo-coonsolidation.jpg";

import freight from "../../assets/images/sea-freight-forwarding.jpg";

import seafreight from "../../assets/images/sea-freight-icon.png";
import air from "../../assets/images/air-freight.jpg";

import airicon from "../../assets/images/air freight-icon.png";
import cargo from "../../assets/images/project-cargo-forwarding.jpg";

import seacargo from "../../assets/images/Sea-Cargo-Consolidation.svg";

import project from "../../assets/images/project-cargo.png";

import handling from "../../assets/images/cargo-handling-infrastructure.jpg";

import infra from "../../assets/images/cargo-infra.svg";

import coastal from "../../assets/images/coastal-shipping.jpg";
import shipping from "../../assets/images/coastal-shipping-icon.png";

const Services = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = " Services";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = " Services";
  }, []);

  return (
    <>
      <section className="our-services">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="service-title">Our Services</h2>
            </div>
            {/* <!--<div className="col-md-8">-->
							<!--	<p className="desc">TeamGlobal is a member of leading global networks like <br />-->
							<!--		<span style="color: #0072BE;">World Wide Alliance, Air Cargo Group and GPLN</span> for the different segments of business it operates.</p>-->
							<!--</div>--> */}
          </div>
          <div className="row" style={{ marginTop: "15px" }}>
            <div className="col-md-4">
              <a
                href="sea-cargo-consolidation"
                style={{ textDecoration: "none", display: " block" }}
              >
                <div className="service-card">
                  <img src={sea} alt="Sea Cargo Coonsolidation" />
                  <div className="service-detail text-center d-flex justify-content-center align-items-center">
                    <div>
                      <span className="service-icn">
                        <img src={seacargo} alt="sea-cargo-icon" />
                      </span>
                      <h4>sea cargo consolidation</h4>
                      <p>
                        Our export Consolidation Program is designed to cater to
                        movement of LCL Shipments of all kinds out of India.
                      </p>
                      <a href="sea-cargo-consolidation">Read More</a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="sea-freight-forwarding"
                style={{ textDecoration: "none", display: " block" }}
              >
                <div className="service-card">
                  <img src={freight} alt="Sea Freight Forwarding" />
                  <div className="service-detail text-center d-flex justify-content-center align-items-center">
                    <div>
                      <span className="service-icn">
                        <img src={seafreight} alt="sea-freight-icon" />
                      </span>
                      <h4>sea freight forwarding</h4>
                      <p>
                        We have an excellent FCL Forwarding Program managed by a
                        large professional team that specializes in FCL
                        Forwarding.
                      </p>
                      <a href="sea-freight-forwarding">Read More</a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="air-freight"
                style={{ textDecoration: "none", display: " block" }}
              >
                <div className="service-card">
                  <img src={air} alt="Air Freight" />
                  <div className="service-detail text-center d-flex justify-content-center align-items-center">
                    <div>
                      <span className="service-icn">
                        <img src={airicon} alt="air freight-icon" />
                      </span>
                      <h4>air freight</h4>
                      <p>
                        Being a part of Prestigious Air Cargo Group (ACG) we
                        have access to best in the className international
                        agency network across Globe.
                      </p>
                      <a href="air-freight">Read More</a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="project-cargo-forwarding"
                style={{ textDecoration: "none", display: " block" }}
              >
                <div className="service-card">
                  <img src={cargo} alt="Project Cargo Forwarding" />
                  <div className="service-detail text-center d-flex justify-content-center align-items-center">
                    <div>
                      <span className="service-icn" style={{ width: "38px" }}>
                        <img src={project} alt="project-carg" />
                      </span>
                      <h4>project cargo forwarding</h4>
                      <p>
                        Our Projects division is focused on the global
                        transportation of heavy lift units, oversized cargoes,
                        and project related shipments.
                      </p>
                      <a href="project-cargo-forwarding">Read More</a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="container-freight-station"
                style={{ textDecoration: "none", display: " block" }}
              >
                <div className="service-card">
                  <img src={handling} alt="Cargo Handling Infrastructure" />
                  <div className="service-detail text-center d-flex justify-content-center align-items-center">
                    <div>
                      <span className="service-icn" style={{ width: "50px" }}>
                        <img src={infra} alt="cargo-handling-icon" />
                      </span>
                      <h4>Container Freight Station</h4>
                      <p>
                        Our unique multi-city consolidation program is designed
                        to accept LCL shipments at various inland container
                        depots in India.
                      </p>
                      <a href="container-freight-station">Read More</a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="coastal-shipping"
                style={{ textDecoration: "none", display: " block" }}
              >
                <div className="service-card">
                  <img src={coastal} alt="Coastal Shipping" />
                  <div className="service-detail text-center d-flex justify-content-center align-items-center">
                    <div>
                      <span className="service-icn" style={{ width: "40px" }}>
                        <img src={shipping} alt="coastal-shipping-icon" />
                      </span>
                      <h4>coastal shipping</h4>
                      <p>
                        Teamglobal provides a personalized and seamless domestic
                        multimodal transportation service using Coastal
                        shipping.
                      </p>
                      <a href="coastal-shipping">Read More</a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
