import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Sustainability.css";

import Twlogo from "../../assets/images/TW-Logo.svg";
import member1 from "../../assets/images/about/member1.png";
import member2 from "../../assets/images/about/member2.png";
import member3 from "../../assets/images/about/member3.png";
import member4 from "../../assets/images/about/member4.png";
import member6 from "../../assets/images/about/member6.png";
import member8 from "../../assets/images/about/member8.png";
import member11 from "../../assets/images/about/member11.png";
import jacob from "../../assets/images/about/jacob-thomas.jpg";
import rampal from "../../assets/images/about/rampal-tandon.jpg";
import sandeep from "../../assets/images/about/sandeep.png";
import manoj from "../../assets/images/about/manoj-goplani.png";
import gayatri from "../../assets/images/about/gayatri-shroff.jpg";
import sujit from "../../assets/images/about/sujit-baral.jpg";
import naveen from "../../assets/images/about/naveen.png";
//import teamglobal from "../../assets/images/teamGloble.jpg";
import teamglobal from "../../assets/images/ESGLeadershipcommitment.jpg";
import Vivekele from '../../assets/images/Vivek kele.jpeg'
import NityamKhosla from '../../assets/images/Nityam khosla.jpeg'
import RajeshBhanushali from '../../assets/images/Rajesh bhanusali.jpeg'
import SandeepPalodkar from '../../assets/images/Sandeep palodkar.jpeg'

const Context = () => {
  // const [expanded, setExpanded] = useState(false);
  const [vivekExpanded, setVivekExpanded] = useState(false);
  const [nityamExpanded, setNityamExpanded] = useState(false);
  const [sandeepExpanded, setSandeepExpanded] = useState(false);
  const [show, setShow] = useState(false);
  const [nityamshow, setNityamShow] = useState(false);
  const [sandeepshow, setSandeepShow] = useState(false);

  const handleExpandClick = (person) => {
    switch (person) {
      case "vivek":
        setVivekExpanded(!vivekExpanded);
        setNityamExpanded(false);
        setSandeepExpanded(false);
        break;
      case "nityam":
        setNityamExpanded(!nityamExpanded);
        setVivekExpanded(false);
        setSandeepExpanded(false);
        break;
      case "sandeep":
        setSandeepExpanded(!sandeepExpanded);
        setVivekExpanded(false);
        setNityamExpanded(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Context";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Context";
  }, []);

  return (
    <>
      <div className="content">
        <section className="contact-head">
          <div className="cargo-consolidation about-txt">
            <div className="container">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <a href="/">Home</a>
                      </Link>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                    Sustainability
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                   ESG Leadership Commitment
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex align-items-center">
                    <h2 className="text-capitalize">ESG Leadership Commitment </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-tabs-sec">
          <div className="container">
            <div className="">
              <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                // marginTop: "20px",
                // paddingRight: "10px",
                // paddingTop: "20px",
                // paddingLeft: "10px",
                // border: "1px solid #ffcccb",
                // height: "600px",
                // width: "800px",
              >
                <li className="nav-item">
                  <a
                    href="esg-framework"
                    className="nav-link"
                    id="esg-framework-tab"
                  >
                    Framework
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-governance-structure-and-roadmap"
                    className="nav-link"
                    id="esg-governance-structure-and-roadmap-tab"
                  >
                   Governance Structure and Roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-focus-area-and-goals"
                    className="nav-link "
                    id="esg-focus-area-and-goals-tab"
                  >
                    {" "}
                    Focus Area And Goals
                  </a>
                </li>

                {/* <li className="nav-item">
                  <a
                    href="policies-and-commitments"
                    className="nav-link"
                    id="cpolicies-and-commitments"
                  >
                    POLICIES AND COMMITMENTS
                  </a>
                </li> */}

                <li className="nav-item">
                  <a className="nav-link active" href="leadership-commitment">
                 Leadership Commitment
                  </a>
                </li>

                {/* <li className="nav-item">
                  <a
                    href="esg-milestone"
                    className="nav-link"
                    id="esg-milestone-tab"
                  >
                  Milestones
                  </a>
              </li> */}

                <li className="nav-item">
                  <a
                    href="success-stories"
                    className="nav-link"
                    id="success-stories-tab"
                  >
                    Success Stories
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="tablevel1"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="about-tab-content">
                    {/* <h2 className="team-title text-capitalize text-center">
                      As Teamglobal, we strive to offer our customers a well
                      defined and a well structured logistics services.
                    </h2> */}
                    <h2 className="team-title text-capitalize text-center">
                   ESG Leadership Commitment
                    </h2>
                    <div className="row">
                      <div className="col-md-12">
                        <figure className="about-banner">
                          <img src={teamglobal} alt="Team Photo" />
                        </figure>
                      </div>
                      <div className="col-md-12">
                        {/* <h4 className="post-title">
                          Teamglobal has been certified and affiliated with
                          listed agencies and associations
                        </h4> */}
                        {/* <p className="">
                          {" "}
                          Teamglobal Logistics, established in 2005, is an
                          integrated logistics service provider. It is
                          headquartered in Mumbai, India, and has 1000 + on-roll
                          employees and 900+ off-roll employees. Teamglobal
                          Logistics operates from 40 offices in India, 2 offices
                          in Bangladesh, and 4 container freight stations (CFSs)
                          in cities of Mumbai, Hyderabad, and Chennai, in India.
                          <br />
                          Teamglobal Logistics offers services in 180 countries
                          and serves across 1500 ports. Service offerings
                          include:
                          <ul style={{ listStyleType: "square" }}>
                            <li>
                              Sea Cargo Consolidation involves consolidation of
                              inbound (imports) and outbound (exports) less-than
                              container (LCL) to full-container load (FCL) for
                              optimization of container space, and in-turn cost
                              optimization for customers.
                            </li>
                            <li>
                              Sea Freight Forwarding involves forwarding of FCL
                              for inbound and outbound containers
                            </li>
                            <li>
                              Project Cargo Forwarding includes forwarding of
                              high value, high-weight, and over-dimensional
                              cargo through multi-modal movements and remove
                              location forwarding.
                            </li>
                            <li>
                              Air Freight involves logistics services for air
                              freight, including pickup and door deliveries
                              using road transportation.
                            </li>
                            <li>
                              Coastal Shipping involves coastline movement of
                              domestic cargo.
                            </li>
                            <li>
                              Container Freight Stations involve handling,
                              operation, and storage of containers.
                            </li>
                          </ul>
                          Teamglobal Logistics’ physical assets include offices
                          and CFSs. Offices are either owned or leased spaces
                          within commercial buildings. CFSs are owned and/or
                          operated by Teamglobal. Teamglobal’s digital assets
                          include its comprehensive E-commerce platform and
                          virtual assistant TIVA. Teamglobal Logistics’ primary
                          customer base consists of various intermediate firms
                          in the logistics business and direct shippers. The
                          business operations are largely organized through
                          global logistics networks.
                        </p> */}

                        {/* <h6 className="text-capitalize">accreditation</h6> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade show active"
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div class="team-tab-content">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="business-card text-center" style={{height: "98%"}}>
                          <figure>
                            <img src={NityamKhosla} alt="" />
                          </figure>
                          <strong class="d-block">Nityam Khosla</strong>
                          <span class="user-pos">Director - Teamglobal</span>

                          <div>
                            <h5>
                              <p class="">
                                {" "}
                                <b>
                                Moving with Time and Fulfilling need of the client as well as becoming a sustainable and responsible Teamglobal company
                                </b>
                                <br />
                                <br />
                                
                               
                                  <p className="card-body" style={{    minHeight: "252px"}}>
                                  In this transformational world Teamglobal embraces decarbonisation, innovative technology , digitalisation including automation to meet the evolving needs of our clients. Our commitment to ESG principles guides our path towards a sustainable and responsible future ensuring the human element is kept in core of technological and eco friendly transition to ensure a sustainable environment for our future generations. We are driven by a shared vision where environmental stewardship, social well-being, and strong governance underpin our decision-making. By balancing the needs of our planet, team members, and clients, we aim to build a lasting legacy with value and contribute to a more responsible and sustainable tomorrow.
                                  </p>
                              
                               
                              </p>
                            </h5>
                            {/* {nityamExpanded && (
                              <p className="">
                                {" "}
                                In this transformational world Teamglobal
                                embraces decarbonisation, innovative technology
                                , digitalisation including automation to meet
                                the evolving needs of our clients. Our
                                commitment to ESG principles guides our path
                                towards a sustainable and responsible future
                                ensuring the human element is kept in core of
                                technological and eco friendly transition to
                                ensure a sustainable environment for our future
                                generations. We are driven by a shared vision
                                where environmental stewardship, social
                                well-being, and strong governance underpin our
                                decision-making. By balancing the needs of our
                                planet, team members, and clients, we aim to
                                build a lasting legacy with value and contribute
                                to a more responsible and sustainable tomorrow.y
                              </p>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="business-card text-center" style={{height: "98%"}}>
                          <figure>
                            <img src={Vivekele} alt="" />
                          </figure>
                          <strong class="d-block">Vivek Kele</strong>
                          <span class="user-pos">Director - Teamglobal</span>

                          {/* <p>
  <a className="btn btn-primary" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Offering Sustainable and Low Carbon Logistics solution ESG governance at Teamglobal</a>
  
</p>

  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample1">
      <div className="card card-body">
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
     
    </div>
  </div>
  </div>
                             */}
                          <div>
                            <h5>
                              <p class="">
                                {" "}
                                <b>
                                Offering Sustainable and Low Carbon Logistics solution ESG governance at Teamglobal
                                </b>
                                <br />
                                <br />
                                <br />
                               
                               
                                  
                                  <p  className="card-body" style={{    minHeight: "275px"}}>
                                  At Teamglobal, our commitment to sustainable and low-carbon logistics solutions is at the heart of our operations. Embracing the principles of Environmental, Social, and Governance (ESG) governance, we are dedicated to reducing our carbon footprint and promoting responsible practices. We believe that multimodal transportation efficiency will be enhanced by adapting technology, using fuel-efficient or electric handling equipment, and creating energy-efficient infrastructure. Our focus extends to creating innovative and eco-friendly transport solutions that not only meet our clients' needs but also align with our shared vision of a more sustainable environment.
                                    {/* </div> */}
                                  </p>
                                
                              </p>
                            </h5>

                            {/* {vivekExpanded && (
                              <p class="">
                                {" "}
                                At Teamglobal, our commitment to sustainable and
                                low-carbon logistics solutions is at the heart
                                of our operations. Embracing the principles of
                                Environmental, Social, and Governance (ESG)
                                governance, we are dedicated to reducing our
                                carbon footprint and promoting responsible
                                practices. We believe that multimodal
                                transportation efficiency will be enhanced by
                                adapting technology, using fuel-efficient or
                                electric handling equipment, and creating
                                energy-efficient infrastructure. Our focus
                                extends to creating innovative and eco-friendly
                                transport solutions that not only meet our
                                clients' needs but also align with our shared
                                vision of a more sustainable environment.
                              </p>
                            )} */}
                          </div>

                          {/* <!--<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                        </div>
                      </div>

                     
                      <div class="col-md-6">
                        <div class="business-card text-center" style={{height: "98%"}}>
                          <figure>
                            <img src={RajeshBhanushali} alt="" />
                          </figure>
                          <strong class="d-block">Rajesh Bhanushali</strong>
                          <span class="user-pos"> Director - LCL - Teamglobal</span>

                          <div>
                            <h5>
                              <p class="">
                                {" "}
                                <b>
                                Initiatives taken at offices and CFS on sustainability
                                </b>
                                <br />
                                <br />
                                
                               
                                  <p className="card-body" style={{    minHeight: "252px"}}>
                                  Team Global is steadfast in its commitment to service excellence, sustainability, and corporate social responsibility. Our Green Office and CFS program prioritize waste management, e-waste disposal, green procurement, and renewable energy adoption. Actively shaping a sustainable future, we address environmental challenges through CSR collaborations, emphasizing water conservation, Recharge of the water table, biodiversity, and urban forestation for enduring positive outcomes.
                                  </p>
                              
                               
                              </p>
                            </h5>
                            {/* {rajeshExpanded && (
                              <p className="">
                                {" "}
                                In this transformational world Teamglobal
                                embraces decarbonisation, innovative technology
                                , digitalisation including automation to meet
                                the evolving needs of our clients. Our
                                commitment to ESG principles guides our path
                                towards a sustainable and responsible future
                                ensuring the human element is kept in core of
                                technological and eco friendly transition to
                                ensure a sustainable environment for our future
                                generations. We are driven by a shared vision
                                where environmental stewardship, social
                                well-being, and strong governance underpin our
                                decision-making. By balancing the needs of our
                                planet, team members, and clients, we aim to
                                build a lasting legacy with value and contribute
                                to a more responsible and sustainable tomorrow.y
                              </p>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="business-card text-center" style={{height: "98%"}}>
                          <figure>
                            <img src={SandeepPalodkar} alt="" />
                          </figure>
                          <strong class="d-block">Sandeep Palodkar</strong>
                          <span class="user-pos">
                          VP - HR/IR, Admin - Teamglobal
                          </span>

                          <div>
                            {/* <h5 onClick={() => handleExpandClick("sandeep")}> */}
                            <h5>
                              <p className="">
                                {" "}
                                <b>
                                Instituted ESG Governance Identified Focus areas and activity adapting best practices to improve ESG performance of Teamglobal.
                                </b>
                                <br />
                                <br />
                               
                                
                                  <p className="card-body" style={{    minHeight: "272px"}}>
                                  In our unwavering commitment to sustainability and responsible business practices, we have embarked on a transformative journey towards ESG excellence. At TeamGlobal, we've laid the foundation for a robust ESG governance framework, meticulously identified key focus areas, and passionately creating sustainability driven business practices. Through these deliberate steps, we're driving positive change, fostering transparency, and enhancing our ESG performance. Together, we're building a brighter, more sustainable future for our organization and the world.
                                  </p>
                               
                                 
                              </p>
                            </h5>

                            {/* <p className="">
                                {""}
                                In our unwavering commitment to sustainability
                                and responsible business practices, we have
                                embarked on a transformative journey towards ESG
                                excellence. At TeamGlobal, we've laid the
                                foundation for a robust ESG governance
                                framework, meticulously identified key focus
                                areas, and passionately creating sustainability
                                driven business practices. Through these
                                deliberate steps, we're driving positive change,
                                fostering transparency, and enhancing our ESG
                                performance. Together, we're building a
                                brighter, more sustainable future for our
                                organization and the world.
                              </p> */}
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-md-4">
                          <div class="business-card text-center">
                            <figure>
                              <img src={member2} alt="" />
                            </figure>
                            <strong class="d-block">Vivek Kele</strong>
                            <span class="user-pos">Director</span>
                           
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    {/* <!--<h2 className="team-title text-capitalize text-center">Our Team</h2>--> */}
                    <div className="team-wrap">
                      <h4 className="team-title text-capitalize text-center">
                        Our Team
                      </h4>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member1} alt="" />
                            </figure>
                            <strong className="d-block">Nityam Khosla</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member2} alt="" />
                            </figure>
                            <strong className="d-block">Vivek Kele</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member3} alt="" />
                            </figure>
                            <strong className="d-block">
                              Rajesh Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Export LCL Console
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member4} alt="" />
                            </figure>
                            <strong className="d-block">
                              Hemant Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Logistics
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={jacob} alt="" />
                            </figure>
                            <strong className="d-block">Jacob Thomas</strong>
                            <span className="user-pos">
                              Director - Commercial
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member6} alt="" />
                            </figure>
                            <strong className="d-block">Tejan Lakhia</strong>
                            <span className="user-pos">
                              Sr. VP - FCL Sea Freight
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member11} alt="" />
                            </figure>
                            <strong className="d-block">Shibu Paul</strong>
                            <span className="user-pos">
                              {" "}
                              Sr. VP - WWA Business Development
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={rampal} alt="" />
                            </figure>
                            <strong className="d-block">Rampal Tandon</strong>
                            <span className="user-pos">
                              VP - Freight Management
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        {/* <!--<div className="col-md-4">
								  				<div className="business-card text-center">
								  					<figure><img src="assets/images/about/naveen.png" alt="" /></figure>
								  					<strong className="d-block">Naveen Kanaujia</strong>
								  					<span className="user-pos">GM – Imports</span>-->
								  					<!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
								  			<!--</div>
								  			</div>-->
								  			<!--<div className="col-md-4">
								  				<div className="business-card text-center">
								  					<figure><img src="assets/images/about/member9.png" alt="" /></figure>
								  					<strong className="d-block">Raghavendran B</strong>
								  					<span className="user-pos">GM - Air Freight</span>
								  					
								  				</div>
								  			</div>--> */}

                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member8} alt="" />
                            </figure>
                            <strong className="d-block">
                              Gaurang Timbadiya
                            </strong>
                            <span className="user-pos">VP - Finance</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
								  				</div> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sandeep} alt="" />
                            </figure>
                            <strong className="d-block">
                              Sandeep Palodkar
                            </strong>
                            <span className="user-pos">
                              VP - HR/IR, Admin, Business Excellence
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={manoj} alt="" />
                            </figure>
                            <strong className="d-block">Manoj Gopalani</strong>
                            <span className="user-pos">
                              VP - Information Technology
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={gayatri} alt="" />
                            </figure>
                            <strong className="d-block">Gayatri Shroff</strong>
                            <span className="user-pos">GM - Commercial</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sujit} alt="" />
                            </figure>
                            <strong className="d-block">Sujit Baral</strong>
                            <span className="user-pos">
                              GM - Regional Head –South
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="business-card text-center">
                            <figure>
                              <img src={naveen} alt="" />
                            </figure>
                            <strong className="d-block">Naveen Kanaujia</strong>
                            <span className="user-pos">GM – Imports</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Context;
