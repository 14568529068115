import React from "react";
import Home from "../Pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  
} from "react-router-dom";
import Header from "../Component/Headers/Header";
import SeaCargoConsolidation from "../Component/services/Consolidations/SeaCargoConsolidations";
// import Footer from "../Component/Footer/Footer";
import SeaFreightConsolidation from "../Component/services/Consolidations/SeaFreightConsolidations";
import AirFreightConsolidation from "../Component/services/Consolidations/AirFreightConsolidations";
// import ProjectCargoConsolidation from "../Component/services/Consolidations/ProjectCargoConsolidations";
import ProjectCargoConsolidation from "../Component/services/Consolidations/ProjectCargoConsolidations";
import ContainerFreightConsolidation from "../Component/services/Consolidations/ContainerFreightConsolidation";
import ShippingConsolidation from "../Component/services/Consolidations/ShippingConsolidation";
// import Transporation from "../Component/services/Consolidations/Transporation";
// import Warehousing from "../Component/services/Consolidations/Warehousing";
// import CustomsClearance from "../Component/services/Consolidations/CustomsClearance";
// import PoManagement from "../Component/services/Consolidations/PoManagement";
// import PickPack from "../Component/services/Consolidations/PickPack";
// import QualityControlInspectipon from "../Component/services/Consolidations/QualityControlInspection";

import GloabalLocation from "../Component/Tools/GloabalLocation";
import WarehouseLocation from "../Component/Tools/WarehouseLocation";
import INCOTerm from "../Component/Tools/IncoTerms";
import ShippiGlossary from "../Component/Tools/ShippingGlossary";
import CountryRestrictions from "../Component/Tools/CountryRestrictions";
import FreightCalculator from "../Component/Tools/FreightCalculator";
import Profile from "../Component/AboutUs/Profile";
import Team from "../Component/AboutUs/Team";
import Mission from "../Component/AboutUs/Mission";
import Workphilosophy from "../Component/AboutUs/workphilosophy";
import Milestones from "../Component/AboutUs/Milestones";
import CsrActivites from "../Component/AboutUs/CSR";
import Contactus from "../Component/Contactus/Contactus";
import Carrer from "../Component/Carrer/Carrer";
import Videos from "../Component/Media/Videos"

import Leader from "../Component/Sustainability/Leader";
import Governance from "../Component/Sustainability/Governance";
import Policy from "../Component/Sustainability/Policy";
import Esg from "../Component/Sustainability/Esg";
import EsgMilestone from "../Component/Sustainability/EsgMilestone";
import Success from "../Component/Sustainability/Success";
import Footer from "../Component/Footer/Footer";
import Chennai from "../Component/CountryMenu/India/Chennai";
import Ahmedabad from "../Component/CountryMenu/India/Ahmedabad";


import Banglore from "../Component/CountryMenu/India/Banglore";
import Baroda from "../Component/CountryMenu/India/Baroda";
import Coimbatore from "../Component/CountryMenu/India/Coimbatore";
import Surat from "../Component/CountryMenu/India/Surat";
import Hyderabad from "../Component/CountryMenu/India/Hyderabad";
// import Jaipur from "../Component/CountryMenu/India/Jaipur";
import Kochi from "../Component/CountryMenu/India/Kochi";
import Kolkata from "../Component/CountryMenu/India/Kolkata";
import Ludhiana from "../Component/CountryMenu/India/Ludhiana";
import Mumbai from "../Component/CountryMenu/India/Mumbai";
import NewDelhi from "../Component/CountryMenu/India/NewDelhi";
import Pune from "../Component/CountryMenu/India/Pune";
// import Tirupur from "../Component/CountryMenu/Tirupur";
import Tirupur from "../Component/CountryMenu/India/Tirupur";
import Tuticorin from "../Component/CountryMenu/India/Tuticorin"
import Chittagong from "../Component/CountryMenu/Bangladesh/Chittagong";
import Dhaka from "../Component/CountryMenu/Bangladesh/Dhaka";
import Kenya from "../Component/CountryMenu/Kenya/Kenya";
import Tanzania from "../Component/CountryMenu/Tanzania/Tanzania";
import Search from "../Component/Media/Search";
import Award2018 from "../Component/Media/Award2018";
import MalaAward2013 from "../Component/Media/MalaAward2013";
import MalaAward2014 from "../Component/Media/MalaAward2014";


import MalaAward2015 from "../Component/Media/MalaAward2015";
import MalaAward2016 from "../Component/Media/MalaAward2016"
import Sustainability from "../Component/Sustainability/Substainability";
import EsgFocusArea from "../Component/Sustainability/EsgFocusArea";
import Ecommerce from "../Component/Ecommerce/Ecommerce.js";
import Register from "../Component/Ecommerce/register.js";
import Tglogin from "../Component/Ecommerce/Tg-login.js";
import PrivacyPolicy from "../Component/Footer/Privacypolicy.js";
import Termsofuse from "../Component/Footer/Termsofuse.js";
import Track from "../Component/Track/Track-it.js";
import Sailing from "../Component/Sailingschedule/sailing.js";
import Edo from "../Component/Ecommerce/E-do.js";
import Lcl from "../Component/Getqoute/Lcl.js";
import Fcl from "../Component/Getqoute/Fcl.js";
import Air from "../Component/Getqoute/Airform.js";
import Thankyou from "../Component/ThankYou/Thankyou.js"
// import Gandhidham from "../Component/CountryMenu/India/Gandhidham.js";

const Routers = () => {
  
  const pathname = window.location.pathname;

   const isHome = pathname === "/";
  return (
    <Router>
      {/* Header */}
      <Header />

      <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route exact path={[`${process.env.PUBLIC_URL}//`]} component={Home}></Route> */}
        <Route
          path="/sea-cargo-consolidation"
          element={<SeaCargoConsolidation />}
        />
        <Route
          path="/sea-freight-forwarding"
          element={<SeaFreightConsolidation />}
        />
        <Route path="/air-freight" element={<AirFreightConsolidation />} />
        <Route
          path="/project-cargo-forwarding"
          element={<ProjectCargoConsolidation />}
        />
        <Route
          path="/container-freight-station"
          element={<ContainerFreightConsolidation />}
        />
        <Route path="/coastal-shipping" element={<ShippingConsolidation />} />
        {/* <Route
          path="/transporation"
          element={<Transporation />}
        /> */}
         {/* <Route
          path="/warehousing"
          element={<Warehousing />}
        /> */}
         {/* <Route
          path="/customs-clearance"
          element={<CustomsClearance />}
        /> */}
         {/* <Route
          path="/po-management"
          element={<PoManagement />}
        /> */}
         {/* <Route
          path="/pick-pack"
          element={<PickPack />}
        /> */}
         {/* <Route
          path="/quality-control-inspection"
          element={<QualityControlInspectipon/>}
          />
         */}
        <Route path="/global-locations" element={<GloabalLocation />} />
        <Route path="/warehouse-locations" element={<WarehouseLocation />} />
        <Route path="/inco-terms" element={<INCOTerm />} />
        <Route path="/search" element={<Search/>}/>
        <Route path="/E-do" element={<Edo/>}/>
        
        
        <Route path="/shipping-glossary" element={<ShippiGlossary />} />
        <Route path="/country-restrictions" element={<CountryRestrictions />} />
        <Route path="/freight-calculator" element={<FreightCalculator />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/team" element={<Team />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/work-philosophy" element={<Workphilosophy />} />
        <Route path="/milestones" element={<Milestones />} />
        <Route path="/csr" element={<CsrActivites />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path ="/careers" element={<Carrer />} />
        <Route path ="/videos" element={<Videos/>} />
        <Route path="/ahmedabad" element={<Ahmedabad />} />
        <Route path="/bangalore" element={<Banglore />} />
        <Route path ="/baroda" element={<Baroda/>} />
        <Route path ="/chennai" element={<Chennai/>} />
        <Route path ="/coimbatore" element={<Coimbatore/>} />
        {/* <Route path="/gandhidham" element={<Gandhidham />} /> */}
        <Route path ="/surat" element ={<Surat/>} />
        <Route path ="/hyderabad" element={<Hyderabad/>} />
        {/* <Route path ="/jaipur" element ={<Jaipur/>} /> */}
        <Route path ="/kochi" element={<Kochi/>} />
        <Route path ="/kolkata" element={<Kolkata/>} />
        <Route path ="/ludhiana" element={<Ludhiana/>} />
        <Route path ="/mumbai" element={<Mumbai />} />
        <Route path ="/newdelhi" element={<NewDelhi/>} />
        <Route path ="/pune" element={<Pune />} />
        <Route path ="/tirupur" element={<Tirupur />} />
        <Route path ="/tuticorin" element={<Tuticorin/>} />
        <Route path ="/chittagong" element={<Chittagong/>} />
        <Route path ="/dhaka" element={<Dhaka/>} />
        <Route path="/kenya" element={<Kenya/>}/>
        <Route path="/tanzania" element={<Tanzania/>} />
        {/* <Route path="/award2018"> element={<Award2018/>}/> */}
        <Route path="/award2018" element={<Award2018/>} />
        <Route path="/malaaward2013" element={<MalaAward2013/>} />
        <Route path="/malaaward2014" element={<MalaAward2014/>} />
        <Route path="/malaaward2015" element={<MalaAward2015/>} />
        <Route path="/malaaward2016" element={<MalaAward2016/>} />
        <Route path="/sustainability" element={<Sustainability/>}/>
        <Route path="/ecommerce" element={<Ecommerce/>}/>
        <Route path="/tg-login" element={<Tglogin/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/terms-of-use" element={<Termsofuse/>}/>
        <Route path="/track" element={<Track/>}/>
        <Route path="/Register" element={<Register/>}/>
        <Route path="/sailing-schedule" element={<Sailing/>}/>
        <Route path="/Lcl" element={<Lcl/>}/>
        <Route path="/Fcl" element={<Fcl/>}/>
        <Route path="/Air" element={<Air/>}/>
        <Route path="/thankyou" element={<Thankyou/>}/>

      

        <Route
          path="/leadership-commitment"
          element={<Leader />}
        />
        <Route
          path="/esg-governance-structure-and-roadmap"
          element={<Governance />}
        />
        <Route path ="/esg-focus-area-and-goals" element={<EsgFocusArea/>}
        />
        <Route
          path="/policies-and-commitments"
          element={<Policy />}
        />
        <Route path="/esg-framework" element={<Esg />} />
        <Route path="/esg-milestone" element={<EsgMilestone />} />
        <Route path="/success-stories" element={<Success />} />
        <Route path="/*" element={<Footer />} />
      </Routes>



      {/* footer */}

    

      {isHome ? null : <Footer />}
    </Router>
  );
};

export default Routers;
