import React from "react";
import facebook from "../../assets/images/social-facebook.png";
import twitter from "../../assets/images/social-twitter.png";
import linkdin from "../../assets/images/social-linkdin.png";
import youtube from "../../assets/images/social-youtube.png";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <hr></hr>
      <footer>
        <div class="primary-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-xl-3">
                {/* <!--<a class="footer-brand d-flex align-items-center" href="index"><img src="assets/images/logo.png" alt="logo"></a>-->
								<!--<p>We are an integrated logistics service provider specializing in LCL consolidation, Air Freight, Project cargo transportation &amp; Cargo handling infrastructure services.</p>--> */}
                <h4 style={{ marginBottom: " 20px"}}>Office Locations</h4>
                <ul class="location-list">
                  <li>
                    <a href="contact">
                      <span>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="angle-left"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                          class="svg-inline--fa fa-angle-left fa-w-6 fa-2x"
                          style={{ marginRight:' 3px'}}
                        >
                          <path
                            fill="#333333"
                            d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"
                            class=""
                          ></path>
                        </svg>
                        Corporate Office
                      </span>
                      {/* <!--<span class="tag">19 Offices</span>--> */}
                    </a>
                  </li>
                  <li>
                    {/* <a href="mumbai.php"> */}
                    <a href="mumbai">
                      <span >
                        <svg
                       
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="angle-left"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                          class="svg-inline--fa fa-angle-left fa-w-6 fa-2x"
                          style={{marginRight:' 3px'}}
                        >
                          <path
                            fill="#333333"
                            d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"
                            class=""
                          ></path>
                        </svg>
                        India
                      </span>
                      <span class="tag">18 Offices</span>
                    </a>
                  </li>
                  <li>
                    {/* <a href="chittagong"> */}
                    <a href="chittagong">
                      <span>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="angle-left"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                          class="svg-inline--fa fa-angle-left fa-w-6 fa-2x"
                          style={{marginRight:' 3px'}}
                        >
                          <path
                            fill="#333333"
                            d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"
                            class=""
                          ></path>
                        </svg>
                        Bangladesh
                      </span>
                      <span class="tag">2 Offices</span>
                    </a>
                  </li>
                  {/* <!--<li>-->
									<!--	<span>-->
									<!--		<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-left fa-w-6 fa-2x"><path fill="#333333" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" class=""></path></svg>-->
									<!--		<a href="contact">Agencies</a>-->
									<!--	</span>-->
									<!--	<span class="tag">2 Agencies</span>-->
									<!--</li>--> */}
                  <li>
                    {/* <a href="kenya"> */}
                    <a href="kenya">
                      <span>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="angle-left"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                          class="svg-inline--fa fa-angle-left fa-w-6 fa-2x"
                          style={{marginRight:' 3px'}}
                        >
                          <path
                            fill="#333333"
                            d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"
                            class=""
                          ></path>
                        </svg>
                        Kenya
                      </span>
                      {/* <!--<span class="tag">2 Offices</span>--> */}
                    </a>
                  </li>
                  <li>
                    {/* <a href="tanzania"> */}
                    <a href="tanzania">
                      <span>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="angle-left"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                          class="svg-inline--fa fa-angle-left fa-w-6 fa-2x"
                          style={{marginRight:' 3px'}}
                        >
                          <path
                            fill="#333333"
                            d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"
                            class=""
                          ></path>
                        </svg>
                        Tanzania
                      </span>
                      {/* <!--<span class="tag">2 Offices</span>--> */}
                    </a>
                  </li>
                </ul>
                <ul class="social-list">
                  <li>
                    <a href="https://www.facebook.com/teamglobal.in/">
                      <img src={facebook} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/teamglobal_log?lang=en">
                      <img src={twitter} alt="twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/teamglobal-logistics-india/mycompany/">
                      <img src={linkdin} alt="linkdin" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCkpQr8hp3puz6qwO2h5SWHA">
                      <img src={youtube} alt="youtube" />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-xl-3 second-col">
                <h4>Services</h4>
                <ul class="footer-nav">
                  <li>
                    <a class="text-capitalize" href="sea-cargo-consolidation">
                      Sea Cargo Consolidation
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="sea-freight-forwarding">
                      Sea Freight Forwarding
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="air-freight">
                      Air Freight
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="project-cargo-forwarding">
                      Project Cargo Forwarding
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="container-freight-station">
                      Container Freight Station
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="coastal-shipping">
                      Coastal Shipping
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4 col-xl-2 digital-offering-col">
                <h4>Ecommerce</h4>
                <ul class="footer-nav">
                  <li>
                    <a
                      class="text-capitalize"
                      href="https://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                    >
                      e-booking
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-capitalize"
                      href="https://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                      target="_blank"
                    >
                      E-SI
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-capitalize"
                      href="http://ecommerce.teamglobal.in/login.aspx"
                      target="_blank"
                    >
                      E-BL
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-capitalize"
                      href="https://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                      target="_blank"
                    >
                      proforma invoice
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-capitalize"
                      href="https://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                      target="_blank"
                    >
                      E-CAN
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="e-do" target="_blank">
                      E-DO
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-capitalize"
                      href="https://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                      target="_blank"
                    >
                      reports
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-capitalize"
                      href="https://tiva.teamglobal.in/"
                      target="_blank"
                    >
                      roadbridge
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4 col-xl-2 sec-last-col">
                <h4>Tools</h4>
                <ul class="footer-nav">
                  <li>
                    <a class="text-capitalize" href="global-locations">
                      Global Locations
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="warehouse-locations">
                      Warehouse Locations
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="inco-terms">
                      INCO Terms
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="shipping-glossary">
                      Shipping Glossary
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="country-restrictions">
                      Country Restrictions
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="freight-calculator">
                      Freight Calculator
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4 col-xl-2 last-col">
                <h4>Quick Links</h4>
                <ul class="footer-nav">
                  <li>
                    <a class="text-capitalize" href="contact">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="team">
                      Our Team
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="milestones">
                      Milestones
                    </a>
                  </li>
                  {/* <!--									<li><a class="text-capitalize" href="customer-advisories?page=1">Advisories</a></li>-->
<!--									<li><a class="text-capitalize" href="news?page=1">News</a></li>--> */}
                  <li>
                    <a class="text-capitalize" href="videos">
                      Videos
                    </a>
                  </li>
                  <li>
                    <a class="text-capitalize" href="careers">
                      Career
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="secondry-footer">
          <div class="container">
            <div class="d-md-flex justify-content-between">
              <p class="copy-rgt">
                Teamglobal Logistics Pvt. Ltd. &copy; 2024 - All Rights
                Reserved.
              </p>
              <div class="secondry-links">
                <a href="privacy-policy">Privacy Policy</a>
                <a href="terms-of-use">Terms of Service</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
