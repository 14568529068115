import React, { useEffect } from "react";
import book from "../../../assets/images/book-icon.svg";
import service from "../../../assets/images/other-service-icon.svg";

import cargo from "../../../assets/images/sea-cargo-coonsolidation-banner.jpg";

import sea from "../../../assets/images/sea-freight-forwarding-banner.jpg";
import lcl from "../../../assets/images/lcl-opratior.svg";

import digital from "../../../assets/images/digital-offerings.svg";
import tool from "../../../assets/images/our-tools-icon.svg";
import Servicefooter from "../servicesfooter";

const SeaFreightConsolidation = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Sea Freight Consolidation";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Sea Freight Consolidation";
  }, []);

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Services</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sea Freight Forwarding
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize"> sea Freight forwarding</h2>
                  <div className="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      className="btn btn-book btn-primary"
                    >
                      <span>
                        <img src={book} />
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" className="btn btn-primary">-->
										<!--	<span>-->
										<!--		<img src="assets/images/Quote-icon.svg">-->
										<!--	</span>Request A Quote-->
										<!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="other-services-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4 left-nav">
                <div className="service-nav ">
                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={service} />
                      </span>
                      Other Services
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="sea-cargo-consolidation">
                          Sea Cargo Consolidation
                        </a>
                      </li>
                      <li className="active">
                        <a href="sea-freight-forwarding">
                          Sea Freight Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="air-freight">Air Freight</a>
                      </li>
                      <li className="">
                        <a href="project-cargo-forwarding">
                          Project Cargo Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="container-freight-station">
                          Container Freight Station
                        </a>
                      </li>
                      <li>
                        <a href="coastal-shipping">Coastal Shipping</a>
                      </li>
                      
                      {/* <li>
                       <a href="transporation">Transporation</a>
                      </li> */}
                      {/* <li>
                       <a href="warehousing">Warehousing</a>
                      </li> */}
                      {/* <li>
                       <a href="customs-clearance">Customs Clearance</a>
                      </li> */}
                      {/* <li>
                       <a href="po-management">Po Management</a>
                      </li> */}
                      {/* <li>
                       <a href="pick-pack">Pick & Pack</a>
                      </li> */}
                      {/* <li>
                        <a href="quality-control-inspection">
                         Quality Control & Inspection 
                        </a>
                      </li> */}
                    </ul>
                  </div>

                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} />
                      </span>
                      Ecommerce
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                          target="_blank"
                        >
                          e-booking
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                          target="_blank"
                        >
                          E-SI
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx"
                          target="_blank"
                        >
                          E-BL
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                          target="_blank"
                        >
                          proforma invoice
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                          target="_blank"
                        >
                          E-CAN
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="e-do"
                          target="_blank"
                        >
                          E-DO
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                          target="_blank"
                        >
                          reports
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://tiva.teamglobal.in/"
                          target="_blank"
                        >
                          roadbridge
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="service-block mb-0">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} />
                      </span>
                      Tools
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="global-locations">Global Locations</a>
                      </li>
                      <li>
                        <a href="warehouse-locations">Warehouse Locations</a>
                      </li>
                      <li>
                        <a
                          href="inco-terms"
                          style={{ textTransform: "inherit" }}
                        >
                          INCO Terms
                        </a>
                      </li>
                      <li>
                        <a href="shipping-glossary">Shipping Glossary</a>
                      </li>
                      <li>
                        <a href="conutry-restrictions">Country Restrictions</a>
                      </li>
                      <li>
                        <a href="freight-calculator">Freight Calculator</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="consolidation-details">
                  <figure>
                    <img src={sea} alt="Sea Freight Forwarding Banner" />
                  </figure>

                  <h4>Sea Freight Forwarding</h4>
                  {/* <p>
                    We have an excellent FCL Forwarding Program being managed by
                    a large professional team that specializes in FCL Forwarding
                    for Inbound and Outbound cargoes.
                    <br />
                    <br />
                    Our large combined volumes of LCL console containers as well
                    as FCL volumes give us the required mass to procure
                    competitive rates and command equally good service from the
                    carriers.
                    <br />
                    <br />
                    Apart from general freight forwarding we are experts at
                    handling DDP and DDU shipments to worldwide destinations.
                    <br />
                    <br />
                    Be it DDP shipments to the US which requires the highest
                    degree of understanding of post arrival procedures, to door
                    deliveries in the African hinterland, we handle them with
                    ease.
                    <br />
                    <br />
                    Out of gauge shipments in special equipment or even flexi
                    tanks we have specialized teams handling these for our
                    customers.
                    <br />
                    <br />
                    Our customer portfolio includes emerging and midsized
                    forwarders, Shippers having their in-house logistics
                    departments, large corporations who have long term rate
                    contracts.
                  </p> */}

                   

                    <p>We have an excellent FCL Forwarding Program being managed by a large professional team that specializes in FCL Forwarding for Inbound and Outbound cargoes.</p>

                    <p>Our large combined volumes of LCL console containers as well as FCL volumes give us the required mass to procure competitive rates and command equally good service from the carriers.</p>

                    <p>Apart from general freight forwarding we are experts at handling DDP and DDU shipments to worldwide destinations.</p>

                    <p>Be it DDP shipments to the US which requires the highest degree of understanding of post arrival procedures, to door deliveries in the African hinterland, we handle them with ease.</p>

                    <p>Out of gauge shipments in special equipment or even flexi tanks we have specialized teams handling these for our customers.</p>

                    <p>Our customer portfolio includes emerging and midsized forwarders, Shippers having their in-house logistics departments, large corporations who have long term rate contracts.</p>
                  

                  {/* <h4>Why we?</h4>
                  <p className="">
                    {" "}
                    <b> Global Reach: </b> We have an extensive network of partners, agents, and carriers worldwide, ensuring your cargo can reach virtually any destination on the map.
                    <br/>


                    <b> Competitive Pricing: </b> Our sea freight services are competitively priced, making it an economical choice for businesses of all sizes.
                    <br/>

                    
                    <b> Customized Solutions: </b> We understand that every cargo shipment is unique. Our team works closely with you to tailor our services to your specific needs, including specialized equipment and handling requirements.
                    <br/>

                    <b> Documentation and Compliance: </b>  Our experts handle all necessary documentation and ensure compliance with customs and international regulations, simplifying the shipping process for you.
                    <br/>

                    <b> 24/7 Tracking and Support: </b> Our tracking systems and dedicated customer support ensure you are always in the loop and can reach out to us whenever you need assistance.
                    <br/>

                    <b> Flexible Incoterms: </b> We work with you to choose the most suitable Incoterms, helping you define responsibilities and costs in the shipping process.
                    <br/>
                    </p> */}


                  {/* <!--<h4>Title 2 - Dummy Content</h4>-->
                                <!--<p>We have 135 direct Export services Ex various ports and ICDs in India making us the largest LCL operator in India. Our large customer base and consistent volumes ensures that we are able to maintain a very high schedule integrity.</p><p> We have dedicated Haz Cargo desk which helps our customers ship their haz cargoes faster. We have an escalation matrix in place ensuring quick resolution to service deviations.-->
                                <!--</p>-->
                                    */}

                  {/* <!--<h4>Title 3 - Dummy Content</h4>-->
                                <!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.<br><br>  We have Direct Services from Global cargo origins to main ports of Nava Sheva and Chennai. The other large ports of Kolkota, Tuticorin and Kochi are covered through international transshipment ports of Colombo, Singapore, Hong Kong and Dubai. We fixed day departures Ex Nava Sheva to New Delhi (ICD Patparganj and ICD Tughlakabad),ICD Ahmedabad and Ex Chennai to ICD Bangalore and ICD Hyderabad.</p>-->
                                
                                <!--<h4>Why Choose Team Global?</h4>-->
                                <!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.</p>-->
                                */}
                </div>

                <div className="most-service">
                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>Pan-India Presence across 18 locations</strong>
                    {/* <!--<p>ICDs in India</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>Contract with major carriers</strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>
                      Presence in 75 countries - expanding global coverage year
                      on year
                      <a
                        href="https://www.wwalliance.com/"
                        target="_blank"
                        style={{
                          paddingLeft: " 0px",
                          color: "#0072BE",
                          display: "block",
                        }}
                      >
                        {" "}
                        WWA - WorldWideAlliance
                      </a>
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>
                      Expertise across industries including HAZ and reefer
                      shipments
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>Online tracking</strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default SeaFreightConsolidation;
