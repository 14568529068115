import './App.css';

import Routers from './Router/Router';

function App() {
  return (
    <Routers/>
   
  );
}

export default App;
