import React from "react";
import webmailpng from "../../assets/images/webmail.png"
import HRMSpng from "../../assets/images/HRMS.png"
import Kautilyapng from "../../assets/images/Kautilya.png"


const tglogin = () => {

    return (
    <>
<div className="content">
<section className="banner-sec customer-login-banner d-flex align-items-center justify-content-center">
  <div className="container ">
    <div className="breadcrumb-wrap">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ background: "transparent" }}>
          <li className="breadcrumb-item">
            <a href="/" style={{ color: "#fff" }}>
              Back To Home
            </a>
          </li>
        </ol>
      </nav>
    </div>
    <h2 className="customer-title text-center">TG Login</h2>
    <div className="customer-login d-md-flex justify-content-between">
      <div className="booking-card">
        <h4>Webmail</h4>
        <p>Access to Emailing Inventory</p>
        <div className="booking-icon">
          <figure>
            <img src={webmailpng} alt="webmail" />
          </figure>
        </div>
        <div className="booking-link">
          <a
            href="https://webmail.teamglobal.in/owa/auth/logon.aspx?replaceCurrent=1&amp;url=https%3a%2f%2fwebmail.teamglobal.in%2fowa"
            className="btn btn-primary Check-btn"
          >
            Login
          </a>
          {/*<a href="register.php" class="registere-link">Register</a>*/}
        </div>
      </div>
      <div className="booking-card">
        <h4>HRMS</h4>
        <p>Your Company registration</p>
        <div className="booking-icon">
          <figure>
            <img src={HRMSpng} alt="HRM" />
          </figure>
        </div>
        <div className="booking-link">
          {/* <a href="https://hrms.teamglobal.in/SpineHRMS/login.aspx?ReturnUrl=%2fSpineHRMS" class="btn btn-primary Check-btn">Login</a> */}
          <a
            href="https://web.workline.hr/"
            className="btn btn-primary Check-btn"
          >
            Login
          </a>
          {/*<a href="register.php" class="registere-link">Register</a>*/}
        </div>
      </div>
      <div className="booking-card">
        <h4>Kautilya - LMS</h4>
        <p>Access to all team global mails</p>
        <div className="booking-icon">
          <figure>
            <img src={Kautilyapng} alt="kautliya" />
          </figure>
        </div>
        <div className="booking-link">
          <a
            href="http://teamglobal.navituslms.com/login"
            className="btn btn-primary Check-btn"
          >
            Login
          </a>
          {/*<a href="register.php" class="registere-link">Register</a>*/}
        </div>
      </div>
    </div>
  </div>
</section>
</div>

        
        
    </>)
}

export default tglogin