import React, { useEffect } from "react";

import book from "../../assets/images/book-icon.svg";
import tool from "../../assets/images/our-tools-icon.svg";
import Servicefooter from "../services/servicesfooter";
const INCOTerm = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "INCO Term";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "INCOTerm";
  }, []);

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Tools</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    INCO Terms
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize">
                    {/* <!--<span className="global-icn">-->
										<!--	<img src="assets/images/global-icon.svg">-->
										<!--</span>--> */}
                    INCO Terms
                  </h2>
                  <div className="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      className="btn btn-book btn-primary"
                    >
                      <span>
                        <img src={book} />
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" className="btn btn-primary">-->
										<!--	<span>-->
										<!--		<img src="assets/images/Quote-icon.svg">-->
										<!--	</span>Request A Quote-->
										<!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--<section className="global-services-sec">--> */}
        <section className="other-services-sec other-locations">
          <div className="container-fluid" style={{ padding: "0 9%" }}>
            <div className="row">
              <div className="col-md-3 left-nav">
                <div className="service-nav ">
                  <div className="service-block mb-0">
                    <h6 className="service-title">
                      <span>
                        <img src={tool} />
                      </span>
                      Tools
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="global-locations">Global Locations</a>
                      </li>
                      <li>
                        <a href="warehouse-locations">
                          Warehourse Locations
                        </a>
                      </li>
                      <li className="active">
                        <a
                          href="inco-terms"
                          style={{ textTransform: "inherit" }}
                        >
                          INCO Terms
                        </a>
                      </li>
                      <li>
                        <a href="shipping-glossary">Shipping Glossary</a>
                      </li>
                      <li>
                        <a href="country-restrictions">
                          Country Restrictions
                        </a>
                      </li>
                      <li>
                        <a href="freight-calculator">Freight Calculator</a>
                      </li>
                    </ul>
                  </div>

                  {/* <!--<div className="service-block active d-flex justify-content-between align-items-center"> -->
									<!--	<span className="cust-img"> -->
									<!--		< !--<img src="assets/images/global-locations.svg">-- >-->
									<!--		<img src="assets/images/global-loc.svg">-->
									<!--	</span>-->
									<!--	<div className="service-title">-->
									<!--		<h6> Global Locations </h6> -->
											
									<!--		<span>-->
									<!--			<img src="assets/images/arow-right-01.svg">-->
									<!--		</span>-->
									<!--	</div>-->
										
									<!--</div>-->
									
									
									<!--<a href="warehouse-loc" className="w-100">-->
									<!--    <div className="service-block d-flex justify-content-between"> -->
    					<!--					<span className="cust-img"> -->
    					<!--						< !--<img src="assets/images/warehourse-icn.svg">-- >-->
    					<!--						<img src="assets/images/warehouse-loc.svg">-->
    					<!--					</span>-->
    					<!--					<div className="service-title">-->
    					<!--						<h6> Warehourse Location </h6>-->
    					<!--						<p className="check-now">Check Now</p>-->
    					<!--					</div>-->
									<!--	</div>-->
									<!--</a>-->
									
									<!--<a href="inco-terms" className="w-100">-->
									<!--    <div className="service-block d-flex justify-content-between"> -->
    					<!--					<span className="cust-img"> -->
    					<!--						< !--<img src="assets/images/inco-icn.svg">-- >-->
    					<!--						<img src="assets/images/inco-terms.svg">-->
    					<!--					</span>-->
    					<!--					<div className="service-title">-->
    					<!--						<h6> INCO Terms </h6>-->
    					<!--						<p className="check-now">Check Now</p>-->
    					<!--					</div>-->
										
								 <!--   	</div>-->
									<!--</a>-->
									
									<!--<a href="shipping-glossary.php" className="w-100">-->
									<!--    <div className="service-block d-flex justify-content-between"> -->
    					<!--					<span className="cust-img"> -->
    					<!--						< !--<img src="assets/images/shipping-icn.svg">-- >-->
    					<!--						<img src="assets/images/shipping-glossary.svg">-->
    					<!--					</span>-->
    					<!--					<div className="service-title">-->
    					<!--						<h6> Shipping Glossary </h6>-->
    					<!--						<p className="check-now">Check Now</p>-->
    					<!--					</div>-->
    										
    					<!--				</div>-->
									<!--</a>-->
									
									<!--<a href="conutry-restrictions.php" className="w-100">-->
									<!--    <div className="service-block d-flex justify-content-between"> -->
    					<!--					<span className="cust-img"> -->
    					<!--						< !--<img src="assets/images/country-icn.svg">-- >-->
    					<!--						<img src="assets/images/country-restrictions.svg">-->
    					<!--					</span>-->
    					<!--					<div className="service-title">-->
    					<!--						<h6> Country Restrictions </h6>-->
    					<!--						<p className="check-now">Check Now</p>-->
    					<!--					</div>-->
    										
    					<!--				</div>-->
									<!--</a>-->
									
									<!--<a href="freight-calc.php" className="w-100">-->
    					<!--				<div className="service-block d-flex justify-content-between"> -->
    					<!--					<span className="cust-img"> -->
    					<!--					    < !--<img src="assets/images/freight-icn.svg">-- >-->
    					<!--						<img src="assets/images/freight-calc.svg">-->
    					<!--					</span>-->
    					<!--					<div className="service-title">-->
    					<!--						<h6> Freight Calculator</h6>-->
    					<!--						<p className="check-now">Check Now</p>-->
    					<!--					</div>-->
    										
    					<!--				</div>-->
									<!--</a>-->
									 */}
                </div>
              </div>
              <div className="col-md-9">
                <iframe
                  className="responsive-iframe"
                  style={{height: '100%' , width: '100%'}}
                  src="https://wwav2.wwalliance.com/bells-and-whistles/inco-terms"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default INCOTerm;
