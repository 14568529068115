import { Link } from "react-router-dom";
import searchicon from "../../assets/images/form-search-icon.png";
import fb from "../../assets/images/fb.svg";
import service from "../../assets/images/other-service-icon.svg";
import digital from "../../assets/images/digital-offerings.svg";
import tools from "../../assets/images/our-tools.svg";
import twitter from "../../assets/images/tw.svg";
import linkedin from "../../assets/images/in.svg";
import youtube from "../../assets/images/yt.svg";
const MalaAward2016 = () => {
  return (
    <div className="content">
      <section className="cargo-consolidation">
        <div className="container">
          <div className="breadcrumb-wrap">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="..//">
                    <a href="/">Home</a>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="../videos">
                    <a href="videos.php?page=1">Videos</a>
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  MALA AWARDS 2016
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-md-flex justify-content-between">
                <h2 className="text-capitalize"> In The Videos</h2>
                <div className="btn-wrap">
                  <form
                    className="news-search"
                    action="search-videos.php"
                    method="GET"
                  >
                    <div className="d-md-flex align-items-center justify-content-between">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="searchinput"
                          id="inputCity"
                          placeholder="Search specific"
                        />

                        <button type="submit" className="search-btn">
                          <Link to="../search">
                            <img src={searchicon} alt="" />
                          </Link>
                        </button>
                      </div>
                      <div className="form-group ">
                        <select id="inputState" className="form-control">
                          <option selected="">Sort By Date</option>
                          <option>...</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="in-news-sec">
        <div className="container other-services-sec">
          <div className="row">
            <div className="col-md-4 left-nav">
              <div className="other-news">
                <h4>
                  Other videos{" "}
                  <a href="videos.php?page=1" className="view-all-news">
                    View All videos
                  </a>
                </h4>

                <div className="d-flex view-img justify-content-between">
                  <iframe
                    title="."
                    width="50"
                    height="50"
                    src="https://www.youtube.com/embed/PiAaEqLu9V8"
                  ></iframe>
                  <div className="post-title">
                    <span>22 June 2021</span>
                    <h6>Awards 2018 - ET Now 6th Samudra Manthan</h6>
                  </div>
                </div>

                <div className="d-flex view-img justify-content-between">
                  <iframe
                    title="."
                    width="50"
                    height="50"
                    src="https://www.youtube.com/embed/3FZ8Wv-Pspc"
                  ></iframe>
                  <div className="post-title">
                    <span>22 June 2021</span>
                    <h6>MALA AWARDS 2016</h6>
                  </div>
                </div>

                <div className="d-flex view-img justify-content-between">
                  <iframe
                  title="."
                    width="50"
                    height="50"
                    src="https://www.youtube.com/embed/iLyi0eZjM1w"
                  ></iframe>
                  <div className="post-title">
                    <span>22 June 2021</span>
                    <h6>MALA Awards 2015</h6>
                  </div>
                </div>

                <div className="d-flex view-img justify-content-between">
                  <iframe
                  title="."
                    width="50"
                    height="50"
                    src="https://www.youtube.com/embed/2fO_QNxeE_0"
                  ></iframe>
                  <div className="post-title">
                    <span>22 June 2021</span>
                    <h6>MALA Awards 2014</h6>
                  </div>
                </div>

                <div className="d-flex view-img justify-content-between">
                  <iframe
                    title="."
                    width="50"
                    height="50"
                    src="https://www.youtube.com/embed/HVtXFoi9MeU"
                  ></iframe>
                  <div className="post-title">
                    <span>22 June 2021</span>
                    <h6>MALA Awards 2013</h6>
                  </div>
                </div>
              </div>

              <div className="service-nav ">
                <div className="service-block">
                  <h6 className="service-title">
                    <span>
                      {/* <img src="assets/images/other-service-icon.svg" /> */}
                      <img src={service} alt="" />
                    </span>
                    Other Services
                  </h6>

                  <ul className="service-list">
                    <li>
                      <a href="sea-cargo-consolidation.php">
                        Sea Cargo Consolidation
                      </a>
                    </li>
                    <li>
                      <a href="sea-freight-forwarding.php">
                        Sea Freight Forwarding
                      </a>
                    </li>
                    <li className="">
                      <a href="air-freight.php">Air Freight</a>
                    </li>
                    <li className="">
                      <a href="project-cargo-forwarding.php">
                        Project Cargo Forwarding
                      </a>
                    </li>
                    <li>
                      <a href="container-freight-station.php">
                        Container Freight Station
                      </a>
                    </li>
                    <li>
                      <a href="coastal-shipping.php">Coastal Shipping</a>
                    </li>
                  </ul>
                </div>

                <div className="service-block">
                  <h6 className="service-title">
                    <span>
                      {/* <img src="assets/images/digital-offerings.svg" /> */}
                      <img src={digital} alt="" />
                    </span>
                    Ecommerce
                  </h6>

                  <ul className="service-list">
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                        target="_blank"
                      >
                        e-booking
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                        target="_blank"
                        rel="noreferrer"
                      >
                        E-SI
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://ecommerce.teamglobal.in/login.aspx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        E-BL
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://tiva.teamglobal.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        roadbridge
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                        target="_blank"
                        rel="noreferrer"
                      >
                        E-CAN
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="e-do.php"
                        target="_blank"
                        rel="noreferrer"
                      >
                        E-DO
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                        target="_blank"
                        rel="noreferrer"
                      >
                        proforma invoice
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-capitalize"
                        href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                        target="_blank"
                        rel="noreferrer"
                      >
                        reports
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="service-block">
                  <h6 className="service-title">
                    <span>
                      {/* <img src="assets/images/our-tools.svg" /> */}
                      <img src={tools} alt="" />
                    </span>
                    Tools
                  </h6>

                  <ul className="service-list">
                    <li className="">
                      <a href="global-locations.php">Global Locations</a>
                    </li>
                    <li>
                      <a href="warehouse-locations.php">Warehourse Locations</a>
                    </li>
                    <li>
                      <a
                        href="inco-terms.php"
                        style={{ textTransform: " inherit" }}
                      >
                        INCO Terms
                      </a>
                    </li>
                    <li>
                      <a href="shipping-glossary.php">Shipping Glossary</a>
                    </li>
                    <li>
                      <a href="country-restrictions.php">
                        Country Restrictions
                      </a>
                    </li>
                    <li>
                      <a href="freight-calculator.php">Freight Calculator</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8 in-news-details">
              <h2>MALA AWARDS 2016</h2>

              <p className="in-title">
                <span>22 June 2021 - by Teamglobal</span>
              </p>

              <iframe
                title="."
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/3FZ8Wv-Pspc"
              ></iframe>

              <p className="desc"></p>
              <p>
                View the awards ceremony of Teamglobal receiving MALA 2016
                Awards.
              </p>
              <p></p>

              <div className="comment-box">
                <h4>Leave Comment</h4>
                <span>
                  Your email address will not be published. Required fields are
                  marked *
                </span>
                <label
                  id="msg"
                  style={{
                    fontSize: "inherit",
                    color: "red",
                    textTransform: "capitalize",
                    fontStyle: "italic",
                  }}
                ></label>

                <form method="post" action="save_video_comment.php">
                  <div className="form-row">
                    <input
                      type="hidden"
                      className="form-control"
                      id=""
                      name="pid"
                      value="18"
                      placeholder="Name"
                    />

                    <div className="form-group col-md-12">
                      <textarea
                        type="text"
                        className="form-control"
                        id=""
                        name="comment"
                        rows="5"
                        placeholder="Add a comment…"
                      ></textarea>
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        name="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        type="email"
                        className="form-control"
                        id=""
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        name="website"
                        placeholder="Website"
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn post-btn">
                    Post Comment
                  </button>
                </form>
              </div>

              <div className="feedback-cmm">
                <h4 className="recent-posts">Recent Comments</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="share-position d-none d-md-block">
          <ul className="">
            <li>
              <h2>677</h2>
              <span>Shares</span>
            </li>
            <li>
              <a href="https://www.facebook.com/sharer.php">
                {/* <img src="assets/images/fb.svg" /> */}
                <img src={fb} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/intent/tweet">
                {/* <img src="assets/images/tw.svg" /> */}
                <img src={twitter} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/shareArticle/?url=https://www.teamglobal.in/faq/mala-awards-2015/">
                {/* <img src="assets/images/in.svg" /> */}
                <img src={linkedin} alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                {/* <img src="assets/images/yt.svg" /> */}
                <img src={youtube} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
export default MalaAward2016;
