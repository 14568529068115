import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import member1 from "../../assets/images/about/member1.png";
import member2 from "../../assets/images/about/member2.png";
import member3 from "../../assets/images/about/member3.png";
import member4 from "../../assets/images/about/member4.png";
import member6 from "../../assets/images/about/member6.png";
import member8 from "../../assets/images/about/member8.png";
import member11 from "../../assets/images/about/member11.png";
import jacob from "../../assets/images/about/jacob-thomas.jpg";
import rampal from "../../assets/images/about/rampal-tandon.jpg";
import sandeep from "../../assets/images/about/sandeep.png";
import manoj from "../../assets/images/about/manoj-goplani.png";
import gayatri from "../../assets/images/about/gayatri-shroff.jpg";
import sujit from "../../assets/images/about/sujit-baral.jpg";
import naveen from "../../assets/images/about/naveen.png";
//import teamglobal from "../../assets/images/teamGloble.jpg";
import teamglobal from "../../assets/images/ESGFocusAreas.jpeg";
import SolarPanel from "../../assets/images/SolarPanel.JPG";

import Twlogo from "../../assets/images/TW-Logo.svg";

const Esg = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Esg";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Esg";
  }, []);

  return (
    <>
      <div className="content">
        <section className="contact-head">
          <div className="cargo-consolidation about-txt">
            <div className="container">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <a href="/">Home</a>
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Sustainability
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                     ESG Focus Area And Goals
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex align-items-center">
                    <h2 className="text-capitalize">
                    ESG Focus Area And Goals
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-tabs-sec">
          <div className="container">
            <div className="">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    href="esg-framework"
                    className="nav-link"
                    id="esg-framework-tab"
                  >
                    Framework
                    {/* Esg Framework */}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-governance-structure-and-roadmap"
                    className="nav-link"
                    id="esg-governance-structure-and-roadmap-tab"
                  >
                    {" "}
                    Governance Structure and Roadmap
                    {/* Esg Governance Structure and Roadmap */}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-focus-area-and-goals"
                    className="nav-link active"
                    id="esg-focus-area-and-goals-tab"
                  >
                    {" "}
                    Focus Area And Goals
                    {/* Esg Focus Area and Goals */}
                  </a>
                </li>

                {/*                 
                <li className="nav-item">
                  <a
                    href="policies-and-commitments"
                    className="nav-link "
                    id="policies-and-commitments"
                  >
                    POLICIES AND COMMITMENTS
                  </a>
                </li> */}

                <li className="nav-item">
                  <a className="nav-link " href="leadership-commitment">
                  Leadership Commitment
                  </a>
                </li>
                {/*<li className="nav-item">
                  <a
                    href="esg-milestone"
                    className="nav-link"
                    id="esg-milestone-tab"
                  >
                    Milestones
       
                  </a>
                </li>
              */}
                <li className="nav-item">
                  <a
                    href="success-stories"
                    className="nav-link"
                    id="success-stories-tab"
                  >
                    Success Stories
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show "
                  id="tablevel1"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="about-tab-content">
                    {/* <h2 className="team-title text-capitalize text-center">
                      As Teamglobal, we strive to offer our customers a well
                      defined and a well structured logistics services.
                    </h2> */}
                    <h2 className="team-title text-capitalize text-center">
                      Esg Framework
                    </h2>

                    <div className="row">
                      <div className="col-md-12">
                        <figure className="about-banner">
                          <img src={teamglobal} alt="Team Photo" />
                        </figure>
                      </div>
                      <div className="col-md-12">
                        {/* <h4 className="post-title">
                          Teamglobal has been certified and affiliated with
                          listed agencies and associations
                        </h4> */}
                        <p className="">
                          {" "}
                          Teamglobal is an integrated Logistics services
                          provider headquartered in India. We are offering
                          services in multiple segments of logistics Viz. Sea
                          Freight, Airfreight, Project Cargo Transportation,
                          Cargo Terminals and Coastal Shipping. It has 18
                          offices in India, Bangladesh, Kenya and Tanzania.
                        </p>
                        <h6 className="text-capitalize">accreditation</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      {/* <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2> */}
                      <h2 className="team-title text-capitalize text-center">
                        Esg Framework
                      </h2>

                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        {/* <div className="col-md-12">
                                                                    <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                                                    <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                                    <h6 className="text-capitalize">accreditation</h6>


                                                                </div> */}
                      </div>
                    </div>
                    {/* <!--<h2 className="team-title text-capitalize text-center">Our Team</h2>--> */}
                    <div className="team-wrap">
                      <h4 className="team-title text-capitalize text-start">
                        Committee Leaders
                      </h4>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member1} alt="" />
                            </figure>
                            <strong className="d-block">Nityam Khosla</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member2} alt="" />
                            </figure>
                            <strong className="d-block">Vivek Kele</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member3} alt="" />
                            </figure>
                            <strong className="d-block">
                              Rajesh Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Export LCL Console
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member4} alt="" />
                            </figure>
                            <strong className="d-block">
                              Hemant Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Logistics
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={jacob} alt="" />
                            </figure>
                            <strong className="d-block">Jacob Thomas</strong>
                            <span className="user-pos">
                              Director - Commercial
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member6} alt="" />
                            </figure>
                            <strong className="d-block">Tejan Lakhia</strong>
                            <span className="user-pos">
                              Sr. VP - FCL Sea Freight
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                      <h4 className="team-title text-capitalize text-start">
                        {" "}
                        Committee Member{" "}
                      </h4>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member11} alt="" />
                            </figure>
                            <strong className="d-block">Shibu Paul</strong>
                            <span className="user-pos">
                              {" "}
                              Sr. VP - WWA Business Development
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={rampal} alt="" />
                            </figure>
                            <strong className="d-block">Rampal Tandon</strong>
                            <span className="user-pos">
                              VP - Freight Management
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member8} alt="" />
                            </figure>
                            <strong className="d-block">
                              Gaurang Timbadiya
                            </strong>
                            <span className="user-pos">VP - Finance</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
                                                        </div> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sandeep} alt="" />
                            </figure>
                            <strong className="d-block">
                              Sandeep Palodkar
                            </strong>
                            <span className="user-pos">
                              VP - HR/IR, Admin, Business Excellence
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={manoj} alt="" />
                            </figure>
                            <strong className="d-block">Manoj Gopalani</strong>
                            <span className="user-pos">
                              VP - Information Technology
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={gayatri} alt="" />
                            </figure>
                            <strong className="d-block">Gayatri Shroff</strong>
                            <span className="user-pos">GM - Commercial</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sujit} alt="" />
                            </figure>
                            <strong className="d-block">Sujit Baral</strong>
                            <span className="user-pos">
                              GM - Regional Head –South
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={naveen} alt="" />
                            </figure>
                            <strong className="d-block">Naveen Kanaujia</strong>
                            <span className="user-pos">GM – Imports</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                      <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                        <div classNameName="col-md-6">
                          {/* <ol classNameName=" ">
                                                        <li className="">POSH</li>
                                                        <li className="">Cyber Security</li>
                                                        <li className="">Human Resource</li>
                                                        <li className="">IT Policy</li>
                                                        <li className="">ESG Policy</li>
                                                    </ol> */}

                          <ol className="list-group">
                            <li className="list-group-item">
                              POSH
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              Cyber Security
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              Human Resource
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              IT Policy
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              ESG Policy
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      {/* <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2> */}
                      <h2 className="team-title text-capitalize text-center">
                       ESG Focus Area And Goals
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={SolarPanel} alt="Team Photo" />
                          </figure>
                        </div>

                        <div className="col-md-12">
                        {/*}  <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4> */}
                          {/* <h4>ESG Governance </h4>
                          <p>Teamglobal Logistics will implement the ESG policy with following mechanism:</p>
                          <ul>
                            <li>
                            <p>
                            Strategic Guidance by the Board of Directors
                            </p>
                            </li>
                           
                            <li>
                            <p>Resource allocation and progress monitoring by ESG Leadership Committee</p>
                            </li>
                            <li>
                            <p>Development of systems and practices for actions, data management, and capacity building by ESG Management Committee</p>
                            </li>
                          </ul> */}
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                      <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                        <div className="col-md-12">
                          {/* <h6 className="text-capitalize">Focus Area</h6> */}
                          {/* <h6 className="text-capitalize">ESG Roadmap </h6> */}
                          <h6> Focus Areas and Goals </h6>

                          <p className="">
                            {" "}
                            Teamglobal Logistics conducted a materiality
                            assessment exercise to identify ESG aspects that are
                            most important for Teamglobal Logistics’ business
                            and its stakeholders. The materiality assessment
                            followed the approach prescribed by the Global
                            Reporting Initiative (GRI) standards.
                            <br />
                            The high priority material topics are;
                            <br />
                            <br />
                            <table className="table table-bordered">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">
                                    {" "}
                                    <strong>Environment </strong>
                                  </th>
                                  <th scope="col">
                                    {" "}
                                    <strong>Social </strong>
                                  </th>
                                  <th scope="col">
                                    {" "}
                                    <strong>Governance </strong>{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {" "}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 1. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Promotion of Sustainable Behavior for
                                        Resource Conservation
                                      </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 2. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Energy Efficiency in Operations
                                      </div>
                                    </div>
                                    <br />
                                    {/* <br /> */}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 3. </b>
                                      </div>
                                      <div className="col-md-11">
                                        GHG Emission Reduction
                                      </div>
                                    </div>
                                    {/* <b> 3. </b> GHG Emission Reduction */}
                                    <br />
                                    {/* <br /> */}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 4. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Management of climate related physical
                                        risks
                                      </div>
                                    </div>
                                    <br />
                                    {/* <br /> */}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 5. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Low Carbon Logistics Services
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 1. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Occupational Health and Safety
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 2. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Accident Management
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 3. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Employee Diversity & Inclusion
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 4. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Talent Attraction and Retention
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 5. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Community Relations
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 6. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Grievance Management & Whistleblower
                                        Protection
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 1. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Compliance Management
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 2. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Procurement of Sustainable Products and
                                        Services
                                      </div>
                                    </div>
                                    <br />
                                    {/* <br /> */}
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 3. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Supply Chain Management
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 4. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Data Security and Privacy
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 5. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Business Ethics
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                    <div className="row">
                                      <div className="col-md-1">
                                        <b> 6. </b>
                                      </div>
                                      <div className="col-md-11">
                                        Corporate Governance
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                    <br />
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    {" "}
                                    <b> 2. </b> Energy Efficiency in Operations
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 2. </b> Accident Management
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 2. </b> Procurement of Sustainable
                                    Products and Services
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <b> 3. </b> GHG Emission Reduction
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 3. </b> Employee Diversity & Inclusion
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 3. </b> Supply Chain Management
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <b> 4. </b> Management of climate related
                                    physical risks{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 4. </b> Talent Attraction and Retention
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 4. </b> Data Security and Privacy
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <b> 5. </b> Low Carbon Logistics Services{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 5. </b> Community Relations
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 5. </b> Business Ethics
                                  </td>
                                </tr>
                                <tr>
                                  <td> </td>
                                  <td>
                                    {" "}
                                    <b> 6.</b> Grievance Management &
                                    Whistleblower  Protection
                                  </td>
                                  <td>
                                    {" "}
                                    <b> 6. </b> Corporate Governance
                                  </td>
                                </tr> */}
                              </tbody>
                            </table>
                            <br />
                            The high priority material topics have been used to
                            define ESG focus areas and associated commitments
                            for 2023-2030 horizon.
                          </p>
                          <h6>
                            {" "}
                            Focus Area 1: Innovation for Low Carbon Logistics
                          </h6>
                          <p className="">
                            {" "}
                            Commitments
                            <br />
                            <ul>
                              <li>
                                Pioneer low carbon logistics services across
                                sea, air, land mode of transportation, through
                                innovative business models.
                              </li>
                              <li>
                                Increase share of renewable energy and use
                                greener fuels to meet energy demand across
                                operations.
                              </li>
                              <li>
                                Achieve carbon neutrality in operations at
                                Container Freight Stations (CFSs)
                              </li>
                              <li>
                                Encourage employees, business partners, and
                                suppliers to proactively reduce their carbon
                                footprint.
                              </li>
                            </ul>
                          </p>
                          <h6>
                            Focus Area 2: Adoption of 3R-Reduce, Reuse, Recycle
                            for Resource Conservation
                          </h6>
                          <p className="">
                            {""}
                            Commitments
                            <br />
                            <ul>
                              <li>
                                Adopt 3R principles to reduce resource
                                consumption (energy, water, materials) in
                                offices, at Container Freight Stations (CFSs),
                                and company events.
                              </li>
                              <li>
                                Prioritize procurement of sustainable products
                                and services.
                              </li>
                              <li>
                                Ensure safe handling and source segregation for
                                all waste streams.
                              </li>
                              <li>
                                Minimize waste sent to landfills by improving
                                waste recycling rates and undertaking proper
                                disposal of non-recyclable waste streams at
                                Container Freight Stations (CFSs).
                              </li>
                            </ul>
                          </p>
                          <h6>
                            Focus Area 3: Health and Well-being of Employees
                          </h6>
                          <p className="">
                            {""}
                            Commitments
                            <br />
                            <ul>
                              <li>
                                Adopt best practices for health & safety
                                management for accident prevention, mitigation
                                of OHS risks in own as well as sub-contracted
                                operations.
                              </li>
                              <li>
                                Conduct health and safety focused awareness and
                                training for own employees as well as workers
                                employed through contracted operations.
                              </li>
                              <li>
                                Offer equal opportunities to employees and
                                foster diversity and social inclusion.
                              </li>
                              <li>
                                Routinely assess employee’s well-being and their
                                needs for career development and take required
                                actions for improvement.
                              </li>
                            </ul>
                          </p>
                          <h6>Focus Area 4: Corporate Governance</h6>
                          <p className="">
                            {" "}
                            Commitments
                            <br />
                            <ul>
                              <li>
                                Establish systems for strategic risk assessment
                                and take actions to mitigate the risks in own
                                operations as well as operations of critical
                                business partners and suppliers.
                              </li>
                              <li>
                                Adopt best practices for corporate governance to
                                ensure ethical and responsible business conduct.
                              </li>
                              <li>
                                Adopt a collaborative approach to engage with
                                local community, proactively address their
                                concerns, and undertake actions for community
                                welfare.
                              </li>
                              <li>
                                Actively engage with business community,
                                government, larger civic society to advocate for
                                a conducive ecosystem for adoption of
                                sustainable practices in logistics sector.
                              </li>
                            </ul>
                            
                          </p>

                          <h6>Quality Policy</h6>
                          <p className="">
                            {" "}
                            Our endeavor is to set new standards in providing cost effective logistics solutions with an emphasis on safe & timely deliveries thereby exceeding our customer’s expectations, while protecting Confidentiality, Integrity and Availability of information.
                            <br /><br/>
                            We shall build business on principles of mutual trust, fairness and Integrity.<br />
                            This shall be achieved by: <br /><br/>
                            <ul>
                              <li>
                              Adopting right technology
                              </li>
                              <li>
                              Building competencies in people
                              </li>
                              <li>
                              Addressing service deviations through continual process monitoring and improvement
                              </li>
                              <li>
                              Effective customer relationship
                              </li>
                              <li>
                              Establishing, implementing and maintaining the quality management system
                              </li>
                            </ul>
                            
                          </p>

                          {/* <table class="table table-striped"> */}

                          {/* Teamglobal Logistics will use a strategic ESG roadmap to address priority focus areas, develop targets, monitoring mechanisms, and disclose ESG performance in accordance with global ESG standard reporting frameworks.  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Esg;
