import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/slick.css';
import './assets/css/style-quote.css';
import  './assets/css/global.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/style-ext.css';
import './assets/font/CircularStd-Book.woff';
import './assets/font/CircularStd-Book.woff2';
import './assets/font/DJBGetDigital.woff';
import './assets/font/DJBGetDigital.woff2';
import './assets/font/Gothic720BT-RomanB.woff';
import './assets/font/Gothic720BT-RomanB.woff2';
import './assets/font/HelveticaNeueBold.woff';
import './assets/font/HelveticaNeueBold.woff2';
import 'bootstrap/dist/css/bootstrap.min.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();


