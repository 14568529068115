import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCube,faPaperPlane,faPlus,faMinus,} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BASE_URL from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

function Fcl() {
  const [cargoType, setCargoType] = useState("General");

  const [formData, setFormData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [errors, setErrors] = useState({});

  const [baseFormData, setBaseFormData] = useState({
    origin: "",
    destination: "",
    cargo_ready_date: "",
    note: "",
    move_type: "",
    cargo_detail: "",
    commodity: "",
    temp_range: "",
    un_no: "",
    imco_range: "",
  });
  const [userFormData, setUserFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });


  const handleCargoTypeChange = (event) => {
    
    setCargoType(event.target.value);
  };

  const [containerCounts, setContainerCounts] = useState({
    "gp-20": 0,
    "gp-40": 0,
    "hc-40": 0,
    "hc-45": 0,
  });

  const handleIncrement = (target) => {
    setContainerCounts((prevCounts) => ({
      ...prevCounts,
      [target]: prevCounts[target] + 1,
    }));
  };

  const handleDecrement = (target) => {
    setContainerCounts((prevCounts) => ({
      ...prevCounts,
      [target]: prevCounts[target] - 1,
    }));
  };
  const handleBaseFormChange = (e) => {
    // setBaseFormData(event.target.value);
    setBaseFormData({
      ...baseFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserFormChange = (e) => {
    // esetUserFormData(event.target.value);
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(baseFormData);
    console.log(userFormData);
    console.log(containerCounts);

    let combinedData = {
      ...baseFormData,
      ...userFormData,
      ...containerCounts,
    };

    try {
      const response = await axios.post(`${BASE_URL.pktapi}./Lcl-form`, combinedData);

      if (response.status === 200) {
        setSubmissionStatus("success");
        setBaseFormData({
          origin: "",
          destination: "",
          cargo_ready_date: "",
          note: "",
          move_type: "",
          cargo_detail: "",
          commodity: "",
          temp_range: "",
          un_no: "",
          imco_range: "",
        });
        setContainerCounts({
          "gp-20": 0,
          "gp-40": 0,
          "hc-40": 0,
          "hc-45": 0,
        });
        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        setErrors({});
      }
    } 
    catch (error) {
      console.error("Error submitting form:", error);
      //setErrors(error.response.data);
      setSubmissionStatus("error");
      setErrors(error.response.data);
      let x = '';
      const entries = Object.entries(error.response.data.data);

      entries.map(([key, value]) => {
        console.log(key+" "+value);
        x+=key+":"+value+"<br>";
      });
      Swal.fire({
        title: 'Error!',
        html:  error.response.data.message+"<br>"+x,
        icon: 'error',
        confirmButtonText: 'OK',
      });
     
    }
  };

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Get A Quote
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize"> Get A Quote</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="other-services-sec" id="form_sec">
          <div className="container bggs">
            <tg-header>
              <nav className="nav nav-tabs flex-row h5 font-weight-light border-bottom">
                <a
                  className="p-4 flex-fill text-sm-center nav-link"
                  aria-current="page"
                  href="/Lcl"
                >
                  <FontAwesomeIcon icon={faCube} aria-hidden="true" /> LCL
                </a>
                <a
                  className="p-4 flex-fill text-sm-center nav-link active"
                  href="/Fcl"
                >
                  <FontAwesomeIcon icon={faCube} aria-hidden="true" /> FCL
                </a>
                <a
                  className="p-4 flex-fill text-sm-center nav-link"
                  href="/Air"
                >
                  <FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" /> Air
                </a>
              </nav>
            </tg-header>
            <div className="container mb-4">
              <main>
                <div className="row gx-5 mt-3">
                  <div className="container-fluid form-group">
                    <form id="fcl-form" noValidate="">
                      <div className="row g-3">
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="form-floating">
                                <input
                                  required=""
                                  type="text"
                                  name="origin"
                                  className="form-control"
                                  id="origin"
                                  placeholder="Origin"
                                  value={baseFormData.origin}
                                  onChange={handleBaseFormChange}
                                />
                                <label htmlFor="origin">Origin</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter origin
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="form-floating">
                                <input
                                  required=""
                                  type="text"
                                  name="destination"
                                  className="form-control"
                                  id="destination"
                                  placeholder="Destination"
                                  value={baseFormData.destination}
                                  onChange={handleBaseFormChange}
                                />
                                <label htmlFor="destination">Destination</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter destination
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="input-group">
                                <div className="form-floating flex-1">
                                  <input
                                    required=""
                                    type="date"
                                    name="cargo_ready_date"
                                    className="form-control"
                                    id="date"
                                    placeholder="Cargo Readiness Date"
                                    min="2024-02-28"
                                    value={baseFormData.cargo_ready_date}
                                    onChange={handleBaseFormChange}
                                  />
                                  <label htmlFor="date">
                                    Cargo Readiness Date
                                  </label>
                                </div>
                                <div className="invalid-feedback">
                                  Please enter date
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="card tg-measure">
                            <div className="card-body">
                              <span className="required" />
                              <label className="mx-2 p-2">Containers</label>
                              <div className="row pb-2 container-data-row">
                                {Object.entries(containerCounts).map(
                                  ([key, value]) => (
                                    <div className="col-sm-3" key={key}>
                                      <div className="input-group flex-nowrap align-items-center">
                                        <span className="input-group-btn">
                                          <button
                                            name="key" 
                                            type="button"
                                            className="btn btn-link text-dark value-control bg-light"
                                            onClick={() => handleDecrement(key)}
                                            value={value}
                                            onChange={handleBaseFormChange}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMinus}
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </span>
                                        <div className="form-floating cnttr">
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={value}
                                            readOnly
                                            name={key}
                                          />
                                          <label>{key}</label>
                                        </div>
                                        <span className="input-group-btn">
                                          <button
                                            type="button"
                                            className="btn btn-link text-dark value-control bg-light"
                                            onClick={() => handleIncrement(key)}
                                            value={value}
                                            onChange={handleBaseFormChange}
                                          >
                                            <FontAwesomeIcon
                                              icon={faPlus}
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="invalid-feedback" id="requiredf">
                                At least one field is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="align-middle border border-2 rounded p-2 p-sm-3 pb-3 row m-0">
                          <div className="align-middle col-sm-2 fw-600">
                            Cargo Details
                          </div>
                          <div className="col-sm-10">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="cargo_detail"
                                id="general"
                                value="General"
                                checked={cargoType === "General"}
                                onChange={(e) => {
                                  handleCargoTypeChange(e);
                                  handleBaseFormChange(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="general"
                              >
                                General
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="cargo_detail"
                                id="hazardous"
                                value="Hazardous"
                                checked={cargoType === "Hazardous"}
                                onChange={(e) => {
                                  handleCargoTypeChange(e);
                                  handleBaseFormChange(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hazardous"
                              >
                                Hazardous
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="cargo_detail"
                                id="reefer"
                                value="Reefer"
                                checked={cargoType === "Reefer"}
                                onChange={(e) => {
                                  handleCargoTypeChange(e);
                                  handleBaseFormChange(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="reefer"
                              >
                                Reefer
                              </label>
                            </div>
                          </div>
                          <div className="row gx-2">
                            <div className="col-sm-4">
                              <div className="card mt-3">
                                <div className="card-body">
                                  <span className="required" />
                                  <div className="form-floating">
                                    <input
                                      required=""
                                      type="text"
                                      className="form-control"
                                      id="commodity"
                                      placeholder="Commodity"
                                      name="commodity"
                                      value={baseFormData.commodity}
                                      onChange={handleBaseFormChange}
                                    />
                                    <label htmlFor="commodity">Commodity</label>
                                  </div>
                                  <div className="invalid-feedback">
                                    Please enter commodity
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id="tempRangeCard"
                              className={`col-sm-4 ${
                                cargoType === "Reefer" ? "" : "d-none"
                              }`}
                            >
                              <div className="card mt-3">
                                <div className="card-body">
                                  <div className="form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="temp"
                                      placeholder="Temp Range"
                                      name="temp_range"
                                      value={baseFormData.temp_range}
                                      onChange={handleBaseFormChange}
                                    />
                                    <label htmlFor="temp">Temp Range</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id="unnoCard"
                              className={`col-sm-4 ${
                                cargoType === "Hazardous" ? "" : "d-none"
                              }`}
                            >
                              <div className="card mt-3">
                                <div className="card-body">
                                  <div className="form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="unno"
                                      placeholder="UN No"
                                      name="un_no"
                                      value={baseFormData.un_no}
                                      onChange={handleBaseFormChange}
                                    />
                                    <label htmlFor="unno">UN No.</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id="imcoCard"
                              className={`col-sm-4 ${
                                cargoType === "Hazardous" ? "" : "d-none"
                              }`}
                            >
                              <div className="card mt-3">
                                <div className="card-body">
                                  <div className="form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="imco"
                                      placeholder="IMCO Range"
                                      name="imco_range"
                                      value={baseFormData.imco_range}
                                      onChange={handleBaseFormChange}
                                    />
                                    <label htmlFor="imco">#IMCO</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-8">
                          <div className="card mt-3">
                            <div className="card-body">
                              <div className="form-floating">
                                <textarea
                                  name="note"
                                  className="form-control"
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea"
                                  style={{ height: 100 }}
                                  defaultValue={""}
                                  value={baseFormData.note}
                                  onChange={handleBaseFormChange}
                                />
                                <label htmlFor="floatingTextarea">
                                  Note (Shipment Reference)
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-4" />
                        <div className="col-sm-12 text-end">
                          <button
                            className="btn btn-light btn-lg me-3"
                            type="reset"
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => setShowModal(true)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </main>
            </div>

            {/* Modal */}
            {/* Modal */}


            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              aria-labelledby="userConfirmModalLabel"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="userConfirmModalLabel">
                  Provide Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6 className="mb-3">
                  Dear User, please provide your Email ID and Mobile No. to
                  proceed ahead
                </h6>
                <form id="userForm">
                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <input
                            required=""
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder=""
                            value={userFormData.name}
                            onChange={handleUserFormChange}
                           
                          />
                          <label htmlFor="name">Enter your name</label>
                        </div>
                        <div className="invalid-feedback">
                          Please enter name
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <input
                            required=""
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder=""
                            value={userFormData.email}
                            onChange={handleUserFormChange}
                          />
                          <label htmlFor="email">Enter your email</label>
                        </div>
                        <div className="invalid-feedback">
                          Please enter email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <input
                            required=""
                            type="phone"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder=""
                            value={userFormData.phone}
                            onChange={handleUserFormChange}
                          />
                          <label htmlFor="phone">Enter your Phone Number</label>
                        </div>
                        <div className="invalid-feedback">
                          Please Enter Number
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="card">
                     
                      <span className="required"></span>
                      <div className="form-floating">

                          <ReCAPTCHA
                                sitekey="6LcnEQUhAAAAAM7mvv8D0LqPghvRf4jcdh99bOQD"
                                // onChange={handleChange}
                                style={{ marginBottom: "20px" }}
                            />
                        
                        </div>
                      
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>

         
            <div
              className="modal fade"
              id="thankYouModal"
              tabIndex={-1}
              aria-labelledby="calculateVolumeLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <form id="calcVolumeForm">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <div className="text-end">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <h5 className="mb-3 modal-title fw-bold">
                        Thank you for your enquiry. <br />
                        Your Ref no is <span id="refNo" />
                      </h5>
                      <div className="text-center"></div>
                      <p className="text-muted">
                        Someone from Teamglobal will connect with you shortly.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Fcl;
