import React from "react";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="content">
      <section className="website-policy pt-5 pb-5">
        <div className="container">
          <h4>Privacy Policy</h4>
          <div>
            <p>
              This Privacy Policy explains how Teamglobal Logistics and its
              affiliated companies the legal rights that you have in relation to our
              collection and processing of your Personal Data.
              <br />
              <br />
              This Privacy Policy may be updated regularly. Therefore, you are
              recommended to refer to this Privacy Policy for any changes and
              updates in the purposes of the processing.
            </p>
            <p>
              <strong>Protecting your Personal Data.</strong> Protecting the
              Personal Data of individuals that we interact throughout our activity,
              with conscious respect for the legal relevant requirements, is a
              commitment and a responsibility of high value and importance for us.
              When engaging with Teamglobal Logistics we want you to feel secure and
              confident that your Personal Data are in good hands.
            </p>
            <p>
              <strong>
                Data Subjects from whom we collect and process Personal Data.
              </strong>{" "}
              We collect Personal Data of our employees, potential employees (i.e.
              job candidates, job applicants), clients, suppliers, client’s and
              supplier’s representatives also referred as “business contacts”,
              shareholders and website users. If the Personal Data we collect are
              not listed in this Privacy Policy, we will give individuals, when
              required by law, appropriate notice of which other Personal Data will
              be collected and how they will be used.
            </p>
            <p>
              <strong>
                Categories of Personal Data that we collect and process.
              </strong>{" "}
              We may collect and process the following categories of Personal Data
              about you:
            </p>
            <ul>
              <li>
                Contact data and Business Contact Information: name, company
                details, postal address, email address,other contact information,
                including your business contact information and your business bank
                account and any data that would enable us to maintain our business
                relationship with you;
              </li>
              <li>
                Targeted data: age, interests, any postings, comments or other
                content that you upload or post on our websites and/or webpages,
                movements and behaviour on our webpages, websites and online
                systems;
              </li>
              <li>
                Automatically collected data: IP addresses, information about your
                browser, operating system and other Personal Data that are relevant
                and necessary for the use of the service; Biometric data:
                photographs, and images/footage captured on CCTV or other video
                systems when you access our premises, locations and/or offices,
                voice recordings for when you register to a conference;
              </li>
              <li>
                Cookies and Tracking Technologies, please refer to paragraph below.
              </li>
            </ul>
            <br />
            <p>
              If you contact us, we may keep a record of the correspondence.
              Furthermore, we may ask you to complete surveys that we use for
              research purposes, although you are not obliged to respond to them.
            </p>
            <p>
              Although we do not generally seek to collect sensitive or special
              categories of Personal Data about you, we may do so in certain cases
              where we have the legal right to do so, or where you have expressly
              consented to this.
            </p>
            <p>
              If you provide us with any Personal Data of another person, you are
              responsible for ensuring that such person is made aware of the
              information contained in this Privacy Policy and that the person has
              given you his/her consent for sharing the information with us.
            </p>
            <p>
              Except for certain information that is required by law, your decision
              to provide any Personal Data to us is voluntary. You will therefore
              not be subject to adverse consequences if you do not wish to provide
              us with your Personal Data. However, please note that if you do not
              provide certain information, we may not be able to accomplish some or
              all the purposes outlined in this Privacy Policy, and you may not be
              able to use certain tools and systems which require the use of such
              Personal Data.
            </p>
            <br />
            <p>
              <strong>The processing activity.</strong> Through our webpages and
              websites, we collect and process Personal Data about you in two ways:
            </p>
            <ul>
              <li>
                <strong>Direct processing</strong> (for example, when you provide
                your Personal Data through a log in action on our webpages and
                websites or when you are prompted to complete a form within our
                webpages and our websites or when you provide information to sign up
                for a newsletter or register to comment on a forum webpage or
                website), and;
              </li>
              <li>
                <strong>Indirect processing,</strong> from certain third parties
                (for example through website analytic technology). Such third
                parties include for example our affiliates, public authorities,
                public websites and social media, suppliers and vendors, etc.
              </li>
            </ul>
            <br />
            <p>
              <strong>Direct processing.</strong> Our websites and webpages may
              collect certain information automatically, such as the type of PC
              device or operating system you use, the amount of time you spend on
              our websites and webpages, and information about the way you use our
              websites and webpages. Our websites and webpages may also use certain
              analytics software that enables us to better understand how our
              website and webpages functionalities work so that we can continue to
              improve them. Our websites and webpages do not knowingly collect
              Personal Data about any person. You can stop continued collection of
              information by our websites easily by stop using our website. However,
              please note that we will retain any previous usage information
              collected from the website according with the retention section of
              this Privacy Policy.
            </p>
            <p>
              <strong>Targeted data and marketing communications.</strong> The
              majority of the Personal Data we collect and use for marketing
              purposes relates to individual employees of our customers and/or
              vendors and other companies with which we have an existing business
              relationship. We may also obtain contact information from public
              sources, including content made public at social media websites, to
              make an initial contact with a relevant individual at a customer,
              vendor or other company. We send commercial e-mails to individuals at
              our customers, vendors or other companies with whom we want to develop
              or maintain a business relationship in accordance with applicable
              marketing laws. Our targeted e-mail messages typically include web
              beacons, cookies, and similar technologies that allow us to know
              whether you open, read, or delete the message, and links you may
              click. When you click a link in a marketing e-mail you receive from
              Teamglobal Logistics, we may also use a cookie to log what pages you
              view and what content you download from our websites, even if you are
              not registered at or signed into our site. Targeted e-mails from us
              may include additional data privacy information, as required by
              applicable laws. You can exercise the right to discontinue marketing
              communications to you at any time by contacting us at info@ictpl.net.
              In such cases, we will retain minimum Personal Data to note that you
              opted out in order to avoid contacting you again.
            </p>
            <p>
              <strong>Indirect processing.</strong> Our websites may include links
              to and from the sites of our partner networks, advertisers and
              affiliates and certain programs (widgets and apps) of third parties.
              Where this is the case, note that such third parties may process your
              Personal Data collected through such programs for their own purposes.
              We do not accept any responsibility or liability for such third
              parties’ sites or programs. Please check such third parties’ terms of
              use and Privacy Policy before using and providing any information to
              such third parties’ sites and programs.{" "}
            </p>
            <p>
              <strong>
                Purposes for collecting and processing your Personal Data.
              </strong>
              <br />
              <br />
              We might process your Personal Data for the below listed purposes:
            </p>
            <ul>
              <li>
                Recruitment, for ensuring that we recruit the appropriate employees;
              </li>
              <li>
                To facilitate, among others, communication in regard to general
                meetings, the management of the shareholder’s register, access to
                the shareholder portal, communication with shareholders and other
                investor relation activities. For access management purposes, to
                ensure that both Teamglobal Logistics and yourself are protected
                when accessing our locations, sites, offices and /or premises;
              </li>
              <li>
                For providing you with our services and products according to agreed
                terms and as per your request, hence for the purpose of allowing the
                commercial terms to be executed between parties and to enable you to
                have a good customer experience;
              </li>
              <li>
                For carrying through the specific transactions and bookings and for
                maintaining our contractual and business relationship with our
                customers and/or vendors, including for maintaining a routine
                administrative flow and a routine contractual relationship with our
                customers and vendors and their representatives (“business
                contacts”) thereto during the delivery and execution of a commercial
                engagement;
              </li>
              <li>
                For facilitating our communication with you to ensure business
                continuity, including for providing you with necessary information,
                references and recommendations about our services and products;
              </li>
              <li>
                For monitoring your use of our systems to enable us to personalize
                your experience on our websites and webpages (including monitoring
                the use of our website and any apps and tools you use);
              </li>
              <li>
                For improving the security and functioning of our websites,
                webpages, networks and information, to ensure that you receive an
                excellent user experience whilst our networks and information are
                secure;
              </li>
              <li>
                For provisioning you with tailored newsletters and/or notifications,
                including relevant press releases to help you stay up to date with
                our products and services For operating and managing our business
                operations, for ensuring the proper functioning of our business
                operations;
              </li>
              <li>
                For conducting and manage anti-corruption, anti-fraud, anti-bribery
                checks, to assess and address risk management to avoid
                non-compliance, setbacks of our business and protecting of our
                reputation;
              </li>
              <li>
                Apply analytics to business operations and data to describe, predict
                and improve business performance within our tool and/or to provide a
                better user experience. Specifically, areas within analytics include
                descriptive analytics, predictive analytics, analytics involving
                individuals (i.e. clients) use Personal Data, analytics driven by
                marketing, single customer view and customer journey,
                talent/employee management analytics, for ensuring the proper
                functioning of our business operations;
              </li>
              <li>
                Marketing our products and services to you, unless you objected
                against such processing, so that we can ensure that we can conduct
                and increase our business.
              </li>
            </ul>
            <br />
            <p>
              <strong>
                Legal basis for collection and processing of your Personal Data?
              </strong>
              <br />
              <br />
              We process your Personal Data for the purposes set out in this Privacy
              Policy based on one or more of the following legal basis:
            </p>
            <ul>
              <li>
                Because we are required to do so for compliance with a legal
                obligation to which we are subject;
              </li>
              <li>
                Because such data is necessary for the performance of a contract to
                which you and/or the company you represent are a party to;
              </li>
              <li>
                Because the processing is necessary for the purposes of the
                legitimate interests pursued by us, by yourself or by a third party,
                or;
              </li>
              <li>
                Where necessary in order to protect the vital interests of any
                person;
              </li>
              <li>
                Based on your prior consent, to the extent such consent is mandatory
                under applicable laws.
              </li>
            </ul>
            <br />
            <p>
              <strong>
                Geographies where we collect and process your Personal Data.
              </strong>
            </p>
            <p>
              We may transfer Personal Data collected by us on an aggregated or
              individual level to various divisions, subsidiaries, joint ventures
              and affiliated companies of Teamglobal Logistics for the purposes
              stated above and in accordance with applicable laws, as well as to
              data processors outside of Teamglobal Logistics for storage and
              service purposes. Your Personal Data will not be disclosed to anyone
              outside Teamglobal Logistics group of companies unless permitted or
              required under applicable legislation.
              <br />
              <br />
              Nevertheless, where there is a need, we may share your Personal Data
              with third parties, such as, services providers, professional
              advisors, lawyer offices, tax consultants, corporate commercial
              transactions, public authorities. Before doing so, we take steps to
              protect your Personal Data. Any third-party service providers and
              professional advisors to whom your Personal Data are disclosed, are
              expected and required to protect the confidentiality and security of
              your Personal Data and may only use your Personal Data in compliance
              with applicable data privacy laws and/or contractual stipulations.
            </p>
            <p>
              <strong>Personal Data retention.</strong> We will retain the data for
              as long as you use the website and as long we have an ongoing
              relationship with you and for a reasonable time thereafter. Please
              note that some or all of the data may be required in order for the
              website to function properly, and we may be required to retain certain
              information by law. We might retain your Personal Data longer, based
              on your legitimate interest or consent (i.e. to provide you with the
              history of the containers booking).
            </p>
            <p>
              We maintain specific records management and retention policies and
              procedures, so that Personal Data are deleted after a reasonable time
              according to the following retention criteria:
            </p>
            <ul>
              <li>
                We retain your Data as long as we have an ongoing relationship with
                you (in particular, if you have an account with us).
              </li>
              <li>
                We will only keep the data while your account is active or for as
                long as needed to provide services to you.
              </li>
              <li>
                We retain your Data for as long as needed in order to comply with
                our legal and contractual obligations.
              </li>
            </ul>
            <br />
            <p>
              In such a case we will retain your Personal Data for as long as you
              request us to do so but not more than for a reasonable period of time,
              and in any case not more than 4 years. We may retain your Personal
              Data where this is advisable to safeguard or improve our legal
              position (for instance in relation to statutes of limitations,
              litigation, or regulatory investigations). We delete or anonymize your
              Personal Data when it is no longer needed for the purposes for which
              it was collected and subsequently processed.
            </p>
            <p>
              <strong>Data Subjects rights.</strong> You are entitled, in the
              circumstances and under the conditions, and subject to the exceptions,
              set out in applicable law, to:
            </p>
            <ul>
              <li>
                Request access to the Personal Data we process about you: this right
                entitles you to know whether we hold Personal Data of you and, if
                so, obtain information on and a copy of those Personal Data.
              </li>
              <li>
                Request rectification of your Personal Data: this right entitles you
                to have your Personal Data be corrected if it is inaccurate or
                incomplete.
              </li>
              <li>
                Object to the processing of your Personal Data: this right entitles
                you to request that we no longer process your Personal Data.
              </li>
              <li>
                Request the erasure of your Personal Data: this right entitles you
                to request the erasure of your Personal Data, including where such
                Personal Data would no longer be necessary to achieve the purposes.
              </li>
              <li>
                Request the restriction of the processing of your Personal Data:
                this right entitles you to request that we only process your
                Personal Data in limited circumstances, including with your consent.
              </li>
              <li>
                Request portability of your Personal Data: this right entitles you
                to receive a copy (in a structured, commonly used and
                machine-readable format) of Personal Data that you have provided to
                us, or request us to transmit such Personal Data to another data
                controller.
              </li>
            </ul>
            <br />
            <p>
              Note that most of the Personal Data that we hold about you comes from
              information you completed, therefore, please ensure that you keep your
              information correct and up to date by updating your
              profile/information on the website.
              <br />
              Please note, however, that certain Personal Data may be exempt from
              the above-mentioned rights pursuant to applicable data privacy or
              other laws and regulations.
            </p>
            <p>
              To the extent that the processing of your Personal Data is based on
              your consent, you have the right to withdraw such consent at any time
              by contacting our office. Please note that this will not affect our
              right to process Personal Data obtained prior to the withdrawal of
              your consent, or its right to continue parts of the processing based
              on other legal bases than your consent.
            </p>
            <p>
              If you wish to exercise your right to access your Personal Data, to
              object to it being processed or to rectify processed data, please
              contact info@teamglobal.in or send a letter to TEAMGLOBAL LOGISCTICS
              PVT LTD, Times Square, A-Wing, 7th Floor, Andheri – Kurla Road, near
              Saki Naka, Andheri (East), Mumbai – 400 059, Maharashtra – INDIA.
            </p>
            <p>
              <strong>Security measures.</strong> We place a great importance to
              your right to privacy and the protection of your Personal Data. We
              want you to feel secure that when you deal with us, your Personal Data
              are in good hands. Therefore, we protect your Personal Data in
              accordance with applicable data privacy laws and our data privacy
              policies and principles. Further, we have implemented appropriate
              organizational and technical security measures to protect your
              Personal Data against unauthorized or unlawful processing and/or
              against accidental loss, alteration, disclosure or access, or
              accidental or unlawful destruction of or damage thereto. We maintain
              organizational, physical and technical security arrangements for all
              the Personal Data we hold. We have protocols, controls and relevant
              policies, procedures and guidance to maintain these arrangements
              taking into account the risks associated with the categories of
              Personal Data and the processing we undertake. We store Personal Data
              on servers with limited access located in secured facilities, and our
              security measures are evaluated on an ongoing basis. The servers are
              protected by anti-virus software and firewalls, among other measures.
            </p>
            <p>
              <strong>Cookies and other tracking technologies.</strong> Our websites
              use cookies. A cookie is a small text file, which is stored on your
              computer in order to recognize your computer at recurring visits. The
              cookie may transmit information via your browser with a purpose of
              authenticating or identifying the computer (via e.g. the IP address)
              or the user. Cookies may contain information such as registration data
              and user preferences. When a server receives a request from a computer
              which stores a cookie from the website, the server is able to use the
              information stored in the cookie in combination with the information
              stored on the server. A cookie cannot collect information from your
              computer and cannot carry any viruses or other damaging files.
            </p>
            <p>
              By using our services, websites and webpages, you are consenting to
              our processing of your Personal Data and information, including the
              use of cookies and other tracking technology as well as the potential
              transfer of your Personal Data to our international divisions,
              subsidiaries, joint ventures, affiliated companies, as set forth in
              this Privacy Policy.
            </p>
          </div>
        </div>
      </section>
    </div>
    
    );
  }
}

export default PrivacyPolicy;
