import React, { useEffect, useState } from 'react'
import axios, { Axios } from 'axios';
import sea from "../../assets/images/sea-cargo-coonsolidation.jpg";

import freight from "../../assets/images/sea-freight-forwarding.jpg";

import seafreight from "../../assets/images/sea-freight-icon.png";
import air from "../../assets/images/air-freight.jpg";

import airicon from "../../assets/images/air freight-icon.png";
import cargo from "../../assets/images/project-cargo-forwarding.jpg";

import seacargo from "../../assets/images/Sea-Cargo-Consolidation.svg";

import project from "../../assets/images/project-cargo.png";

import handling from "../../assets/images/cargo-handling-infrastructure.jpg";

import infra from "../../assets/images/cargo-infra.svg";

import coastal from "../../assets/images/coastal-shipping.jpg";
import shipping from "../../assets/images/coastal-shipping-icon.png";
import BASE_URL from '../../config';
import Swal from 'sweetalert2';

import "./sailing.css";




import { faArrowRight } from '@fortawesome/free-solid-svg-icons';



function Sailing() {

  const [origin, setOrigin] = useState([]);
  const [destination, setDestination] = useState([]);
  const [tableData, setTableData] = useState([]);
  const xhr = new XMLHttpRequest();
  // useEffect(() => {
  //   getAllOrigin()
  // }, []);


  // const getAllOrigin = async () => {
  //   let scheduleType = document.getElementById('schedule_type').value;
  //   if (scheduleType == "") {
  //     return
  //   }
  //   console.log(scheduleType);
  //   //const response = await fetch(`${BASE_URL}./originlist.php?type=`+scheduleType)
  //   const response = await axios.get(BASE_URL.api, {
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   setOriginLists(response)
  // }

  const sailingOptionChange = (e) => {
    Swal.showLoading();
    let type = e.target.value;
    
    xhr.open('GET', '/api/originlist.php?type=' + type);
    xhr.onload = function () {
      Swal.close();
      if (xhr.status === 200) {
        
        setOrigin(JSON.parse(xhr.responseText));
        
      
      }
    };
    xhr.send();
  }

  const getAllDestination = (e) => {
    Swal.showLoading();
    let type = document.getElementById("schedule_type").value;
    let destination = e.target.value;
    xhr.open('GET', '/api/destinationlist.php?type=' + type + '&origin=' + destination);
    xhr.onload = function () {
      Swal.close();
      if (xhr.status === 200) {
        setTimeout(() => {
          setDestination(JSON.parse(xhr.responseText));
          document.getElementById("fetch_results").removeAttribute('disabled');
          document.getElementById("export_results").removeAttribute('disabled');
          
        }, 2000); 
      }
    };
  
    xhr.send();
  }

  const queryOptions = () => {
    Swal.showLoading();
    //let type = document.getElementById("schedule_type").value;
    let destination = document.getElementById("origin_port").value;
    let destinationPort = document.getElementById("destination_port").value;
    xhr.open('GET', '/api/search.php?origin=' + destination + '&destination=' + destinationPort);
    xhr.onload = function () {
      Swal.close();
      if (xhr.status === 200) {
        setTableData(JSON.parse(xhr.responseText));

      }
    };
    xhr.send();
  }

  const downloadExcel = () => {
    let destination = document.getElementById("origin_port").value;
    let destinationPort = document.getElementById("destination_port").value;
    let url = "/api/search-export.php?origin=" + destination + "&destination=" + destinationPort;
    window.open(url, "_blank");
  }

  const showRoute = (id) => {
    let elem = document.querySelector("#td-" + id);
    let allElem = document.querySelector(".hidden-table");
    allElem.classList.remove("showDetails");
    elem.classList.add("showDetails");
  }

  return (
    <>
      <div>
        <div className="content">
          <section className="cargo-consolidation">
            <div className="container pl-0">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                      {/* <a href=""></a> */}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Sailing Schedule
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex justify-content-between">
                    <h2 className="text-capitalize"> Sailing Schedule</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="track sailing-schedule-form">
            <div className="container pl-0 pr-0">
              <div className="contact-details" id="contact-form">
                {/*<h3>Fill Your Basic Details</h3>*/}
                <div className="row">
                  <div className="row">
                    <div className="col-md-3">
                      <select
                        id="schedule_type"
                        name="schedule_type"
                        className="form-select"
                        onChange={sailingOptionChange}
                      >
                        <option disabled selected>
                          Select Option
                        </option>
                        <option value="E" >
                          Export
                        </option>
                        <option value="I">Import</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select
                        id="origin_port"
                        name="origin_port"
                        className="form-select"
                        onChange={getAllDestination}
                      >
                        <option value="" selected="" disabled="">
                          Select Origin Port
                        </option>
                        {
                          origin && Object.keys(origin).map((x) => {
                            return (
                              <optgroup label={x}>
                                {origin[x] && origin[x].map((data) => (
                                  <option value={data.key}>{data.port}</option>
                                ))}
                              </optgroup>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select
                        id="destination_port"
                        name="destination_port"
                        className="form-select"
                      >
                        <option value="" selected="" disabled="">
                          Select Destination Port
                        </option>
                        {
                          destination && Object.keys(destination).map((x) => {
                            return (
                              <optgroup label={x}>
                                {destination[x] && destination[x].map((data) => (
                                  <option value={data.key}>{data.port}</option>
                                ))}
                              </optgroup>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-3">


                      <button
                        id="fetch_results"
                        type="QUERY"
                        defaultValue="QUERY"

                        onClick={queryOptions}
                        className="wpcf7-form-control wpcf7-submit submit-btn "
                      >
                        QUERY
                      </button>

                      <button
                        id="export_results"
                        type="DOWNLOAD"

                        onClick={downloadExcel}
                        className="wpcf7-form-control wpcf7-submit submit-btn "
                      >
                        DOWNLOAD
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container sailing-schedule-result">
            <div className="row">
              <div className="col-md-12 table-responsive" id="results_container">
                {tableData && tableData.length > 0 ? (
                  < table id="schedule_result_table" className="table table-striped mt-4">
                    <thead>
                      <tr>
                        <th scope="col">Vessel/Voyage</th>
                        <th scope="col">Cutt-off Date/Time</th>
                        <th scope="col">ETD</th>
                        <th scope="col">ETA CFS</th>
                        <th scope="col">Port of Loading</th>
                        <th scope="col">Transit Time Cut-Off Origin CFS to Port Of Discharge</th>
                        <th scope="col">Transit Time Port of Loading to Port of Discharge</th>
                        <th scope="col">Transit Time Port of Loading to CFS Destination</th>
                        <th scope="col">Transit Time Cut-Off Origin CFS to CFS Destination</th>
                        <th scope="col">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData && tableData.map((tdata, i) => {
                        return (
                          <>
                            <tr>
                              {tdata && tdata.map((data, index) => {
                                return (index != 9 ? <td>{data}</td> : <td onClick={() => showRoute(i)}>
                                  <i class="fa fa-arrow-down" data-id={index}></i>
                                </td>)
                              })}
                            </tr>
                            <tr className="hidden-table" id={"td-" + i}>
                              <td colspan="10">
                                < table className='table'>
                                  <thead>
                                    <tr>
                                      <th>Origin</th>
                                      <th>Destination</th>
                                      <th>Estimated Time Of Departure</th>
                                      <th>Estimated Time Of Arrival</th>
                                      <th>Transport Mode</th>
                                      <th>Transport Details</th>
                                      <th>Transit Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tdata[9] && tdata[9].map((innerdata) => {
                                      return (
                                        <tr>
                                          {innerdata && innerdata.map((inner) => {
                                            return (<td>{inner}</td>)
                                          })}
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </>
                        )
                      })
                      }
                    </tbody >
                  </table>
                ) : ""}
              </div>
            </div>
          </div>




          <section className="our-services">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="service-title">Our Services</h2>
                </div>
                {/* <!--<div className="col-md-8">-->
							<!--	<p className="desc">TeamGlobal is a member of leading global networks like <br />-->
							<!--		<span style="color: #0072BE;">World Wide Alliance, Air Cargo Group and GPLN</span> for the different segments of business it operates.</p>-->
							<!--</div>--> */}
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-md-4">
                  <a
                    href="sea-cargo-consolidation"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={sea} alt="Sea Cargo Coonsolidation" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn">
                            <img src={seacargo} alt="sea-cargo-icon" />
                          </span>
                          <h4>sea cargo consolidation</h4>
                          <p>
                            Our export Consolidation Program is designed to cater to
                            movement of LCL Shipments of all kinds out of India.
                          </p>
                          <a href="sea-cargo-consolidation">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="sea-freight-forwarding"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={freight} alt="Sea Freight Forwarding" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn">
                            <img src={seafreight} alt="sea-freight-icon" />
                          </span>
                          <h4>sea freight forwarding</h4>
                          <p>
                            We have an excellent FCL Forwarding Program managed by a
                            large professional team that specializes in FCL
                            Forwarding.
                          </p>
                          <a href="sea-freight-forwarding">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="air-freight"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={air} alt="Air Freight" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn">
                            <img src={airicon} alt="air freight-icon" />
                          </span>
                          <h4>air freight</h4>
                          <p>
                            Being a part of Prestigious Air Cargo Group (ACG) we
                            have access to best in the className international
                            agency network across Globe.
                          </p>
                          <a href="air-freight">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="project-cargo-forwarding"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={cargo} alt="Project Cargo Forwarding" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn" style={{ width: "38px" }}>
                            <img src={project} alt="project-carg" />
                          </span>
                          <h4>project cargo forwarding</h4>
                          <p>
                            Our Projects division is focused on the global
                            transportation of heavy lift units, oversized cargoes,
                            and project related shipments.
                          </p>
                          <a href="project-cargo-forwarding">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="container-freight-station"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={handling} alt="Cargo Handling Infrastructure" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn" style={{ width: "50px" }}>
                            <img src={infra} alt="cargo-handling-icon" />
                          </span>
                          <h4>Container Freight Station</h4>
                          <p>
                            Our unique multi-city consolidation program is designed
                            to accept LCL shipments at various inland container
                            depots in India.
                          </p>
                          <a href="container-freight-station">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="coastal-shipping"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={coastal} alt="Coastal Shipping" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn" style={{ width: "40px" }}>
                            <img src={shipping} alt="coastal-shipping-icon" />
                          </span>
                          <h4>coastal shipping</h4>
                          <p>
                            Teamglobal provides a personalized and seamless domestic
                            multimodal transportation service using Coastal
                            shipping.
                          </p>
                          <a href="coastal-shipping">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>



        </div>




      </div >
    </>
  )
}

export default Sailing
