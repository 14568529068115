import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../../config";
import { useNavigate } from "react-router-dom";
import { Container, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Register() {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    services: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "services-all") {
      const updatedServices = checked
        ? [
            "E-Booking",
            "E-SI",
            "E-BL",
            "Roadbridge",
            "E-CAN",
            "E-DO",
            "Proforma Invoice",
            "Reports",
          ]
        : [];
      setFormData({ ...formData, services: updatedServices });

      // Update the state of all individual checkboxes
      const checkboxes = document.querySelectorAll('input[name="services[]"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = checked;
      });
    } else {
      let updatedServices = [...formData.services];
      if (checked) {
        updatedServices.push(value);
      } else {
        updatedServices = updatedServices.filter(
          (service) => service !== value
        );
      }
      setFormData({ ...formData, services: updatedServices });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL.pktapi}./Register-form`, formData)
      .then((response) => {
        console.log(response.data);
        setSubmissionStatus("success");
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.data) {
          const errors = error.response.data.data;
          setValidationErrors(errors); // Set validation errors from the API response
        } else {
          console.error("Error:", error);
          setSubmissionStatus("error");
        }
      });
  };

  useEffect(() => {
    if (submissionStatus === "success") {
      const timer = setTimeout(() => {
        navigate('/thankyou');
      }, 2000);
      return () => clearTimeout(timer); 
    }
  }, [submissionStatus, navigate]);

  return (
    <>
      <div>
        <div className="content">
          <section className="register-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <a href="/ecommerce" className="back-arrow">
                    <img src="https://www.teamglobal.in/assets/images/arow-right.svg" />
                    Back To E-Commerce Screen
                  </a>
                </div>
                <div className="col-md-7 txt-pt">
                  <h2>Register for E-Commerce Services</h2>
                </div>
              </div>
              <div className="register-details">
                <div className="row ">
                  <div className="col-md-12">
                    <h6>Details of Contact Person</h6>
                    <form onSubmit={handleSubmit}>
                      <div className="form-row row">
                        <div className="form-group col-md-4">
                          <input
                            type="text"
                            className={`form-control ${
                              validationErrors && validationErrors.name
                                ? "is-invalid"
                                : ""
                            }`}
                            name="name"
                            placeholder="Name"
                            required=""
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {validationErrors && validationErrors.phone && (
                            <div className="invalid-feedback">
                              {validationErrors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <input
                            type="text"
                            className={`form-control ${
                              validationErrors && validationErrors.phone
                                ? "is-invalid"
                                : ""
                            }`}
                            name="phone"
                            placeholder="Phone"
                            required=""
                            value={formData.phone}
                            onChange={handleChange}
                          />
                          {validationErrors && validationErrors.phone && (
                            <div className="invalid-feedback">
                              {validationErrors.phone}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <input
                            type="email"
                            className={`form-control ${
                              validationErrors && validationErrors.email
                                ? "is-invalid"
                                : ""
                            }`}
                            name="email"
                            placeholder="Email"
                            required=""
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {validationErrors && validationErrors.email && (
                            <div className="invalid-feedback">
                              {validationErrors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <h6>Select Services Your Want</h6>
                      <div
                        className="check-services"
                        style={{ paddingTop: "3em" }}
                      >
                        <div className="row">
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services-all"
                                defaultValue="Select All"
                                id="selectAll"
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="selectAll"
                                style={{ fontWeight: 500 }}
                              >
                                Select All
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="E-Booking"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck1"
                              >
                                E-Booking
                              </label>
                              <p>
                                Make an export booking online using pre-defined
                                templates{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="E-SI"
                                id="exampleCheck2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck2"
                              >
                                E-SI
                              </label>
                              <p>
                                Submit online shipping instructions for your
                                export shipments{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="E-BL"
                                id="exampleCheck3"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck3"
                              >
                                E-BL
                              </label>
                              <p>Remotely print your export B/Ls </p>
                            </div>
                          </div>
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="Roadbridge"
                                id="exampleCheck4"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck4"
                              >
                                Roadbridge
                              </label>
                              <p>
                                Quotation for DAP/DDU/Ex-works shipments to/from
                                India
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="E-CAN"
                                id="exampleCheck5"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck5"
                              >
                                E-CAN
                              </label>
                              <p>Download CAN based on Import HBL </p>
                            </div>
                          </div>
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="E-DO"
                                id="exampleCheck8"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck8"
                              >
                                E-DO
                              </label>
                              <p>Pay import invoices and receive </p>
                            </div>
                          </div>
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="Proforma Invoice"
                                id="exampleCheck6"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck6"
                              >
                                Proforma Invoice
                              </label>
                              <p>Check draft invoices before final print</p>
                            </div>
                          </div>
                          <div className="col-md-3 check-mb">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="services[]"
                                defaultValue="Reports"
                                id="exampleCheck7"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck7"
                              >
                                Reports
                              </label>
                              <p>
                                Pre-defined reports &amp; customized reports
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          name="submit"
                          className="btn submit-details-btn "
                        >
                          Submit Details
                        </button>
                        {submissionStatus === "success" && (
                          <div className="App" style={{ marginTop: "1em" }}>
                          </div>
                        )}
                        {submissionStatus === "error" && (
                          <div className="App" style={{ marginTop: "1em" }}>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Register;
