import React from "react";

import teractsvg from '../../assets/images/track-trace.svg'
import Booknow from '../../assets/images/book-now.svg'
import  sailing from "../../assets/images/sailing-schedule.svg"

import roadbridge from "../../assets/images/roadbridge.svg"
import get from "../../assets/images/Get-a-quote.gif"


const Banner = () => {

    return (
        <>
            <section class="banner-sec">
                <div class="container">
                    <h1 class="main-title">Taking Business Places…</h1>
                    <span class="digital-txt d-block text-uppercase" style={{color: "transparent"}}>Now Digitally</span>
                    <p class="banner-txt">View Our Offerings
                        {/* <a href="ecommerce.php" target="_blank"> */}
                        <a href="/ecommerce" target="_blank"> 

                            View All
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-2x"><path fill="#FFAC58" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>
                        </a>
                    </p>
                    
                    <ul class="offering-list">
                        <li style={{display:"none"}}><span class="offer-icn"><img src="assets/images/tiva-icn.png" alt="icon" /></span><span class="offer-txt">TivA Bot</span></li>
                        <li>
                            <a href="https://ecommerce.teamglobal.in/login.aspx?uid=eBooking" target="_blank">
                                <span class="offer-icn"><img src={Booknow} alt="Book Now" /></span>
                                <span class="offer-txt">Book<br /> Now</span>
                            </a>
                        </li>
                        <li>
                            {/* <a href="track.php" target="_blank"> */}
                            <a href= "/track" target="_blank">
                                <span class="offer-icn"><img src={teractsvg} alt="track icon" /></span>
                                <span class="offer-txt">Track<br /> &amp; Trace</span>
                            </a>
                        </li>
                        <li>
                            {/* <a href="sailing-schedule.php" target="_blank"> */}

                            <a href="/sailing-schedule" target="_blank">
                                <span class="offer-icn">
                                    <img src={sailing} alt="icon" /></span>
                                <span class="offer-txt">Sailing<br /> Schedule
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://tiva.teamglobal.in/" target="_blank">
                                <span class="offer-icn"><img src={roadbridge} alt="icon" /></span>
                                <span class="offer-txt">Road<br /> Bridge</span>
                            </a>
                        </li>
                    </ul>
                    {/* <!--<div class="sales-exe d-flex justify-content-end">-->
						<!--	<div class="exe-prof">-->
						<!--		<img src="assets/images/sales-exe-teamglobal.png" alt="sales-exe-teamglobal" />-->
						<!--		<div class="info">-->
						<!--			<strong class="d-block">Rupesh Rao</strong>-->
						<!--			<span class="txt d-block">Sales Executive, Teamglobal</span>-->
						<!--		</div>-->
						<!--	</div>-->
						<!--</div>--> */}
                    <div class="sales-exe d-flex justify-content-end" style={{bottom: "25%"}}>
                        <div class="exe-prof" style={{width: "400px"}}>
                            {/* <!--<img src="admin/image/467147.png" alt="sales-exe-teamglobal" />--> */}
                            <a href="/Lcl" ><img src={get} alt="sales-exe-teamglobal" /></a>

                        </div>
                    </div>
                    {/* <div class="sales-exe d-flex justify-content-end">
							<div class="exe-prof">
								<img src="assets/images/tiva-at-tg.png" class="img-fluid" alt="sales-exe-teamglobal" />
								<div class="info">
									<span class="d-block tiva-whtsapp">TIVA on WhatsApp</span>
									<span class="txt d-block">write to <a href="mailto: tiva@teamglobal.in" target="_blank" style="color: #f5932e;">tiva@teamglobal.in</a> to get TIVA access</span>
								</div>
							</div>
						</div> */}
                </div>

            </section>
        </>
    )
}


export default Banner