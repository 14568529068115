import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import member1 from "../../assets/images/about/member1.png";
import member2 from "../../assets/images/about/member2.png";
import member3 from "../../assets/images/about/member3.png";
import member4 from "../../assets/images/about/member4.png";
import member6 from "../../assets/images/about/member6.png";
import member8 from "../../assets/images/about/member8.png";
import member11 from "../../assets/images/about/member11.png";
import jacob from "../../assets/images/about/jacob-thomas.jpg";
import rampal from "../../assets/images/about/rampal-tandon.jpg";
import sandeep from "../../assets/images/about/sandeep.png";
import manoj from "../../assets/images/about/manoj-goplani.png";
import gayatri from "../../assets/images/about/gayatri-shroff.jpg";
import sujit from "../../assets/images/about/sujit-baral.jpg";
import naveen from "../../assets/images/about/naveen.png";
//import teamglobal from "../../assets/images/teamGloble.jpg";
import teamglobal from "../../assets/images/ESGRoadmap.JPG";
import Twlogo from "../../assets/images/TW-Logo.svg";
import ShyamSarda from "../../assets/images/ShyamSarda.jpeg";
import Dhairya from "../../assets/images/Dhairya.jpeg"
import Salim from '../../assets/images/Salim.jpeg'
import img1 from '../../assets/images/img1.jpeg'
import img2 from '../../assets/images/img2.jpeg'
import img3 from '../../assets/images/img3.jpeg'


import Vivekele from '../../assets/images/Vivek kele.jpeg'
import NityamKhosla from '../../assets/images/Nityam khosla.jpeg'
import RajeshBhanushali from '../../assets/images/Rajesh bhanusali.jpeg'
import SandeepPalodkar from '../../assets/images/Sandeep palodkar.jpeg'
import ShayamSharda from '../../assets/images/shavam sharda.jpeg'
import SalimShikalgar from '../../assets/images/Salim shikaldar.jpeg'
import DhairyaSangoi from '../../assets/images/Dhairya Sangoi.jpeg'

const Governance = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Governance";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Governance";
  }, []);

  return (
    <div className="content">
      <section className="contact-head">
        <div className="cargo-consolidation about-txt">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <a href="/">Home</a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Sustainability
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    ESG Governance Structure and Roadmap
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex align-items-center">
                  <h2 className="text-capitalize">
                    ESG Governance Structure and Roadmap
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-tabs-sec">

        <div className="container">
          <div className="">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    href="esg-framework"
                    className="nav-link "
                    id="esg-framework-tab"
                  >
                    Framework
                    {/* Framework */}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-governance-structure-and-roadmap"
                    className="nav-link active"
                    id="esg-governance-structure-and-roadmap-tab"
                  >
                    {" "}
                    Governance Structure and Roadmap
                    {/* Esg Governance Structure and Roadmap */}
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="esg-focus-area-and-goals"
                    className="nav-link "
                    id="esg-focus-area-and-goals-tab"
                  >
                    {" "}
                    Focus Area And Goals
                    {/* Esg Focus Area And Goals */}
                  </a>
                </li>

                {/*                 
                <li className="nav-item">
                  <a
                    href="policies-and-commitments"
                    className="nav-link "
                    id="policies-and-commitments"
                  >
                    POLICIES AND COMMITMENTS
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link " href="leadership-commitment">
                    Leadership Commitment
                  </a>
                </li>
               {/* <li className="nav-item">
                  <a
                    href="esg-milestone"
                    className="nav-link"
                    id="esg-milestone-tab"
                  >
                    Milestones
                    
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    href="success-stories"
                    className="nav-link"
                    id="success-stories-tab"
                  >
                    Success Stories
                  </a>
                </li>
              </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show "
                id="tablevel1"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="about-tab-content">
                  {/* <h2 className="team-title text-capitalize text-center">
                    As Teamglobal, we strive to offer our customers a well
                    defined and a well structured logistics services.
                  </h2> */}
                  <h2 className="team-title text-capitalize text-center">
                   ESG Governance Structure and Roadmap
                  </h2>
                  <div className="row">
                    <div className="col-md-12">
                      <figure className="about-banner">
                        <img src={teamglobal} alt="Team Photo" />
                      </figure>
                    </div>
                    <div className="col-md-12">
                      <h4 className="post-title">
                        Teamglobal has been certified and affiliated with listed
                        agencies and associations
                      </h4>
                      <p className="">
                        {" "}
                        Teamglobal is an integrated Logistics services provider
                        headquartered in India. We are offering services in
                        multiple segments of logistics Viz. Sea Freight,
                        Airfreight, Project Cargo Transportation, Cargo
                        Terminals and Coastal Shipping. It has 18 offices in
                        India, Bangladesh, Kenya and Tanzania.
                      </p>
                      <h6 className="text-capitalize">accreditation</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show active"
                id="tablevel2"
                role="tabpanel"
                aria-labelledby="team-tab"
              >
                <div className="team-tab-content">
                  <div className="about-tab-content">
                    {/* <h2 className="team-title text-capitalize text-center">
                      As Teamglobal, we strive to offer our customers a well
                      defined and a well structured logistics services.
                    </h2> */}
                    <h2 className="team-title text-capitalize text-center">
                     ESG Governance Structure and Roadmap
                    </h2>
                    <div className="row">
                      <div className="col-md-12">
                        <figure className="about-banner">
                          <img src={teamglobal} alt="Team Photo" />
                        </figure>
                      </div>
                      <div className="col-md-12">
                      {/*  <h4 className="post-title">
                          Teamglobal has been certified and affiliated with
                          listed agencies and associations
                        </h4> */}
                      </div>

                      <div className="col-md-12">
                        {/* <h6 className="text-capitalize">Focus Area</h6> */}
                        {/* <h6 className="text-capitalize">ESG Roadmap </h6> */}

                        <h6> ESG Governance</h6>
                        <p className="">
                          {" "}
                          We will implement the ESG policy with following
                          mechanism:
                          <br />
                          <ul>
                            <li>
                              Strategic Guidance by the Board of Directors
                            </li>
                            <li>
                              Resource allocation and progress monitoring by ESG
                              Leadership Committee
                            </li>
                            <li>
                              Development of systems and practices for actions,
                              data management and capacity building by ESG
                              Management Committee
                            </li>
                          </ul>
                        </p>
                      </div>

                      <div className="col-md-12">
                        <h6> ESG Roadmap </h6>
                        <p className="">
                          {" "}
                          We will use a strategic ESG roadmap to
                          address priority focus areas, develop targets,
                          monitoring mechanisms, and disclose ESG performance in
                          accordance with global ESG standard reporting
                          frameworks.
                        </p>
                      </div>
                      {/* <div className="col-md-12">
                                                <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                                <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</span>
                                                <h6 className="text-capitalize">accreditation</h6>


                                            </div> */}
                    </div>
                  </div>
                  {/* <!--<h2 className="team-title text-capitalize text-center">Our Team</h2>--> */}
                  <div className="team-wrap">
                    <h4 className="team-title text-capitalize text-center">
                      {/* Committee Leaders */}
                      Our Team
                    </h4>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img src={NityamKhosla} alt="" />
                          </figure>
                          <strong className="d-block">Nityam Khosla</strong>
                          <br />
                          {/* <span className="user-pos">Director</span> */}

                          {/* <li> nityam@teamglobal.in</li>
                          <li> */}

                          {/* <span className="user-pos">Director-Teamglobal</span> */}

                          <span className="user-pos">Director - Teamglobal</span>
                          <br />

                          <span className="user-pos">nityam@teamglobal.in</span>
                          <br />

                          {/* <span className="user-pos">TeamGlobal</span> */}
                          {/* <br /> */}

                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>
                      <div className="col-md-4" >
                        <div className="business-card text-center" style={{ border: '5pxSolidBlue' }}>
                          <figure>
                            <img src={Vivekele} alt="" />
                          </figure>
                          <strong className="d-block">Vivek Kele</strong>
                          <br />

                          <span className="user-pos">Director - Teamglobal</span>
                          <br />
                          <span className="user-pos">vivek@teamglobal.in</span>
                          {/* <br /> */}

                          {/* <span className="user-pos">TeamGlobal</span> */}
                          <br />

                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>
                      

                      <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img src={RajeshBhanushali} alt="" />
                          </figure>
                          <strong className="d-block">Rajesh Bhanushali</strong>
                          <br />
                          <span className="user-pos">
                            Director - LCL – Teamglobal
                          </span>

                          <br />

                          <span className="user-pos ">
                            rajesh@teamglobal.in
                          </span>
                          <br />

                          {/* <span className="user-pos ">TeamGlobal</span> */}
                          {/*  <span className="user-pos">
                            Director - Export LCL Console
                          </span> */}
                          {/*  <span className="user-pos">
                          {" "}
                            Director - Export LCL Console
                          </span> */}
                          {/*  <span className="user-pos">
                            rajesh@teamglobal.in
                          </span> */}
                          {/*  <span className="user-pos">
                            TeamGlobal
                          </span> */}

                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img src={SandeepPalodkar} alt="" />
                          </figure>
                          <strong className="d-block">Sandeep Palodkar</strong>
                          <br />
                          {/* <span className="user-pos">
                            Director - Export LCL Console
                          </span> */}
                          <span className="user-pos">
                            VP - HR / IR / Admin - Teamglobal
                          </span>
                          <br />
                          <span className="user-pos">
                            sandeep@teamglobal.in
                          </span>
                          <br />
                          {/* <span className="user-pos">TeamGlobal</span>
                          <br /> */}

                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>


                      <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img  src={ShayamSharda} alt="" />
                          </figure>
                          <strong className="d-block">Shyam Sharda</strong>
                          <br />
                          {/* <span className="user-pos">
                            Director - Export LCL Console
                          </span> */}
                          <span className="user-pos">CFO - Teamglobal</span>
                          <br />
                          <span className="user-pos">shyam@teamglobal.in</span>
                          <br />
                          {/* <span className="user-pos">TeamGlobal</span>
                          <br /> */}

                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img   src={SalimShikalgar} alt="" />
                          </figure>
                          <strong className="d-block">Salim Shikalgar
                          </strong>
                          <br />
                          <span className="user-pos"> CEO - ICTPL

                          </span>
                          <br />
                          <span className="user-pos">
                            {" "}
                            salims@ictpl.net

                          </span>
                          <br />
                          {/* <span className="user-pos"> TeamGlobal</span>
                          <br /> */}
                          {/* <span className="user-pos">TeamGlobal</span> */}

                          {/* <span className="user-pos"> ICTPL - Mumbai
                          </span> */}
                          {/* <br /> */}
                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img  src={DhairyaSangoi} alt="" />
                          </figure>
                          <strong className="d-block">Dhairya Sangoi</strong>
                          <br />
                          <span className="user-pos"> ESG Program Manager</span>
                          <br />
                          <span className="user-pos">
                            {" "}
                            dhairyas@teamglobal.in
                          </span>
                          <br />
                          {/* <span className="user-pos"> TeamGlobal</span>
                          <br /> */}
                          {/* <span className="user-pos">TeamGlobal</span> */}

                          {/* <span className="user-pos"> TGL - Mumbai</span> */}
                          {/* <br /> */}
                          {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</span>--> */}
                        </div>
                      </div>


                      {/* <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure>
                            <img src={member6} alt="" />
                          </figure>
                          <b className="d-block">Manoj Gopalani</b>
                          <br />
                        
                          <span className="user-pos"> VP - IT / TGL </span>
                          <br />
                          <span className="user-pos">manojg@teamglobal.in</span>
                          <br />
                         
                        </div>
                      </div> */}

                      {/* <div className="col-md-4">
                        <div className="business-card text-center">
                        
                          <b className="d-block">Jacob Thomas</b>
                        
                          <span className="user-pos">Director - ICTPL</span>
                          <br />
                          <span className="user-pos">jacobt@ictpl.net</span>
                         
                        </div>
                      </div> */}

                      {/* <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure><img src={member3} alt="" /></figure>
                          <b className="d-block">V Baskaran</b>
                          <br />
                        
                          <span className="user-pos">COO - Chandra </span>
                          <br />
                          <span className="user-pos">
                            baskaranv@chandracfs.net
                          </span>
                       
                        </div>
                      </div> */}
                      {/* 
                      <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure><img src={member3} alt="" /></figure>
                          <b className="d-block">Sujit Baral</b>
                          <br />
                         
                          <span className="user-pos">GM - Regional Head / Batco </span>
                          <br />
                          <span className="user-pos">
                            sujit.baral@teamglobal.in
                          </span>
                         
                        </div>
                      </div> */}

                      {/* <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure><img src={member3} alt="" /></figure>
                          <b className="d-block">Joseph Samuel</b>
                          <br />

                          <span className="user-pos">Director - Teamworld</span>
                          <br />
                          <span className="user-pos">
                            joseph@teamglobal.in
                           
                          </span>
                        
                        </div>
                      </div> */}

                      {/* <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure><img src={member3} alt="" /></figure>
                          <b className="d-block">Sameer Baig</b>
                          <br />
                         
                          <span className="user-pos">Director - TGI Xpress</span>
                         
                        </div>
                      </div> */}

                      {/* <div className="col-md-4">
                        <div className="business-card text-center">
                          <figure><img src={member4} alt="" /></figure>
                          <b className="d-block">Hemant Bhanushali</b>
                          <br />
                          <span className="user-pos">Director - Logistics</span>

                        
                        </div>
                      </div> */}

                    </div>








                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Governance;
