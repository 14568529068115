import React, { useState, useEffect } from "react";
import Leader from "./Leader";
import Governance from "./Governance";
import Policy from "./Policy";
import Esg from "./Esg";
import EsgMilestone from "./EsgMilestone";
import Success from "./Success";
import EsgFocusArea from "./EsgFocusArea";

import TabContext from "@mui/lab/TabContext";
import { Tabs, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Sustainability.css";
// import EsgMilestone from "./EsgMilestone";

const Sustainability = () => {
  const [state, setState] = useState("1");

  const handleChange = (data, value) => {
    setState(value);
  };

  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Sustainability";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Sustainability";
  }, []);

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation about-txt">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item">About Us</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="row">
                        <div className="col-md-12">
                            <div className="d-md-flex justify-content-between align-items-center">
                                <h2 className="text-capitalize"> About Us</h2>
                                <div className="btn-wrap">
                                    <a href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking" target="_blank" className="btn btn-book btn-primary">
                                        <span>
                                            <img src={book} />
                                        </span>Book Now
                                    </a>
                                    <a href="assets/images/brochure.pdf" target="_blank" className="dwn-btn btn btn-primary">
                                        <span>
                                            <img src={download} alt="" />
                                        </span>Download Brochure
                                    </a>
                                    
                                </div>
                            </div>
                        </div>
                    </div> */}
          </div>
        </section>

        <section className="page-tabs-sec">
          <div
            className="container"
            //   style={{
            //   marginTop: "20px",
            //   paddingRight: "10px",
            //   paddingTop: "20px",
            //   paddingLeft: "10px",
            //   border: "1px solid #ffcccb",
            //   height: "600px",
            //   width: "800px",
            //   "&::-webkit-scrollbar": {
            //     width: '4px',
            //   },
            //   "&::-webkit-scrollbar-track": {
            //     backgroundColor: "#B5FF33",
            //   },
            //   "&::-webkit-scrollbar-thumb": {
            //     backgroundColor: "green",
            //     borderRadius: 2,
            //   },
            // }}
          >
            *{" "}
            <div className="container scrollbar-container">
              <TabContext value={state}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                <Tabs
                  value={state}
                  onChange={handleChange}
                  aria-label="Vehicle"
                  variant={"scrollable"}
                  scrollButtons={"auto"}
                >
                  <Tab label={<b> Framework</b>} value="1" />

                  {/* <Tab label={<b>GOVERNANCE STRUCTURE</b>} value="2" /> */}
                  <Tab
                    label={<b>  Governance Structure and Roadmap</b>}
                    value="2"
                  />
                  <Tab label={<b> Focus Area And Goals</b>} value="3" />
                  {/* <Tab label={<b>ESG FRAMEWORK</b>} value="4" /> */}
                  <Tab label={<b> Leadership Commitment</b>} value="4" />
                  <Tab label={<b> Milestones</b>} value="5" />
                  <Tab label={<b>Success Stories</b>} value="6" />
                </Tabs>

                <TabPanel value={"1"}>
                  <Esg value={"1"} />
                </TabPanel>

                <TabPanel value={"2"}>
                  <Governance value={"2"} />
                </TabPanel>
                <TabPanel value={"3"}>
                  <EsgFocusArea value={"3"} />
                </TabPanel>
                <TabPanel value={"4"}>
                  <Leader value={"4"} />
                </TabPanel>

                <TabPanel value={"5"}>
                  <EsgMilestone value={"5"} />
                </TabPanel>
                <TabPanel value={"6"}>
                  <Success value={"6"} />
                </TabPanel>

                {/* </Box> */}
              </TabContext>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Sustainability;
