import React, { useEffect, useState } from "react";
import Banner from "../Component/banner/banner";
import CaompanyGroup from "../Component/companygroup/CompanyGroup";
import Services from "../Component/services/services";
import Footer from "../Component/Footer/Footer";
import Carrer from "../Component/Carrer/Carrer";





const Home =()=>{
    useEffect(() => {

        const metaDescriptionTag = document.querySelector(
            'head > meta[name="description"]'
          );
          document.title="Welcome to Teamglobal"
         
         // console.log("metaDescriptionTag",metaDescriptionTag);
             metaDescriptionTag.content = "Welcome to Teamglobal";
          
    
    },[]);

    return(
        <>

          <div className="content">
                 <Banner />
                 <CaompanyGroup />
                 <Services />
                 {/* <Footer /> */}
                
           </div>
        </>
    )
    }


    export default Home

  