import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BASE_URL from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

function Airform() {
  const [showModal, setShowModal] = useState(false);
  const [ setSubmissionStatus] = useState(null);
  const [ setErrors] = useState({});

  const [baseFormData, setBaseFormData] = useState({
    origin: "",
    destination: "",
    cargo_ready_date: "",
    total_weight: "",
    total_volume: "",
    note: "",   
  });
  const [userFormData, setUserFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleBaseFormChange = (e) => {
    setBaseFormData({
      ...baseFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserFormChange = (e) => {
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let combinedData = {
      ...baseFormData,
      ...userFormData,
    };

    try {
      const response = await axios.post(`${BASE_URL.pktapi}./Lcl-form`, combinedData);

      if (response.status === 200) {
        setSubmissionStatus("success");
        setBaseFormData({
          origin: "",
          destination: "",
          cargo_ready_date: "",
          total_weight: "",
          total_volume: "",
          note: "",  
        });
        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        setErrors({});
      }
    } 
    catch (error) {
      console.error("Error submitting form:", error);
      //setErrors(error.response.data);
      setSubmissionStatus("error");
      setErrors(error.response.data);
      let x = '';
      const entries = Object.entries(error.response.data.data);

      entries.map(([key, value]) => {
        console.log(key+" "+value);
        x+=key+":"+value+"<br>";
      });
      Swal.fire({
        title: 'Error!',
        html:  error.response.data.message+"<br>"+x,
        icon: 'error',
        confirmButtonText: 'OK',
      });
     
    }
  };

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Get A Quote
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize"> Get A Quote</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="other-services-sec" id="form_sec">
          <div className="container bggs">
            <tg-header>
              <nav className="nav nav-tabs flex-row h5 font-weight-light border-bottom">
                <a
                  className="p-4 flex-fill text-sm-center nav-link"
                  aria-current="page"
                  href="/Lcl"
                >
                  <FontAwesomeIcon icon={faCube} aria-hidden="true" /> LCL
                </a>
                <a
                  className="p-4 flex-fill text-sm-center nav-link"
                  href="/Fcl"
                >
                  <FontAwesomeIcon icon={faCube} aria-hidden="true" /> FCL
                </a>
                <a
                  className="p-4 flex-fill text-sm-center nav-link active"
                  href="/Air"
                >
                  <FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" /> Air
                </a>
              </nav>
            </tg-header>

            <div className="container mb-4">
              <main>
                <div className="row gx-5 mt-3">
                  <div className="container-fluid form-group">
                    <form id="air-form">
                      <div className="row g-3">
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="form-floating">
                                <input
                                  required=""
                                  type="text"
                                  name="origin"
                                  className="form-control"
                                  id="origin"
                                  placeholder="Origin"
                                  value={baseFormData.origin}
                                  onChange={handleBaseFormChange}
                                />
                                <label htmlFor="origin">Origin</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter origin
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="form-floating">
                                <input
                                  required=""
                                  type="text"
                                  name="destination"
                                  className="form-control"
                                  id="destination"
                                  placeholder="Destination"
                                  value={baseFormData.destination}
                                  onChange={handleBaseFormChange}
                                />
                                <label htmlFor="destination">Destination</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter destination
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="input-group">
                                <div className="form-floating flex-1">
                                  <input
                                    required=""
                                    type="date"
                                    name="cargo_ready_date"
                                    className="form-control"
                                    id="date"
                                    placeholder="Cargo Readiness Date"
                                    min="2024-02-28"
                                    value={baseFormData.cargo_ready_date}
                                    onChange={handleBaseFormChange}
                                  />
                                  <label htmlFor="date">
                                    Cargo Readiness Date
                                  </label>
                                </div>
                                <div className="invalid-feedback">
                                  Please enter date
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card tg-measure">
                            <div className="card-body">
                              <div className="w-100 btn-group" role="group">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  onchange="getValue(this)"
                                  name="uom"
                                  id="uom"
                                  autoComplete="off"
                                  defaultValue="UOM"
                                  Value={baseFormData.uom}
                                  onChange={handleBaseFormChange}
                                  defaultChecked=""
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor="uom"
                                >
                                  Unit of Measure
                                </label>
                                {/*<input type="radio" class="btn-check" value="MET" onchange="getValue(this)" name="uom" id="met"
                  autocomplete="off">*/}
                                <label
                                  className="btn btn-outline-primary"
                                  style={{ cursor: "auto" }}
                                  htmlFor="met"
                                >
                                  MET
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="input-group">
                                <div className="form-floating flex-1">
                                  <input
                                    required=""
                                    type="number"
                                    step="0.01"
                                    name="total_weight"
                                    className="form-control"
                                    id="weight"
                                    placeholder="Total Weight"
                                    value={baseFormData.total_weight}
                                    onChange={handleBaseFormChange}
                                  />
                                  <label htmlFor="weight">Total Weight</label>
                                </div>
                                <span
                                  className="input-group-text"
                                  id="weight-addon"
                                >
                                  Kg
                                </span>
                              </div>
                              <div className="invalid-feedback">
                                Please enter total weight
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body">
                              <span className="required" />
                              <div className="input-group">
                                <div className="form-floating flex-1">
                                  <input
                                    required=""
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    id="volume"
                                    placeholder="Total Volume"
                                    aria-describedby="calcVolume"
                                    name="total_volume"
                                    value={baseFormData.total_volume}
                                    onChange={handleBaseFormChange}
                                  />
                                  <label htmlFor="volume">Total Volume</label>
                                </div>
                                {/*<button class="btn btn-outline-default" data-bs-toggle="modal" data-bs-target="#calculateVolume"
                  type="button" id="calcVolume"><span id="cv-text">CBM</span> <i
                    class="fa-solid fa-calculator"></i></button>*/}
                              </div>
                              <div className="invalid-feedback">
                                Please enter total volume
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="card mt-3">
                            <div className="card-body">
                              <div className="form-floating">
                                <textarea
                                  name="note"
                                  className="form-control"
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea"
                                  style={{ height: 100 }}
                                  defaultValue={""}
                                  value={baseFormData.note}
                                  onChange={handleBaseFormChange}
                                />
                                <label htmlFor="floatingTextarea">
                                  Note (Shipment Reference)
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-4" />
                      <div className="col-sm-12 text-end">
                        <button
                          className="btn btn-light btn-lg me-3"
                          type="reset"
                        >
                          Reset
                        </button>
                        <button
                          className="btn btn-primary btn-lg"
                          type="button"
                          onClick={() => setShowModal(true)}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </main>
            </div>

            {/* Modal */}

            <div
              className="modal fade"
              id="calculateVolume"
              tabIndex={-1}
              aria-labelledby="calculateVolumeLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <form id="calcVolumeForm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="calculateVolumeLabel">
                        Calculate Volume
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <h6 className="mb-3">
                        Enter the values below to calculate volume
                      </h6>
                      <div className="row g-3">
                        <div className="col-sm-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="form-floating has-validation">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="number"
                                  placeholder=""
                                  required=""
                                />
                                <label htmlFor="number">No. of Pieces</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter no. of pieces
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="length"
                                  placeholder=""
                                  required=""
                                />
                                <label htmlFor="length">Length</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter length
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="height"
                                  required=""
                                />
                                <label htmlFor="height">Height</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter height
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="breadth"
                                  required=""
                                />
                                <label htmlFor="breadth">Breadth</label>
                              </div>
                              <div className="invalid-feedback">
                                Please enter breadth
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="form-floating">
                                <select
                                  className="form-control"
                                  id="unit"
                                  required=""
                                >
                                  <option value="">Select</option>
                                  <option value="cm">Centimeter</option>
                                  <option value="ft">Feet</option>
                                  <option value="in">Inch</option>
                                  <option value="m">Meter</option>
                                </select>
                                <label htmlFor="unit">Unit</label>
                              </div>
                              <div className="invalid-feedback">
                                Please select unit
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="form-floating">
                                <input
                                  readOnly=""
                                  type="text"
                                  className="form-control"
                                  id="cbm"
                                />
                                <label htmlFor="cbm">CBM</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onclick="getCBM(document.getElementById('length').value,document.getElementById('height').value,document.getElementById('breadth').value,document.getElementById('number').value,document.getElementById('unit').value)"
                      >
                        Calculate
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => setShowModal(true)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              aria-labelledby="userConfirmModalLabel"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="userConfirmModalLabel">
                  Provide Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6 className="mb-3">
                  Dear User, please provide your Email ID and Mobile No. to
                  proceed ahead
                </h6>
                <form id="userForm">
                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <input
                            required=""
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder=""
                            value={userFormData.name}
                            onChange={handleUserFormChange}
                          />
                          <label htmlFor="name">Enter your name</label>
                        </div>
                        <div className="invalid-feedback">
                          Please enter name
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <input
                            required=""
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder=""
                            value={userFormData.email}
                            onChange={handleUserFormChange}
                          />
                          <label htmlFor="email">Enter your email</label>
                        </div>
                        <div className="invalid-feedback">
                          Please enter email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <input
                            required=""
                            type="phone"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder=""
                            value={userFormData.phone}
                            onChange={handleUserFormChange}
                          />
                          <label htmlFor="phone">Enter your Phone Number</label>
                        </div>
                        <div className="invalid-feedback">
                          Please Enter Number
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="required"></span>
                        <div className="form-floating">
                          <ReCAPTCHA
                            sitekey="6LcnEQUhAAAAAM7mvv8D0LqPghvRf4jcdh99bOQD"
                            // onChange={handleChange}
                            style={{ marginBottom: "20px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>

            <div
              className="modal fade"
              id="thankYouModal"
              tabIndex={-1}
              aria-labelledby="calculateVolumeLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <form id="calcVolumeForm">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <div className="text-end">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <h5 className="mb-3 modal-title fw-bold">
                        Thank you for your enquiry. <br />
                        Your Ref no is <span id="refNo" />
                      </h5>
                      <div className="text-center"></div>
                      <p className="text-muted">
                        Someone from Teamglobal will connect with you shortly.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Airform;
