import React from 'react'

const Termsofuse = () => {
  return (
    <div className="content">
  <section className="website-policy pt-5 pb-5">
    <div className="container">
      <h4>Terms Of Use</h4>
      <div>
        <p>
          Welcome to Teamglobal Logistics. The website is owned by Teamglobal
          Logistics Pvt Ltd.,, having its registered office at Times Square, A
          wing, 7th Floor, Andheri – Kurla Road, near Saki Naka, Andheri (East),
          Mumbai – 400 059, Maharashtra – INDIA.
        </p>
        <p>
          Your use of Teamglobal’s services and tools are governed by the
          following terms and conditions, (“Terms of Use”) as applicable to
          Teamglobal’s including the applicable policies which are incorporated
          herein by way of reference. By mere use of the website, You shall be
          contracting with Teamglobal Logistics, the owner of the website. These
          terms and conditions including the policies constitute your binding
          obligations, with Teamglobal Logistics Pvt Ltd.
        </p>
        <p>
          For the purpose of these Terms of Use, wherever the context so
          requires “You” or “User” shall mean any natural or legal person who
          has agreed to use the services by providing data while registering on
          the website as Registered User. The term “Teamglobal
          “,”We”,”Us”,”Our”, “TGL”, shall mean Teamglobal Logistics.
        </p>
        <p>
          When you use any of the services provided by Us through the website,
          you will be subject to the rules, guidelines, policies, terms, and
          conditions applicable to such service, and they shall be deemed to be
          incorporated into this Terms of Use and shall be considered as part
          and parcel of this Terms of Use. We reserve the right, at Our sole
          discretion, to change, modify, add or remove portions of these Terms
          of Use, at any time without any prior written notice to you.
        </p>
        <p>
          You shall ensure to review these Terms of Use periodically for
          updates/changes. Your continued use of the website following the
          posting of changes will mean that You accept and agree to the
          revisions. As long as You comply with these Terms of Use, We grant You
          a personal, non-exclusive, non-transferable, limited privilege to
          enter and use the website. By impliedly or expressly accepting these
          Terms of Use, you also accept and agree to be bound by Teamglobal’s
          policies including but not limited to Privacy Policy as amended from
          time to time.
        </p>
      </div>
      <h5>Refunds or Cancellation Policy</h5>
      <p>
        Teamglobal Logistics will not entertain any cancellation under the IRN
        mandate. Refund policies applicable in the following conditions:
      </p>
      <ul>
        <li>
          In case a customer claims a refund, the customer will need to provide
          all the documentation and justification for the same; basis which
          management will take a decision and the decision will be communicated
          to the customer.
        </li>
        <li>
          Teamglobal Logistics offers no guarantees whatsoever for the accuracy
          or timeliness of the refunds.
        </li>
      </ul>
    </div>
  </section>
</div>

  )
}

export default Termsofuse
