import React from "react";
import check from "../ThankYou/check-icon.png";

const ThankYou = () => {

    return (
        <>
            <section class="banner-sec">
                <div class="container">

                    <section className="thank-you">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <img
                                        src={check}
                                        alt="check icon"
                                        className="img-fluid check-icon"
                                    />
                                    <h1>
                                        Thank you for contacting <br />
                                        Teamglobal Logistics
                                    </h1>
                                    <p>Our team will get in touch with you on the registered e-mail.</p>
                                    <a
                                        class="underline-link"
                                        href="/"
                                        style={{ color: "#FFAC58", textDecoration: "underline"}}
                                    >
                                        Back To Home
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </section>
        </>
    )
}


export default ThankYou