import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import member1 from "../../assets/images/about/member1.png";
import member2 from "../../assets/images/about/member2.png";
import member3 from "../../assets/images/about/member3.png";
import member4 from "../../assets/images/about/member4.png";
import member6 from "../../assets/images/about/member6.png";
import member8 from "../../assets/images/about/member8.png";
import member11 from "../../assets/images/about/member11.png";
import jacob from "../../assets/images/about/jacob-thomas.jpg";
import rampal from "../../assets/images/about/rampal-tandon.jpg";
import sandeep from "../../assets/images/about/sandeep.png";
import manoj from "../../assets/images/about/manoj-goplani.png";
import gayatri from "../../assets/images/about/gayatri-shroff.jpg";
import sujit from "../../assets/images/about/sujit-baral.jpg";
import naveen from "../../assets/images/about/naveen.png";
import teamglobal from "../../assets/images/teamGloble.jpg";

import Twlogo from "../../assets/images/TW-Logo.svg";

const EsgMilestone = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Esg Milestone";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Esg Milestone";
  }, []);

  return (
    <>
      <div className="content">
        <section className="contact-head">
          <div className="cargo-consolidation about-txt">
            <div className="container">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <a href="/">Home</a>
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Sustainability
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      ESG milestone
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex align-items-center">
                    <h2 className="text-capitalize">ESG Milestones</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-tabs-sec">
          <div className="container">
            <div className="">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                  <a
                    href="esg-framework"
                    className="nav-link"
                    id="esg-framework-tab"
                  >
                    Framework
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-governance-structure-and-roadmap"
                    className="nav-link"
                    id="esg-governance-structure-and-roadmap-tab"
                  >
                     Governance Structure and Roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-focus-area-and-goals"
                    className="nav-link"
                    id="esg-focus-area-and-goals-tab"
                  >
                    {" "}
                    Focus Area and Goals
                  </a>
                </li>

                {/* <li className="nav-item">
                                    <a href="policies-and-commitments" className="nav-link " id="policies-and-commitments">POLICIES AND COMMITMENTS</a>
                                </li> */}

<li className="nav-item">
                  <a className="nav-link " href="leadership-commitment">
                  Leadership Commitment
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="esg-milestone"
                    className="nav-link active"
                    id="esg-milestone-tab"
                  >
                   Milestones
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="success-stories"
                    className="nav-link"
                    id="success-stories-tab"
                  >
                    Success Stories
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show "
                  id="tablevel1"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="about-tab-content">
                    {/* <h2 className="team-title text-capitalize text-center">
                      As Teamglobal, we strive to offer our customers a well
                      defined and a well structured logistics services.
                    </h2> */}
                      <h2 className="team-title text-capitalize text-center">
                        ESG Milestones
                      </h2>
                    <div className="row">
                      <div className="col-md-12">
                        <figure className="about-banner">
                          <img src={teamglobal} alt="Team Photo" />
                        </figure>
                      </div>
                      <div className="col-md-12">
                        <h4 className="post-title">
                          Teamglobal has been certified and affiliated with
                          listed agencies and associations
                        </h4>
                        <p className="">
                          {" "}
                          Teamglobal is an integrated Logistics services
                          provider headquartered in India. We are offering
                          services in multiple segments of logistics Viz. Sea
                          Freight, Airfreight, Project Cargo Transportation,
                          Cargo Terminals and Coastal Shipping. It has 18
                          offices in India, Bangladesh, Kenya and Tanzania.
                        </p>
                        <h6 className="text-capitalize">accreditation</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      {/* <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2> */}
                      <h2 className="team-title text-capitalize text-center">
                        ESG Milestones
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        {/* <div className="col-md-12">
                                                    <h4 className="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
                                                    <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                    <h6 className="text-capitalize">accreditation</h6>


                                                </div> */}
                      </div>
                    </div>
                    {/* <!--<h2 className="team-title text-capitalize text-center">Our Team</h2>--> */}
                    <div className="team-wrap">
                      <h4 className="team-title text-capitalize text-start">
                        Committee Leaders
                      </h4>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member1} alt="" />
                            </figure>
                            <strong className="d-block">Nityam Khosla</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member2} alt="" />
                            </figure>
                            <strong className="d-block">Vivek Kele</strong>
                            <span className="user-pos">Director</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member3} alt="" />
                            </figure>
                            <strong className="d-block">
                              Rajesh Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Export LCL Console
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member4} alt="" />
                            </figure>
                            <strong className="d-block">
                              Hemant Bhanushali
                            </strong>
                            <span className="user-pos">
                              Director - Logistics
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={jacob} alt="" />
                            </figure>
                            <strong className="d-block">Jacob Thomas</strong>
                            <span className="user-pos">
                              Director - Commercial
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member6} alt="" />
                            </figure>
                            <strong className="d-block">Tejan Lakhia</strong>
                            <span className="user-pos">
                              Sr. VP - FCL Sea Freight
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                      <h4 className="team-title text-capitalize text-start">
                        {" "}
                        Committee Member{" "}
                      </h4>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member11} alt="" />
                            </figure>
                            <strong className="d-block">Shibu Paul</strong>
                            <span className="user-pos">
                              {" "}
                              Sr. VP - WWA Business Development
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={rampal} alt="" />
                            </figure>
                            <strong className="d-block">Rampal Tandon</strong>
                            <span className="user-pos">
                              VP - Freight Management
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={member8} alt="" />
                            </figure>
                            <strong className="d-block">
                              Gaurang Timbadiya
                            </strong>
                            <span className="user-pos">VP - Finance</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>-->
                                        </div> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sandeep} alt="" />
                            </figure>
                            <strong className="d-block">
                              Sandeep Palodkar
                            </strong>
                            <span className="user-pos">
                              VP - HR/IR, Admin, Business Excellence
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={manoj} alt="" />
                            </figure>
                            <strong className="d-block">Manoj Gopalani</strong>
                            <span className="user-pos">
                              VP - Information Technology
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={gayatri} alt="" />
                            </figure>
                            <strong className="d-block">Gayatri Shroff</strong>
                            <span className="user-pos">GM - Commercial</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={sujit} alt="" />
                            </figure>
                            <strong className="d-block">Sujit Baral</strong>
                            <span className="user-pos">
                              GM - Regional Head –South
                            </span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="business-card text-center">
                            <figure>
                              <img src={naveen} alt="" />
                            </figure>
                            <strong className="d-block">Naveen Kanaujia</strong>
                            <span className="user-pos">GM – Imports</span>
                            {/* <!--<p className="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>--> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>

                          <h4> ESG Governance</h4>
                          <p className="">
                            {" "}
                            Teamglobal Logistics will implement the ESG policy
                            with following mechanism:
                            <ul>
                              <li>
                                Strategic Guidance by the Board of Directors
                              </li>
                              <li>
                                Resource allocation and progress monitoring by
                                ESG Leadership Committee
                              </li>
                              <li>
                                Development of systems and practices for
                                actions, data management, and capacity building
                                by ESG Management Committee
                              </li>
                            </ul>
                          </p>

                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                      <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                        <div classNameName="col-md-6">
                          {/* <ol classNameName=" ">
                                                    <li className="">POSH</li>
                                                    <li className="">Cyber Security</li>
                                                    <li className="">Human Resource</li>
                                                    <li className="">IT Policy</li>
                                                    <li className="">ESG Policy</li>
                                                </ol> */}

                          <ol className="list-group">
                            <li className="list-group-item">
                              POSH
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              Cyber Security
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              Human Resource
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              IT Policy
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                            <li className="list-group-item">
                              ESG Policy
                              <input
                                style={{ marginLeft: "24px" }}
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                aria-label="..."
                              />
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2>
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                      <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                        <div classNameName="col-md-6">
                          <h6 className="text-capitalize">Focus Area</h6>

                          <p className="">
                            {" "}
                            Teamglobal is an integrated Logistics services
                            provider headquartered in India. We are offering
                            services in multiple segments of logistics Viz. Sea
                            Freight, Airfreight, Project Cargo Transportation,
                            Cargo Terminals and Coastal Shipping. It has 18
                            offices in India, Bangladesh, Kenya and Tanzania.
                          </p>
                        </div>

                        <div classNameName="col-md-6">
                          <h6 className="text-capitalize">Goals</h6>

                          <p className="">
                            {" "}
                            Teamglobal is an integrated Logistics services
                            provider headquartered in India. We are offering
                            services in multiple segments of logistics Viz. Sea
                            Freight, Airfreight, Project Cargo Transportation,
                            Cargo Terminals and Coastal Shipping. It has 18
                            offices in India, Bangladesh, Kenya and Tanzania.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="tablevel2"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="team-tab-content">
                    <div className="about-tab-content">
                      {/* <h2 className="team-title text-capitalize text-center">
                        As Teamglobal, we strive to offer our customers a well
                        defined and a well structured logistics services.
                      </h2> */}
                      <h2 className="team-title text-capitalize text-center">
                       ESG Milestones
                      </h2>
                      
                      <div className="row">
                        <div className="col-md-12">
                          <figure className="about-banner">
                            <img src={teamglobal} alt="Team Photo" />
                          </figure>
                        </div>
                        <div className="col-md-12">
                          <h4 className="post-title">
                            Teamglobal has been certified and affiliated with
                            listed agencies and associations
                          </h4>
                          {/* <p className=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
                                                   <h6 className="text-capitalize">accreditation</h6> */}
                        </div>
                      </div>
                      {/* <div className="team-wrap">

                                                <div classNameName="row">
                                                    <div classNameName="col-md-4">
                                                        <b><span>-2023</span></b>

                                                    </div>
                                                    <div classNameName="col-md-4">
                                                        <b><span>-2022</span></b>

                                                    </div>
                                                    <div classNameName="col-md-4">
                                                        <b><span>-2021</span></b>

                                                    </div>
                                                    <div classNameName="col-md-4">
                                                        <b><span>-2020</span></b>

                                                    </div>
                                                    <div classNameName="col-md-4">
                                                        <b><span>-2019</span></b>

                                                    </div>
                                                    <div classNameName="col-md-4">
                                                        <b><span>-2018</span></b>

                                                    </div><div classNameName="col-md-4">
                                                        <b><span>-2017</span></b>

                                                    </div>

                                                    <div classNameName="col-md-4">
                                                        <b><span>-2016</span></b>

                                                    </div><div classNameName="col-md-4">
                                                        <b><span>-2015</span></b>

                                                    </div>


                                                </div>
                                            </div> */}
                      {/* <h6> ESG Governance</h6>
                                            <p className=" ">
                                                {" "}
                                                Teamglobal Logistics will implement the ESG policy with following mechanism:
                                                <ul>
                                                    <li>
                                                    Strategic Guidance by the Board of Directors
                                                    </li>
                                                    <li>
                                                    Resource allocation and progress monitoring by ESG Leadership Committee
                                                    </li>
                                                    <li>
                                                    Development of systems and practices for actions, data management, and capacity building by ESG Management Committee
                                                    </li>
                                                </ul>
                                            </p>

                                            <h6> ESG Roadmap </h6>
                                            <p className="">
                                                {" "}
                                                Teamglobal Logistics will use a strategic ESG roadmap to address priority focus areas, develop targets, monitoring mechanisms, and disclose ESG performance in accordance with global ESG standard reporting frameworks. 
                                            </p>
                                            <h6> Approval, Review and Revisions </h6>
                                            <p className="">
                                                {" "}
                                                This policy shall be applicable from the date of adoption and shall be read in conjunction with the other policies of Teamglobal Logistics listed below, as may be applicable. 
                                                <br/>
                                              
                                                
                                                This ESG policy shall be formally adopted by top-most management body at Teamglobal Logistics.
                                                <br/>
                                               
                                              
                                                This policy shall be reviewed on annual basis and updated as required, to ensure its relevance, effectiveness, suitability to the relevant stakeholders. 
                                                <br/>
                                              
                                                
                                                Teamglobal Logistics shall be committed to obtaining input from all relevant stakeholders for the continued development of this policy. Feedback received from stakeholders as well as any revisions in the policy shall be duly recorded.

                                            </p> */}

                      <div
                        class="tab-pane fade  show active"
                        id="tablevel5"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        <div className="about-tab-content milestones-wrapper">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="milest-title text-center">
                                Milestones  
                              </h4>
                              <div className="miles-point">
                                <h6>2023</h6>
                                {/* <p class="desc"> */}
                                <p className="">
                                    {" "}
                                    <ul>
                                        <li>
                                        Teamglobal Logistics Pvt Ltd has been
                                    awarded Eminent position under the Category
                                    “Training and Development” in SCALE Awards
                                    2023 hosted by CII (Confederation Of Indian
                                    Industries), 9th Edition.

                                        </li>
                                        <li>
                                        Teamglobal wins the award for Best LCL
                                    consolidator – Imports of the year at Exim
                                    India’s - Maritime and Logistics Awards
                                    (MALA)-12th Edition for the 12th time in a
                                    row.
                                        </li>
                                        <li>
                                        {/* <span class="d-block bullet-pt"> */}
                                    Teamglobal Logistics wins the Best LCL
                                    Consolidator of the year-Import -2023 in the
                                    India Maritime Awards -6th Edition
                                  {/* </span> */}
                                        </li>
                                        <li>
                                        {/* <span class="d-block bullet-pt"> */}
                                    Teamglobal Logistics wins The LCL
                                    Consolidator of the Year in the 7th Edition
                                    of Eastern Star Awards by East Coast
                                    Maritime.
                                  {/* </span> */}

                                        </li>
                                        <li>
                                        {/* <span class="d-block bullet-pt"> */}
                                    Teamglobal becomes an individual member of
                                    INTERNATIONAL FEDERATION OF FREIGHT
                                    FORWARDERS ASSOCIATIONS (FIATA) in February
                                    2023
                                  {/* </span> */}
                                        </li>
                                    </ul>
                                 
                                 
                                  
                                 
                                </p>
                              </div>
                           
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        class="tab-pane fade"
                        id="tablevel6"
                        role="tabpanel"
                        aria-labelledby="team-tab"
                      >
                        <div class="about-tab-content">
                          <div class="row">
                            <div class="col-md-12">
                              <h4>Corporate Social Responsibility</h4>
                              <h6>Overview</h6>
                              <p class="desc">
                                Teamglobal believes in “The power of Giving – to
                                the society”, through the CSR Initiatives with
                                an aim to behave ethically and contribute to the
                                economic, social, intellectual development of
                                the society. TG’s CSR approach comprises of
                                efforts in the areas of Education, Health care,
                                Health awareness camp, Environment, Social
                                Empowerment & Sports & Culture. Teamglobal with
                                its Philanthropist approach has been quietly
                                active in Social pursuits and has always
                                endeavored to be the “Seed of Change”. Some of
                                the initiatives worth mentioning are blood
                                donation camps, donation of clothes, books,
                                medicines to the NGO’s etc.
                              </p>
                              <p class="desc">
                                To live up to its vision, Teamglobal launched a
                                Trust “Saksham Sarthi” and the core activities
                                of the Trust will see a reflection of business
                                responsibility towards the society. The process
                                of funding the NGO’s was initiated basis one of
                                the objectives of Trust.
                              </p>
                              <p class="desc">
                                Other Core Objectives of the Trust are:
                              </p>
                              <p class="desc">
                                Educational
                                <br />
                                Medical
                                <br />
                                Relief to the poor
                                <br />
                                General Public Utility.
                              </p>
                              <p class="desc">
                                To ensure the CSR initiatives are carried out in
                                the right spirit Teamglobal did an extensive
                                search and finalised two NGO’s to get associated
                                with for the year 2016-17 namely:
                              </p>
                              <p class="desc">
                                <strong>1. Asha Sadan</strong> – engaged in
                                caring and development of abandoned and less
                                privileged girl child & women. Established in
                                the year 1962, for over 90 years, this
                                non-governmental organisation has brought
                                like-minded women together in the service of
                                others, to help tackle social and medical
                                problems faced by poor women and children in the
                                city.
                                <br />
                                <strong>2. Aasara</strong> – engaged in
                                upliftment of street children. Established in
                                the year 1996 with a mission to bring meaning in
                                the lives of street children by providing
                                various needs-based services to develop their
                                inherent talent and re-integrate them back to
                                society.
                              </p>
                              <p class="desc">
                                Core criteria for NGO selection were as follows:
                              </p>
                              <p class="desc">
                                Education for Girl Child & Women.
                                <br />
                                Child and Mother care.
                                <br />
                                Diet and Nutrition
                                <br />
                                Organizing training programmes for women on
                                tailoring Embroidery designs,
                                <br />
                                Vocational Courses.
                              </p>
                              <h4>Saksham Sarthi’s CSR Activities:</h4>
                              <h6>Namasyaa</h6>
                              <p class="desc">
                                To make a social cause, a social movement, it is
                                very important to sensitize & mobilize the
                                public. In the effort to do the same, a two-day
                                exhibition was hosted under the brand of
                                “Namasya”. Saksham Sarthi came live through the
                                branded Namasya articles like candles, florals,
                                articrafts, utility items and other decorative
                                pieces, which were exhibited for sale. The
                                objective is to channelize the profit earned for
                                social & developmental purpose.
                              </p>
                              <h6>Prameya</h6>
                              <p class="desc">
                                Saksham Sarthi initiated out Knowledge sharing
                                activities under the name of “Prameya” which
                                stands for Knowledge in Sanskrit. The first
                                activity came as a Knowledge sharing platform
                                for the Housekeeping, helpers, support staff,
                                drivers of Teamglobal & other corporates of
                                Times Square. Forty participants attended this
                                session; which was hosted in association with a
                                NGO “Shamal”. The session on Financial Awareness
                                like Government policies, saving accounts,
                                Investment schemes and other government schemes,
                                which are Accident & Life Insurance, Pension,
                                PPF, Scheme for the Girl Child, Loans etc.
                                extended time to time.
                              </p>
                              <h6>Joy of Giving Season</h6>
                              <p class="desc">
                                Teamglobal opened the Joy of Giving season on
                                07th October, 2016 by inviting members to post
                                their wish list on the wish tree. This was to
                                celebrate both giving & receiving for themselves
                                and their family. Team members put forth 156
                                wishes on the Wish tree and the company granted
                                the same and some benevolent employees also
                                sponsored some of gifts as well.
                              </p>
                              <h6>Association with NGO’s</h6>
                              <p class="desc">
                                In pursuit of the corporate social
                                responsibility, Teamglobal registered its Trust
                                “Saksham Sarthi” under The Maharashtra Public
                                Trust Act 1950 & Indian Income Tax Act 1961. To
                                work with the society at the grassroots level
                                the NGO’s working in the area of Education,
                                Street Children, Women & children Empowerment &
                                Vocational Trainings were sought after. After a
                                rigorous assessment & analysis of nearly 20
                                NGO’s, a search committee consulting the
                                Trustees of Trust & Teamglobal’s HR Team,
                                finally zeroed down upon following 02 NGO’s for
                                association.
                              </p>
                              <p class="desc">
                                <strong>1. Asha Sadan</strong> – engaged in
                                caring and development of abandoned and less
                                privileged girl child & women. Established in
                                the year 1962, for over 90 years, this
                                non-governmental organisation has brought
                                like-minded women together in the service of
                                others, to help tackle social and medical
                                problems faced by poor women and children in the
                                city.
                                <br />
                                <strong>1. Aasara</strong> – engaged in
                                upliftment of street children. Established in
                                the year 1996 with a mission to bring meaning in
                                the lives of street children by providing
                                various needs-based services to develop their
                                inherent talent and re-integrate them back to
                                society.
                              </p>
                              <p class="desc">
                                Saksham Sarthi listed some activities and task
                                to be sponsored, which were education of street
                                children at Aasara; gifted Desktops 7 also
                                sponsored for their sports & other development
                                endeavors. Asha Sadan was extended support by
                                sponsoring Desktops and providing for livelihood
                                for 175 beneficiaries for a period of 6 months.
                              </p>
                              <p class="desc">
                                To promote a culture of volunteering Team
                                members of TG will be associated for
                                weekend/fortnight activities like Skill
                                Dvelopment and trainings. In addition, an
                                internal Audit committee is made to audit the
                                Physical assets from time to time.
                              </p>
                              <h6>Direct Initiatives</h6>
                              <p class="desc">
                                Saksham Sarthi has also been involved in
                                benefiting some individuals directly. The
                                modusoperandi is to get a list of people who are
                                seeking educational assistance from families of
                                lower income groups. On receipt of application &
                                filtering basis the eligibility & financial
                                status, the sponsorship is extended for higher
                                education.
                              </p>
                              <h6>
                                Vocational / Creative Skills to Associates
                              </h6>
                              <p class="desc">
                                We aim to provide Vocational / Creative skills
                                to the beneficiaries at our associated NGO’s in
                                the areas of Computer Education, Excel training,
                                Spoken English, Creative Skills like Drawing /
                                Paintings and Rangoli making to state a few of
                                them. We have received nominations from
                                volunteers who wish to extend their support and
                                we will be having the scheduled shared by 01st
                                week of April, 2017.
                              </p>
                              <h4>Teamglobal’s CSR Activities</h4>
                              <h6>Blood Donation Camp</h6>
                              <p class="desc">
                                In association with Rotary Club Powai, a one-day
                                Blood Donation Camp was organized at Teamglobal
                                Mumbai Location. Camp was supported by Sarvodaya
                                Hospital Samarpan Blood Bank. The camp witnessed
                                enthusiastic donors – with donors from
                                Teamglobal fraternity as well as other
                                Corporates from Times Square Building and
                                collected 75 units of blood.
                              </p>
                              <p class="desc">
                                The enthusiastic donors were willing to donate
                                blood to serve to the needs of the patience and
                                the camp witnessed many others who came forward
                                to donate yet because of medical reasons were
                                not allowed. Teamglobal has been hosting such
                                activities for the last 05 years.
                              </p>
                              <h6>Clothes Donation to NGO Goonj</h6>
                              <p class="desc">
                                Teamglobal employees presented clothes to NGO
                                Goonj. It is a regular cycle that members gift
                                all necessary items & same is shared with Goonj.
                              </p>
                              <p class="desc">Green Initiatives</p>
                              <p class="desc">
                                We believe every individual has a role to play
                                in fighting the harmful consequences of E-waste.
                              </p>
                              <p class="desc">
                                To combat this Teamglobal has taken a novel
                                step, by tying up with E-Ward and Co it is
                                ensured that the disposal of the Hazardous
                                E-Waste is done in an Environmental Friendly
                                manner.
                              </p>
                              <h4>Our Future Action Plans</h4>
                              <p class="desc">
                                To further extend the philanthropic activities
                                we have chalked down our Futuristic action plan
                                as under:
                                <br />
                                1. Extending the scope of Saksham Sarthi to
                                other Branches, who will project interest to
                                volunteer for the same.
                                <br />
                                2. Getting associated with other related NGO’s /
                                Association and social institutions, which are
                                related to the objectives of Saksham Sarthi.
                                <br />
                                3. Enhancing the number of TGites to volunteer
                                for the program.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EsgMilestone;
