import React, { useState } from "react";
import sea from "../../assets/images/sea-cargo-coonsolidation.jpg";

import freight from "../../assets/images/sea-freight-forwarding.jpg";

import seafreight from "../../assets/images/sea-freight-icon.png";
import air from "../../assets/images/air-freight.jpg";

import airicon from "../../assets/images/air freight-icon.png";
import cargo from "../../assets/images/project-cargo-forwarding.jpg";

import seacargo from "../../assets/images/Sea-Cargo-Consolidation.svg";

import project from "../../assets/images/project-cargo.png";

import handling from "../../assets/images/cargo-handling-infrastructure.jpg";

import infra from "../../assets/images/cargo-infra.svg";

import coastal from "../../assets/images/coastal-shipping.jpg";
import shipping from "../../assets/images/coastal-shipping-icon.png";

function TrackingForm() {
  const [referenceOption, setReferenceOption] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!referenceNumber) {
      setErrorMessage("Please provide a correct reference number.");
      return;
    }

    let url = "";

    switch (referenceOption) {
      case "1":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/hbl_detail_display.aspx?hbl_no=${referenceNumber}`; //
        break;
      case "2":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/booking_result.aspx?query_type=booking&query_no=${referenceNumber} `; //
        break;
      case "3":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/container_display.aspx?Container_No=${referenceNumber}`; //
        break;
      case "4":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/booking_result.aspx?query_type=shipping&query_no=${referenceNumber}`; //
        break;
      case "5":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/hawb_result.aspx?hawb_no=${referenceNumber}`; //
        break;
      case "6":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/MAWB_Main.aspx?mawb_no=${referenceNumber}`; //
        break;
      case "7":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/po_search_list.aspx?order_no=${referenceNumber}`; //
        break;
      case "8":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/Shipper_Ref_List.aspx?shipper_ref=${referenceNumber}`; //
        break;
      case "9":
        url = `https://ecommerce.teamglobal.in/ecommerce/TracknTrace/Shipper_Inv_List.aspx?shipper_inv=${referenceNumber}`;
        break;
    }
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Invalid option selected");
      setErrorMessage("");
    }
  };

  return (
    <>
      <div>
        <div className="content">
          <section className="cargo-consolidation">
            <div className="container pl-0">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      {/* <a href="index.php">Home</a> */}
                      <a href="/">Home</a>

                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Track
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex justify-content-between">
                    <h2 className="text-capitalize"> Track It With Ease</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="track">
            <div className="container">
              <div className="contact-details" id="contact-form">
                <h4 style={{ color: "white" }}>Fill Your Details</h4>
                <form onSubmit={handleSubmit}>
                  <div className="form-row row">
                    <div className="form-group mb-0 col-md-4 cust-col-width">
                      <select
                        value={referenceOption}
                        onChange={(event) =>
                          setReferenceOption(event.target.value)
                        }
                        name="reference_option"
                        className="form-select"
                        id="reference-option"
                      >
                        <option selected="" value="" disabled="">
                          Reference Option
                        </option>
                        <option value={1}>HBL Number</option>
                        <option value={2}>Booking Number</option>
                        <option value={3}>Container Number</option>
                        <option value={4}>Shipping Bill Number</option>
                        <option value={5}>HAWB Number</option>
                        <option value={6}>MAWB Number</option>
                        <option value={7}>RO Number</option>
                        <option value={8}>Shipper Reference Number</option>
                        <option value={9}>Shipper Invoice Number</option>
                      </select>
                    </div>
                    <div className="form-group mb-0 col-md-4 cust-col-width">
                      <input
                        type="text"
                        value={referenceNumber}
                        onChange={(event) =>
                          setReferenceNumber(event.target.value)
                        }
                        className="form-control"
                        name="reference_number"
                        id="reference-number"
                        placeholder="Reference Number"
                      />
                      {errorMessage && (
                        <div className="text-danger">{errorMessage}</div>
                      )}
                    </div>

                    <div className="form-group mb-0 col-md-3">
                      <button
                        type="submit"
                        className="btn btn-primary submit-btn"
                      >
                        Submit
                      </button>
                      {(referenceOption === "3" || referenceOption === "4") && (
                        <button
                          className="btn btn-primary submit-btn photo-btn"
                          name="photos"
                        >
                          Photos
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <section
            className="our-services"
            style={{ backgroundColor: "#F4FAFF" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="service-title">Our Services</h2>
                </div>
                {/*<div class="col-md-8">*/}
                {/*	<p class="desc">TeamGlobal is a member of leading global networks like <br />*/}
                {/*		<a href="#">World Wide Alliance, Air Cargo Group and GPLN</a> for the different segments of business it operates.</p>*/}
                {/*</div>*/}
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-md-4">
                  <a
                    href="sea-cargo-consolidation"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={sea} alt="Sea Cargo Coonsolidation" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn">
                            <img src={seacargo} alt="sea-cargo-icon" />
                          </span>
                          <h4>sea cargo consolidation</h4>
                          <p>
                            Our export Consolidation Program is designed to
                            cater to movement of LCL Shipments of all kinds out
                            of India.
                          </p>
                          <a href="sea-cargo-consolidation">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="sea-freight-forwarding"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={freight} alt="Sea Freight Forwarding" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn">
                            <img src={seafreight} alt="sea-freight-icon" />
                          </span>
                          <h4>sea freight forwarding</h4>
                          <p>
                            We have an excellent FCL Forwarding Program managed
                            by a large professional team that specializes in FCL
                            Forwarding.
                          </p>
                          <a href="sea-freight-forwarding">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="air-freight"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={air} alt="Air Freight" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span className="service-icn">
                            <img src={airicon} alt="air freight-icon" />
                          </span>
                          <h4>air freight</h4>
                          <p>
                            Being a part of Prestigious Air Cargo Group (ACG) we
                            have access to best in the className international
                            agency network across Globe.
                          </p>
                          <a href="air-freight">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="project-cargo-forwarding"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={cargo} alt="Project Cargo Forwarding" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            className="service-icn"
                            style={{ width: "38px" }}
                          >
                            <img src={project} alt="project-carg" />
                          </span>
                          <h4>project cargo forwarding</h4>
                          <p>
                            Our Projects division is focused on the global
                            transportation of heavy lift units, oversized
                            cargoes, and project related shipments.
                          </p>
                          <a href="project-cargo-forwarding">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="container-freight-station"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={handling} alt="Cargo Handling Infrastructure" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            className="service-icn"
                            style={{ width: "50px" }}
                          >
                            <img src={infra} alt="cargo-handling-icon" />
                          </span>
                          <h4>Container Freight Station</h4>
                          <p>
                            Our unique multi-city consolidation program is
                            designed to accept LCL shipments at various inland
                            container depots in India.
                          </p>
                          <a href="container-freight-station">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href="coastal-shipping"
                    style={{ textDecoration: "none", display: " block" }}
                  >
                    <div className="service-card">
                      <img src={coastal} alt="Coastal Shipping" />
                      <div className="service-detail text-center d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            className="service-icn"
                            style={{ width: "40px" }}
                          >
                            <img src={shipping} alt="coastal-shipping-icon" />
                          </span>
                          <h4>coastal shipping</h4>
                          <p>
                            Teamglobal provides a personalized and seamless
                            domestic multimodal transportation service using
                            Coastal shipping.
                          </p>
                          <a href="coastal-shipping">Read More</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default TrackingForm;
