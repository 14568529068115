import React from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import Servicefooter from "../services/servicesfooter";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Alert } from "react-bootstrap";
import location from "../../assets/images/location-icn-yellow.svg";
import phone from "../../assets/images/phone-icn-yellow.svg";
import email from "../../assets/images/email-icn-yellow.svg";
import lofo from "../../assets/images/lofo-flag.png";
import flag1 from "../../assets/images/flag01.png";
import flag2 from "../../assets/images/flag02.png";
import flag3 from "../../assets/images/flag03.png";
import flag4 from "../../assets/images/flag04.png";
import axios from "axios";
import BASE_URL from '../../config';

const Contactus = () => {
  const [captchaValue, setCaptchaValue] = useState("");
  
  // const handleCaptchaChange = (value) => {
    //     setCaptchaValue(value);
    // };


    const [formData, setFormData] = useState({});
    const [submissionStatus,setSubmissionStatus] = useState(null);
    const [errors, setErrors] = useState({});
  
    const handleSubmit = async (event) => {
      event.preventDefault();
    
      const formData = new FormData(event.target);

      try {
        const response = await fetch(`${BASE_URL.pktapi}./form`, {
          method: "POST",
          body: JSON.stringify(Object.fromEntries(formData)), 
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        const responseData = await response.json();
    
        if (response.ok) {
          setSubmissionStatus("success");
          setFormData({
            name: "",
            phone: "",
            email: "",
            subject: "",
            message: "",
          });
        } else {
        
          setSubmissionStatus("error");
          setErrors(responseData.data);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmissionStatus("error");
      }
      
    };
    



  const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });
};

  return (
    <>
      <div className="content">
        <section className="contact-head">
          <div className="cargo-consolidation about-txt">
            <div className="container">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex align-items-center">
                    <h2 className="text-capitalize">Contact Us</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-info-wrp">
          <div className="container">
            <ul className="country-list">
              <li className="list-item">
                <a href="#" className="text-capitalize active">
                  {" "}
                  <span className="flag-icn">
                    <img src={lofo} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>Corporate Office</span>
                </a>
              </li>
              <li className="list-item">
                <a href="#" className="text-capitalize">
                  {" "}
                  <span className="flag-icn">
                    <img src={flag1} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>India</span>
                </a>

                <ul className="country-sub-menu">
                  <li>
                    <a href="ahmedabad">Ahmedabad</a>
                  </li>
                  <li>
                    <a href="bangalore">Bangalore</a>
                  </li>
                  <li>
                    <a href="baroda">Baroda</a>
                  </li>
                  <li>
                    <a href="chennai">Chennai</a>
                  </li>
                  <li>
                    <a href="coimbatore">Coimbatore</a>
                  </li>
                  {/* <li>
                    <a href="gandhidham">Gandhidham</a>
                  </li> */}
                  <li>
                    <a href="hyderabad">Hyderabad</a>
                  </li>
                  {/* <li>
                    <a href="jaipur">Jaipur</a>
                  </li> */}
                  <li>
                    <a href="kochi">Kochi</a>
                  </li>
                  <li>
                    <a href="kolkata">Kolkata</a>
                  </li>
                  <li>
                    <a href="ludhiana">Ludhiana</a>
                  </li>
                  <li>
                    <a href="mumbai">Mumbai</a>
                  </li>
                  <li>
                    <a href="newdelhi">New Delhi</a>
                  </li>
                  <li>
                    <a href="pune">Pune</a>
                  </li>
                  {/* <li>
                    <a href="/surat">Surat</a>
                  </li> */}
                  <li>
                    <a href="tirupur">Tirupur</a>
                  </li>
                  <li>
                    <a href="tuticorin">Tuticorin</a>
                  </li>
                  {/* <!-<li style="border-top: 1px solid #eaeaea;"><strong>Agencies</strong></li>-->
                                <!--<li><a href="karur">Karur</a></li>-->
                                <!--									<li><a href="moradabad">Moradabad</a></li> --> */}
                </ul>
              </li>
              <li className="list-item">
                <a href="#" className="text-capitalize">
                  {" "}
                  <span className="flag-icn">
                    <img src={flag2} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>bangladesh</span>
                </a>
                <ul className="country-sub-menu">
                  <li>
                    <a href="chittagong">Chittagong</a>
                  </li>
                  <li>
                    <a href="dhaka">Dhaka</a>
                  </li>
                </ul>
              </li>
              <li className="list-item">
                <a href="kenya" className="text-capitalize">
                  {" "}
                  <span className="flag-icn">
                    <img src={flag3} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>Kenya</span>
                </a>
              </li>
              <li className="list-item">
                <a href="tanzania" className="text-capitalize">
                  {" "}
                  <span className="flag-icn">
                    <img src={flag4} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>Tanzania</span>
                </a>
              </li>
            </ul>

            <div className="corporate-office">
              <div className="corporate-title d-md-flex justify-content-between align-items-center">
                <h4>Corporate Office - Teamglobal Logistics Pvt. Ltd.</h4>
                <a href="#contact-form">
                  <button className="btn btn-primary">Fill The Form</button>
                </a>
              </div>

              <div className="corporate-teamglobal">
                <div className="row">
                  <div className="col-md-6">
                    <div className="address w-100">
                      <figure>
                        <img src={location} />
                      </figure>
                      <p className="mb-0">
                        Times Square, A wing, 7th Floor, Andheri -Kurla Road,
                        near Saki Naka, Andheri (East), Mumbai - 400 059,
                        Maharashtra - INDIA,
                      </p>
                    </div>
                    <div className="address border-bottom pb-4 pt-md-4 d-flex align-items-center">
                      <figure className="cust-fig">
                        <img src={phone} />
                      </figure>
                      <p className="mb-0">
                        <a
                          href="tel:+91 22 67549800"
                          target="_blank"
                          style={{ color: "#385B72" }}
                        >
                          +91 22 6754 9800
                        </a>
                      </p>
                    </div>
                    <div className="address border-bottom pb-4 pt-md-4 d-flex align-items-center">
                      <figure className="cust-fig">
                        <img src={email} />
                      </figure>
                      <p className="mb-0">
                        <a
                          href="mailto:info@teamglobal.in"
                          target="_blank"
                          style={{ color: "#385B72" }}
                        >
                          info@teamglobal.in
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <!--<figure>-->
                                        <!--	<img src="assets/images/map-yellow.png">-->
                                            <!--</figure>--> */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7539.977019513135!2d72.884095!3d19.10816!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x358d915fb50737b4!2sTeam%20Global%20Logistics%20Private%20Limited!5e0!3m2!1sen!2sin!4v1618915612448!5m2!1sen!2sin"
                      width="100%"
                      height="160"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="corporate-team">
                <h4>
                  <span> Directors </span>
                </h4>
                <div className="row">
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Director</p>
                      <h2>Mr. Nityam Khosla</h2>
                      {/* <a href="mailto:nityam@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span> nityam@teamglobal.in
                      </a> */}
                      {/* <!--
                                        <a href="tel:9867444300" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9867444300
                                        </a>

                                        <a href="mailto:nityam@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> nityam@teamglobal.in
                                        </a> 
												--> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Director</p>
                      <h2>Mr. Vivek Kele</h2>
                      {/* <a href="mailto:vivek@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span> vivek@teamglobal.in
                      </a> */}
                      {/* <!--
                                        <a href="tel:919867444200" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9867444200
                                        </a>

                                        <a href="mailto:vivek@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> vivek@teamglobal.in
                                        </a>
												--> */}
                    </div>

                    
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Director</p>
                      <h2>Mr. Rajesh Bhanushali</h2>
                      {/* <a href="mailto:nityam@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span> nityam@teamglobal.in
                      </a> */}
                      {/* <!--
                                        <a href="tel:9867444300" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9867444300
                                        </a>

                                        <a href="mailto:nityam@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> nityam@teamglobal.in
                                        </a> 
												--> */}
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3"></div>
                </div>
              </div>

              <div className="corporate-team">
                <h4>
                  <span>National Head</span>
                </h4>
                <div className="row">
                  {/* <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Export LCL Console - Director </p>
                      <h2>Mr. Rajesh Bhanushali</h2>
                     
                    </div>
                  </div> */}

                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>FCL Sea Freight - Sr. VP</p>
                      <h2>Mr. Tejan Lakhia</h2>
                      {/* <a href="mailto:hemantb@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span> hemantb@teamglobal.in
                      </a> */}
                      {/* <!--
                                        <a href="tel:919867777800" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9867777800
                                        </a>

                                        <a href="mailto:hemantb@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> hemantb@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>WWA Business Development - Sr. VP </p>
                      <h2>Mr. Shibu Paul</h2>
                      {/* <!--
                                        <a href="tel:919821158114" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9821158114
                                        </a>

                                        <a href="mailto:jacobt@ictpl.net">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> jacobt@ictpl.net
                                        </a>
												--> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>LCL Export - VP</p>
                      <h2>Mr. Sujit Baral</h2>
                      {/* <!--
                                        <a href="tel:919821158114" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9821158114
                                        </a>

                                        <a href="mailto:jacobt@ictpl.net">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> jacobt@ictpl.net
                                        </a>
												--> */}
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p> LCL Import - AVP</p>
                      <h2>Mr. Naveen Chand Kanaujia</h2>
                      {/* <!--
                                        <a href="tel:919821011641" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9821011641
                                        </a>

                                        <a href="mailto:tejanl@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> tejanl@teamglobal.in
                                        </a> 
												--> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p> FCL Export - National Product Head </p>
                      <h2>Mr. Vivek Singh</h2>
                      {/* <!--
                                        <a href="tel:919560699333" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9560699333
                                        </a>

                                        <a href="mailto:shibu.del@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> shibu.del@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Freight Management - VP</p>
                      <h2>Mr. Rampal Tandon</h2>
                      <!--
                                        <a href="tel:919699529352" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 9699529352
                                        </a>

                                        <a href="mailto:rampalt@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> rampalt@teamglobal.in
                                        </a>
												-->
                    </div>
                  </div> */}

                  {/* <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p> Import LCL Console - Assistant VP </p>
                      <h2>Mr. Naveen Chand Kanaujia</h2>
                    
                    </div>
                  </div> */}
                  {/* <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p> Air Freight - GM </p>
                      <h2>Mr. Zubin Pervez Bhagalia </h2>
                     
                     
                    </div>
                  </div> */}

                  {/* <!--
                                <div className="col-md-4 col-lg-3">
                                    <div className="teamglobal-card">
                                        <p>Air Freight - GM</p>
                                        <h2>Mr. Raghavendra B</h2>

                                        <a href="tel:91 7738010026" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 7738010026
                                        </a>

                                        <a href="mailto:raghav@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> raghav@teamglobal.in
                                        </a>
                                    </div>
                                </div>
										-->
 */}
                </div>
              </div>

              <div className="corporate-team">
                <h4>
                  <span>Functional Heads</span>
                </h4>
                <div className="row">
                <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>CFO </p>
                      <h2>Mr. Shyam Sharda</h2>
                      {/* <!--
                                        <a href="tel:917738666057" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 7738666057
                                        </a>

                                        <a href="mailto:gaurangt@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> gaurangt@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p> Finance -Sr. VP </p>
                      <h2>Mr. Gaurang Timbadiya</h2>
                      {/* <!--
                                        <a href="tel:917738666057" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 7738666057
                                        </a>

                                        <a href="mailto:gaurangt@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> gaurangt@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>HR/IR,Admin,Business Excellence-VP</p>
                      <h2>Mr. Sandeep Palodkar</h2>
                      {/* <!--
                                        <a href="tel:917738000709" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 7738000709
                                        </a>

                                        <a href="mailto:samtaj@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> samtaj@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Information Technology - VP </p>
                      <h2>Mr. Manoj Gopalani</h2>
                      {/* <!--
                                        <a href="tel:917710024035" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 7710024035
                                        </a>

                                        <a href="mailto:manojg@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> manojg@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="teamglobal-card">
                      <p>Commercial - Vice President </p>
                      <h2>Ms. Gayatri Shroff</h2>
                      {/* <!--
                                        <a href="tel:917304538365" >
                                            <span>
                                                <img src="assets/images/phone-icn.svg">
                                            </span> +91 7304538365
                                        </a>

                                        <a href="mailto:gayatris@teamglobal.in">
                                            <span>
                                                <img src="assets/images/email-icn.svg">
                                            </span> gayatris@teamglobal.in
                                        </a>
												--> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-details " id="contact-form">
              <h3 className="text-center mb-3">Fill Your Basic Details</h3>

              <form id="fsub" method="post"  onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-4 mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                     {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      title="please enter number only"
                      name="phone"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                     {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <input
                      type="email"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>
                  <div className=" col-md-4 form-group mb-3">
                    <select name="subject" className={`form-control ${errors.subject ? 'is-invalid' : ''}`} value={formData.subject} onChange={handleChange} required>
                      <option selected>Subject</option>
                      <option value="Sea Freight Export">
                        Sea Freight Export
                      </option>
                      <option value="Sea Freight Import">
                        Sea Freight Import
                      </option>
                      <option value="Air Freight">Air Freight</option>
                      <option value="Project Cargo">Project Cargo</option>
                      <option value="Container Freight Station">
                        Container Freight Station
                      </option>
                      <option value="Coastal Shipping">Coastal Shipping</option>
                    </select>
                    {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                  </div>
                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      name="message"
                      required
                      placeholder="Your message here…"
                    />
                    {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                  </div>
                  {/* <ReCAPTCHA className="form-group col-md-4 mt-3 mb-3"
                                sitekey="AIzaSyBjmS-BK-_z1ylrEfjtQZSnJk_zMUETHiw"
                                onChange={handleCaptchaChange}
                                style={{ marginBottom: "10px" }}
                            /> */}
                  <div className="form-group col-md-4">
                    <button
                      className="btn btn-primary"
                      name="submit"
                      type="submit"
                    >
                      {" "}
                      Submit Details{" "}
                    </button>
                  </div>
                </div>
                {submissionStatus === "success" && (
                  <div className="App">
                    <Container className="p-0" style={{ width: "35%" }}>
                      <Alert variant="success">
                        Form submitted successfully!
                      </Alert>
                    </Container>
                  </div>
                )}
                {submissionStatus === "error" && (
                  <div className="App">
                    <Container className="p-0" style={{ width: "35%" }}>
                      <Alert variant="danger">Form Not submitted!</Alert>
                    </Container>
                  </div>
                )}
              </form>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default Contactus;
