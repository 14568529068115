import { Link } from "react-router-dom";
import globalicon from "../../assets/images/global-icon.svg";

const Search = () => {
  return (
    // <section classNameName="global-services-sec">
    // 					<div className="container">

    // <hgroup classNameName="mb20">
    // 		<h1>Search Results</h1>
    // 		<h2 classNameName="lead"><strong classNameName="text-danger">5</strong> results were found for the search for <strong classNameName="text-danger"></strong></h2>
    // 	</hgroup>

    //     <section classNameName="col-xs-12 col-sm-6 col-md-12">

    //     <article classNameName="search-result row">

    // 			<div classNameName="col-xs-12 col-sm-12 col-md-12 excerpet">
    // 				<h3><a href="in-the-videos.php?id=20" title="">Awards 2018 - ET Now 6th Samudra Manthan</a></h3>

    //                </div>
    // 			<span classNameName="clearfix borda"></span>
    // 		</article>
    // 	    <article classNameName="search-result row">

    // 			<div classNameName="col-xs-12 col-sm-12 col-md-12 excerpet">
    // 				<h3><a href="in-the-videos.php?id=14" title="">MALA Awards 2013</a></h3>

    //                </div>
    // 			<span classNameName="clearfix borda"></span>
    // 		</article>
    // 	    <article classNameName="search-result row">

    // 			<div classNameName="col-xs-12 col-sm-12 col-md-12 excerpet">
    // 				<h3><a href="in-the-videos.php?id=15" title="">MALA Awards 2014</a></h3>

    //                </div>
    // 			<span className="clearfix borda"></span>
    // 		</article>
    // 	    <article classNameName="search-result row">

    // 			<div classNameName="col-xs-12 col-sm-12 col-md-12 excerpet">
    // 				<h3><a href="in-the-videos.php?id=17" title="">MALA Awards 2015</a></h3>

    //                </div>
    // 			<span classNameName="clearfix borda"></span>
    // 		</article>
    // 	    <article classNameName="search-result row">

    // 			<div classNameName="col-xs-12 col-sm-12 col-md-12 excerpet">
    // 				<h3><a href="in-the-videos.php?id=18" title="">MALA AWARDS 2016</a></h3>

    //                </div>
    // 			<span classNameName="clearfix borda"></span>
    // 		</article>

    // 	</section>
    // </div>

    // 				</section>
    //           )
    // }
    // export default Search

    <div className="content">
      <section className="cargo-consolidation">
        <div className="container">
          <div className="breadcrumb-wrap">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="..//">
                    <a href="#">Home</a>{" "}
                  </Link>
                </li>

                <li className="breadcrumb-item">
                  <Link to="../videos">
                    <a href="videospage=1">Videos</a>{" "}
                  </Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Search
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-md-flex justify-content-between">
                <h2 className="text-capitalize">
                  <span className="global-icn">
                    {/* <img src="assets/images/global-icon.svg" /> */}
                    <img src={globalicon} alt=""/>
                  </span>
                  Search Result:{" "}
                </h2>
                <div className="btn-wrap">
                  <a href="#" className="btn btn-book btn-primary">
                    <span>
                      <img src="assets/images/book-icon.svg" alt=""/>
                    </span>
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="global-services-sec">
        <div className="container">
          <hgroup className="mb20">
            <h1>Search Results</h1>
            <h2 className="lead">
              <strong className="text-danger">5</strong> results were found for
              the search for <strong className="text-danger"></strong>
            </h2>
          </hgroup>

          <section className="col-xs-12 col-sm-6 col-md-12">
            <article className="search-result row">
              <Link to="../award2018">
                <div className="col-xs-12 col-sm-12 col-md-12 excerpet">
                  <h3>
                    <a href="in-the-videos.php?id=20" title="">
                      Awards 2018 - ET Now 6th Samudra Manthan
                    </a>
                  </h3>
                </div>
              </Link>
              <span className="clearfix borda"></span>
            </article>
            <article className="search-result row">
              <Link to="../malaaward2013">
                <div className="col-xs-12 col-sm-12 col-md-12 excerpet">
                  <h3>
                    <a href="in-the-videos.php?id=14" title="">
                      MALA Awards 2013
                    </a>
                  </h3>
                </div>

                <span className="clearfix borda"></span>
              </Link>
            </article>
            <article className="search-result row">
              <Link to="../malaaward2014">
                <div className="col-xs-12 col-sm-12 col-md-12 excerpet">
                  <h3>
                    <a href="in-the-videos.php?id=15" title="">
                      MALA Awards 2014
                    </a>
                  </h3>
                </div>
                <span className="clearfix borda"></span>
              </Link>
            </article>
            <article className="search-result row">
              <Link to="../malaaward2015">
                <div className="col-xs-12 col-sm-12 col-md-12 excerpet">
                  <h3>
                    <a href="in-the-videos.php?id=17" title="">
                      MALA Awards 2015
                    </a>
                  </h3>
                </div>
                <span className="clearfix borda"></span>
              </Link>
            </article>
            <article className="search-result row">
              <Link to="../malaaward2016">
                <div className="col-xs-12 col-sm-12 col-md-12 excerpet">
                  <h3>
                    <a href="in-the-videos.php?id=18" title="">
                      MALA AWARDS 2016
                    </a>
                  </h3>
                </div>
                <span className="clearfix borda"></span>
              </Link>
            </article>
          </section>
        </div>
      </section>
    </div>
  );
};
export default Search;
