import React from "react";
import { useState } from "react";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/images/logo.png";
import pffimg from "../../assets/images/pdf-logo.svg";
import usericn from "../../assets/images/user-icn.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
function Header() {
  // const shodrop = () => {
  //   const element = document.querySelector("#showdrop1");
  //   element.style.display = "block";
  // };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
  };
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleMouseEnter = (dropdownId) => {
    setOpenDropdown(dropdownId);
    setIsDropdownOpen(true);
  };

  const handleToggle = (dropdownId) => {
    openDropdown ? setOpenDropdown(null) : setOpenDropdown(dropdownId);
    openDropdown ? setIsDropdownOpen(false) : setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
    setIsDropdownOpen(false);
  };

  const [toggle, setToggle] = useState(false);

  // const toggleLoginDropdown = () => {
  //   setToggle(!toggle);
  // };

  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen((prevIsOpen) => !prevIsOpen);
    // setIsDropdownOpen(!isServicesDropdownOpen);
  };

  return (
    <div className="wrapper">
      <header className="header">
        <nav className="navbar navbar-expand-xl navbar-light">
          {/* <a className="nav-brand d-flex align-items-center" href="/"> */}
          {/* <a className="nav-brand d-flex align-items-center" href="https://teamglobal.in/e-do.php">  */}

          {/* <a
            className="nav-brand d-flex align-items-center"
            href="http://localhost:3000"
          > */}
          <Link to="../" className="nav-brand d-flex align-items-center">
            <img
              style={{ maxWidth: "76%", marginLeft: "15px" }}
              src={logo}
              alt="logo"
              className="logo"
            />
          </Link>
          <button
            className={`navbar-toggler ${isDropdownOpen ? "" : "collapsed"}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isDropdownOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleDropdown}
          >
            <span
              className="navbar-toggler-icon"
              // style={{ marginLeft: "150px", display: "block" }}
              style={{
                marginTop: "3px",
                marginRight: "27px",
                display: "block",
              }}
            ></span>
            <span
              className="navbar-toggler-icon"
              // style={{ marginLeft: "150px", display: "block" }}
              // style={{ marginTop: "34px",paddingRight:"52px", display: "block" }}
              style={{ marginRight: "27px", display: "block" }}
            ></span>
            <span
              className="navbar-toggler-icon"
              // style={{ marginLeft: "150px", display: "block" }}
              style={{ marginRight: "27px", display: "block" }}
            // style={{ marginTop: "34px",paddingRight:"52px", display: "block" }}
            ></span>
          </button>
          {/* <div className="navbar-collapse collapse" id="navbarSupportedContent"> */}
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
          <div
            className={`collapse navbar-collapse justify-content-center ${isDropdownOpen ? "show" : ""
              }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              <NavDropdown
                title={
                  <span>
                    Services 
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                id="collapsible-nav-dropdown"
                className="nav-item"
                show={openDropdown === "services"}
                onMouseEnter={() => handleMouseEnter("services")}
                onMouseLeave={handleMouseLeave}
                onClick={toggleServicesDropdown}
                icon="none"
              >

                <NavDropdown.Item
                  className="text-capitalizze"
                  href="sea-cargo-consolidation"
                >
                  Sea Cargo Consolidation
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-capitalizze"
                  href="sea-freight-forwarding"
                >
                  Sea Freight Forwarding
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-capitalizze"
                  href="air-freight"
                >
                  Air Freight
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-capitalizze"
                  href="project-cargo-forwarding"
                >
                  Project Cargo Forwarding
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-capitalizze"
                  href="container-freight-station"
                >
                  Container Freight Station
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-capitalizze"
                  href="coastal-shipping"
                >
                  Coastal Shipping
                </NavDropdown.Item>

                {/* <NavDropdown.Item href="transporation">
                  Transportation
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="warehousing">
                  Warehousing
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="customs-clearance">
                  Customs Clearance
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="po-management">
                  Po Management
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="pick-pack">
                  Pick & Pack
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="quality-control-inspection">
                Quality Control & Inspection
               </NavDropdown.Item> */}
              </NavDropdown>

              <NavDropdown
                 title={
                  <span>
                    Tools
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                id="collapsible-nav-dropdown"
                show={openDropdown === "tools"}
                onMouseEnter={() => handleMouseEnter("tools")}
                onMouseLeave={handleMouseLeave}
              >
                <NavDropdown.Item href="global-locations">
                  Global Locations
                </NavDropdown.Item>
                <NavDropdown.Item href="warehouse-locations">
                  Warehouse Locations
                </NavDropdown.Item>
                <NavDropdown.Item href="inco-terms">
                  INCO Terms
                </NavDropdown.Item>
                <NavDropdown.Item href="shipping-glossary">
                  Shipping Glossary
                </NavDropdown.Item>
                <NavDropdown.Item href="country-restrictions">
                  Country Restrictions
                </NavDropdown.Item>
                <NavDropdown.Item href="freight-calculator">
                  Freight Calculator
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <span>
                    Ecommerce
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                id="collapsible-nav-dropdown"
                show={openDropdown === "ecommerce"}
                onMouseEnter={() => handleMouseEnter("ecommerce")}
                onMouseLeave={handleMouseLeave}
              >
                <NavDropdown.Item
                  href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                  target="_blank"
                  rel="noreferrer"
                >
                  e-booking
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-SI
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://ecommerce.teamglobal.in/login.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-BL
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                  target="_blank"
                  rel="noreferrer"
                >
                  proforma invoice
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-CAN
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  href="https://teamglobal.in/e-do.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-DO
                </NavDropdown.Item> */}
                <NavDropdown.Item href="e-do" target="_blank" rel="noreferrer">
                  E-DO
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                  target="_blank"
                  rel="noreferrer"
                >
                  reports
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://tiva.teamglobal.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  roadbridge
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                 title={
                  <span>
                   About Us
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                id="collapsible-nav-dropdown"
                show={openDropdown === "about us"}
                onMouseEnter={() => handleMouseEnter("about us")}
                onMouseLeave={handleMouseLeave}
              >
                <NavDropdown.Item href="profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="team">Team</NavDropdown.Item>
                <NavDropdown.Item href="mission">Mission</NavDropdown.Item>
                <NavDropdown.Item href="work-philosophy">
                  Work Philosophy
                </NavDropdown.Item>
                <NavDropdown.Item href="milestones">
                  Milestones
                </NavDropdown.Item>
                <NavDropdown.Item href="csr">CSR Activities</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <span>
                    Substainability
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                id="collapsible-nav-dropdown"
                show={openDropdown === "sustainability"}
                onMouseEnter={() => handleMouseEnter("sustainability")}
                onMouseLeave={handleMouseLeave}
              >
                <NavDropdown.Item href="esg-framework">
                  ESG Framework
                </NavDropdown.Item>

                <NavDropdown.Item href="esg-governance-structure-and-roadmap">
                  ESG Governance Structure and Roadmap
                </NavDropdown.Item>
                <NavDropdown.Item href="esg-focus-area-and-goals">
                  ESG Focus Area and Goals
                </NavDropdown.Item>

                {/* <NavDropdown.Item href="policies-and-commitments">
                  Policies And Commitments
                </NavDropdown.Item> */}
                <NavDropdown.Item href="leadership-commitment">
                  ESG Leadership Commitment
                </NavDropdown.Item>

                {/* <NavDropdown.Item href="esg-milestone">ESG Milestones</NavDropdown.Item> */}
                <NavDropdown.Item href=" success-stories">
                  ESG Success Stories
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                 title={
                  <span>
                    Media
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                id="collapsible-nav-dropdown"
                show={openDropdown === "media"}
                onMouseEnter={() => handleMouseEnter("media")}
                onMouseLeave={handleMouseLeave}
              >
                <NavDropdown.Item href="videos">Videos</NavDropdown.Item>
              </NavDropdown>

              {/* <NavDropdown title=" Contact Us" id="collapsible-nav-dropdown"> */}
              {/* <NavDropdown.Item href="contact" style={{ paddingLeft: "20px" }}>
                Contact Us
              </NavDropdown.Item> */}
              <Nav.Link href="contact" style={{ paddingLeft: "20px" }}>
                {" "}
                Contact Us
              </Nav.Link>

              {/* </NavDropdown> */}
              {/* <NavDropdown title=" STC" id="collapsible-nav-dropdown"> */}

              {/* <NavDropdown.Item
                href="https://teamglobal.in/assets/images/TGL-Standard-Terms-and%20Conditions.pdf"
                // href="assets/images/TGL-Standard-Terms-and Conditions.pdf"
                // href="assets\images\TGL-Standard-Terms-and Conditions.pdf"
                // C:\Users\Lenovo\Desktop\TeamsGlobal\src\assets\images\TGL-Standard-Terms-and Conditions.pdf
                target="_blank"
                style={{ paddingLeft: "20px" ,marginTop:'2px'}}
              >
                STC
                <img
                  src={pffimg}
                  style={{
                    width: "25px",
                    display: "inline-block",
                    paddingLeft: "5px",
                  }}
                />
              </NavDropdown.Item> */}

              <Nav.Link
                href="https://teamglobal.in/assets/images/TGL-Standard-Terms-and%20Conditions.pdf"
                target="_blank"
                rel="noreferrer"
                style={{ paddingLeft: "20px" }}
              >
                STC
                <img
                  src={pffimg}
                  alt=""
                  style={{
                    width: "25px",
                    display: "inline-block",
                    paddingLeft: "5px",
                  }}
                />
              </Nav.Link>

              {/* </NavDropdown> */}
            </ul>
          </div>
          <div className="right-nav">
            {/* <a
              href="#"
              className="signin-link"
              onClick={toggleLoginDropdown}
              style={{
                marginTop: "9px",
                paddingRight: "30px",
                textDecoration: "none",
              }}
            >
              <img src={usericn} alt="search" style={{ fontSize: "15px" }} />{" "}
              Login
            </a> */}

            <NavDropdown className="loginDropdown"
              title={
                <span>
                  <img
                    src={usericn}
                    alt="search"
                    style={{ fontSize: "15px" }}
                  />
                  Login
                </span>
              }
              // id="collapsible-nav-dropdown"
              // className="nav-item signin-link"
              show={openDropdown === "login"}
              // onMouseEnter={() => handleMouseEnter("login")}
              // onMouseLeave={handleMouseLeave}
              onClick={() => handleToggle("login")}
            >
              <NavDropdown.Item href="/ecommerce" target="_blank">
                Customer Login  <FontAwesomeIcon className="ms-3" icon={faChevronRight} />
              </NavDropdown.Item>
              <NavDropdown.Item href="/tg-login" target="_blank">
                TG Login <FontAwesomeIcon className="ms-3" icon={faChevronRight} />
              </NavDropdown.Item>
            </NavDropdown>

            {/* {toggle && } */}

            {/* <a href="#" className="signin-link" onClick={toggleDropdown}>
              <img src={usericn} alt="search" style={{ fontSize: '15px', marginLeft: '15px' }} /> Login
            </a> */}

            {/* <form className="search-form" action="search-all" method="GET">
              <div className="form-group">
                <input type="text" className="form-control" name="searchinput" id="inputCity" placeholder="Search Input" />
                <button type="submit" className="search-icn"><img src="assets/images/search-icn.svg" alt="search" /></button>
              </div>
            </form> */}
            {/* <ul className="login-dropdown drpdwn-1" style={{ display: 'block' }}>
             

              <li>
                <a href="ecommerce" target="_blank">
                  Customer Login
                  <i className="arrow-icn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.128" height="16.739" viewBox="0 0 11.128 16.739">
                      <defs><style></style></defs>
                      <path className="a" d="M14.028,0,8.417,5.754,2.663.143,0,2.853l8.465,8.274,8.274-8.465Z" transform="translate(0 16.739) rotate(-90)"></path>
                    </svg>
                  </i>
                </a>
              </li>
              <li>
                <a href="tg-login.php" className="tglog-open">TG Login
                  <i className="arrow-icn"><svg xmlns="http://www.w3.org/2000/svg" width="11.128" height="16.739" viewBox="0 0 11.128 16.739"><defs><style></style></defs><path className="a" d="M14.028,0,8.417,5.754,2.663.143,0,2.853l8.465,8.274,8.274-8.465Z" transform="translate(0 16.739) rotate(-90)"></path></svg></i>
                </a>
              </li>
            </ul> */}

            <form class="search-form" action="search-all.php" method="GET">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="searchinput"
                  id="inputCity"
                  placeholder="Search Input"
                />
                <button type="submit" class="search-icn">
                  <img src="assets/images/search-icn.svg" alt="search" />
                </button>
              </div>
            </form>

            {toggle && (
              <ul class="login-dropdown drpdwn-1" style={{ display: "block" }}>
                <li>
                  <a
                    href="https://teamglobal.in/ecommerce.php"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Customer Login
                    <i class="arrow-icn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.128"
                        height="16.739"
                        viewBox="0 0 11.128 16.739"
                      >
                        <defs></defs>
                        <path
                          class="a"
                          d="M14.028,0,8.417,5.754,2.663.143,0,2.853l8.465,8.274,8.274-8.465Z"
                          transform="translate(0 16.739) rotate(-90)"
                        ></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://teamglobal.in/tg-login.php"
                    class="tglog-open"
                    style={{ textDecoration: "none" }}
                  >
                    TG Login
                    <i class="arrow-icn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.128"
                        height="16.739"
                        viewBox="0 0 11.128 16.739"
                      >
                        <defs> </defs>
                        <path
                          class="a"
                          d="M14.028,0,8.417,5.754,2.663.143,0,2.853l8.465,8.274,8.274-8.465Z"
                          transform="translate(0 16.739) rotate(-90)"
                        ></path>
                      </svg>
                    </i>
                  </a>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
