import React from "react";
import book from "../../assets/images/book-icon.svg"
import bookingcart from "../../assets/images/booking-cart.svg"
import esiiconsvg from "../../assets/images/e-si-icon.svg"
import eblnewsvg from "../../assets/images/e-bl-new.svg"
import proformainvoicenewsvg from "../../assets/images/proforma-invoice-new.svg"
import ecancansvg from "../../assets/images/e-can-can.svg"
import idoiconsvg from "../../assets/images/i-do-icon.svg"
import reportssvg from "../../assets/images/reports.svg"
import roadbridgenewsvg from "../../assets/images/roadbridge-new.svg"
import tracktraceiconsvg from "../../assets/images/track-trace-icon.svg"

const Edo = () => {

    return (<>

        <div className="content">

        <section class="cargo-consolidation">
					<div class="container">
						<div class="breadcrumb-wrap">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>

									<li class="breadcrumb-item">Services</li>
									<li class="breadcrumb-item active" aria-current="page">Ecommerce</li>
								</ol>
							</nav>

						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="d-md-flex justify-content-between align-items-center">
									<h2 class="text-capitalize">Ecommerce</h2>
									<div class="btn-wrap">
										<a href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking" target="_blank" rel="noreferrer" class="btn btn-book btn-primary">
											<span>
												<img src={book} alt="book"/>
											</span>Book Now
										</a>
										
									
									</div>
								</div>
							</div>
						</div>

					</div>
				</section>

            
    <section className="ecommerce-offerings">
  <div className="container">
    {/*<h4 class="digital-title">Our Digital Offerings</h4>*/}
    <div className="row">
      <div className="col-md-4">
        <div className="booking-card">
          <h4>E-Booking</h4>
          <p>
            Book your shipments and create templates to re-use for bookings
            online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={bookingcart} alt="E-booking"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4 style={{ textTransform: "uppercase" }}>E-SI</h4>
          <p>
            Submit shipping instructions against confirmed bookings by just
            amending information online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={esiiconsvg} alt="E-SI"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4 style={{ textTransform: "uppercase" }}>E-bl</h4>
          <p>Remotely print your bills of lading online</p>
          <div className="booking-icon">
            <figure>
              <img src={eblnewsvg}  alt="E-bl"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://ecommerce.teamglobal.in/login.aspx"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4>proforma invoice</h4>
          <p>Review proforma invoices online</p>
          <div className="booking-icon">
            <figure>
              <img src={proformainvoicenewsvg} alt="proforma invoice"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4 style={{ textTransform: "uppercase" }}>E-can</h4>
          <p>
            Download your Cargo Arrival Notice by querying on your HBL Number
            online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={ecancansvg} alt="E-can"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4 style={{ textTransform: "uppercase" }}>E-Do</h4>
          <p>
            Pay import invoices and receive electronic delivery order online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={idoiconsvg} alt="E-Do"/>
            </figure>
          </div>
          <div className="booking-link">
            <a href="/E-do" className="btn btn-primary Check-btn">
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4>Reports</h4>
          <p>
            Pre-defined reports &amp; customized reports to better manage your
            shipments online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={reportssvg} alt="Reports"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4>roadbridge</h4>
          <p>
            Obtain quote for DDU/DAP shipments to India and Ex-works shipments
            from India online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={roadbridgenewsvg} alt="roadbridge"/>
            </figure>
          </div>
          <div className="booking-link">
            <a
              href="https://tiva.teamglobal.in/"
              className="btn btn-primary Check-btn"
            >
              Login
            </a>
            <a href="/Register" className="registere-link">
              Register
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="booking-card">
          <h4>Track &amp; Trace</h4>
          <p>
            Obtain quote for DDU/DAP shipments to India and Ex-works shipments
            from India online
          </p>
          <div className="booking-icon">
            <figure>
              <img src={tracktraceiconsvg} alt="track & trace"/>
            </figure>
          </div>
          <div className="booking-link">
            <a href="/track" className="btn btn-primary Check-btn">
              Track Now
            </a>
            {/*<a href="/Register" class="registere-link">Register</a>*/}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        
        </div>
    </>)
}

export default Edo