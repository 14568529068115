import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import brochure from "../../assets/images/brochure.pdf"
import book from "../../assets/images/book-icon.svg"
import download from "../../assets/images/about/download.svg"
import fiata from "../../assets/images/about/fiata.png"
import iata from "../../assets/images/about/iata.png"
import ffi from "../../assets/images/about/ffi.png"
import gpln from "../../assets/images/about/gpln.png"
import amtoi from "../../assets/images/about/amtoi.png"
import iso from "../../assets/images/about/iso.png"
import chat from "../../assets/images/about/chat.svg"
import clock from "../../assets/images/about/clock.svg"
import process from "../../assets/images/about/process.svg"
import graph from "../../assets/images/about/graph.svg"
import member1 from "../../assets/images/about/member1.png"
import member2 from "../../assets/images/about/member2.png"
import member3 from "../../assets/images/about/member3.png"
import member4 from "../../assets/images/about/member4.png"
import member5 from "../../assets/images/about/member5.png"
import member6 from "../../assets/images/about/member6.png"
import member7 from "../../assets/images/about/member7.png"
import member8 from "../../assets/images/about/member8.png"
import member9 from "../../assets/images/about/member9.png"
import member10 from "../../assets/images/about/member10.png"
import member11 from "../../assets/images/about/member11.png"
import banner from "../../assets/images/about/about-banner.png"
import Team from "../../assets/images/about/team-photo.jpg";
import Servicefooter from "../services/servicesfooter";

import FIATApdf from "../../assets/images/profile/latest/FIATA.pdf"
import IATApdf from "../../assets/images/profile/latest/IATA.pdf"
import FFIpdf from "../../assets/images/profile/FFI.pdf"
import GPLNpdf from "../../assets/images/profile/latest/GPLN.pdf"
import AMTOIpdf from "../../assets/images/profile/AMTOI.pdf"
import ISOpdf from "../../assets/images/profile/latest/ISO-90012015.pdf"


const Profile = () => {
	useEffect(() => {

		const metaDescriptionTag = document.querySelector(
		    'head > meta[name="description"]'
		  );
		  document.title="Profile";
		 
		 // console.log("metaDescriptionTag",metaDescriptionTag);
		     metaDescriptionTag.content = "Profile";
		  
	      
	      },[]);




	return (
		<>
			<div class="content">

				<section class="cargo-consolidation about-txt">
					<div class="container">
						<div class="breadcrumb-wrap">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>

									<li class="breadcrumb-item">About Us</li>
									<li class="breadcrumb-item active" aria-current="page">Profile</li>
								</ol>
							</nav>

						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="d-md-flex justify-content-between align-items-center">
									<h2 class="text-capitalize"> About Us</h2>
									<div class="btn-wrap">
										<a href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking" target="_blank" class="btn btn-book btn-primary">
											<span>
												<img src={book} />
											</span>Book Now
										</a>
										<a href={brochure} target="_blank" class="dwn-btn btn btn-primary">
											<span>
												<img src={download} alt="" />
											</span>Download Brochure
										</a>
										{/* <!--<a href="#" class="btn btn-primary">-->
										<!--	<span>-->
										<!--		<img src="assets/images/Quote-icon.svg">-->
										<!--	</span>Request A Quote-->
										<!--</a>--> */}
									</div>
								</div>
							</div>
						</div>

					</div>
				</section>


				<section class="page-tabs-sec">
					<div class="container">
						<div class="">
							<ul class="nav nav-tabs" id="myTab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" href="profile">Profile</a>
								</li>
								<li class="nav-item">
									<a href="team" class="nav-link" id="team-tab">Team</a>
								</li>
								<li class="nav-item">
									<a href="mission" class="nav-link" id="contact-tab">Mission</a>
								</li>

								<li class="nav-item">
									<a href="work-philosophy" class="nav-link" id="team-tab">Work Philosophy</a>
								</li>
								<li class="nav-item">
									<a href="milestones" class="nav-link" id="team-tab">Milestones</a>
								</li>
								<li class="nav-item">
									<a href="csr" class="nav-link" id="team-tab">CSR Activities</a>
								</li>

							</ul>
							<div class="tab-content" id="myTabContent">
								<div class="tab-pane fade show active" id="tablevel1" role="tabpanel" aria-labelledby="home-tab">
									<div class="about-tab-content">
										<h2 class="team-title text-capitalize text-center">As Teamglobal, we strive to offer our customers a well defined and a well structured logistics services.</h2>
										<div class="row">
											<div class="col-md-12">
												<figure class="about-banner"><img src={Team} alt="Team Photo" /></figure>
											</div>
											<div class="col-md-12">
												<h4 class="post-title">Teamglobal has been certified and affiliated with listed agencies and associations</h4>
												<p class=""> Teamglobal is an integrated Logistics services provider headquartered in India. We are offering services in multiple segments of logistics Viz. Sea Freight, Airfreight, Project Cargo Transportation, Cargo Terminals and Coastal Shipping. It has 18 offices in India, Bangladesh, Kenya and Tanzania.</p>
												<h6 class="text-capitalize">accreditation</h6>
												<ul class="accr-list">
													<li class="text-uppercase">
														<a href={FIATApdf} target="_blank">
															<span><img src={fiata} alt="" /></span><strong>FIATA</strong>
															
														</a>
													</li>
													<li class="text-uppercase">
														<a href={IATApdf} target="_blank">
															<span><img src={iata} alt="" /></span><strong>IATA</strong>
														</a>
													</li>
													<li class="text-uppercase">
														<a href={FFIpdf} target="_blank">
															<span><img src={ffi} alt="" /></span><strong>FFI</strong>
														</a>
													</li>
													<li class="text-uppercase">
														<a href={GPLNpdf} target="_blank">
															<span><img src={gpln} alt="" /></span><strong>GPLN</strong>
														</a>
													</li>
													<li class="text-uppercase">
														<a href={AMTOIpdf} target="_blank">
															<span><img src={amtoi} alt="" /></span><strong>AMTOi</strong>
															</a>
													</li>
													<li class="text-uppercase">
														<a href={ISOpdf} target="_blank">
															<span><img src={iso} alt="" /></span><strong>ISO</strong>
														</a>
													</li>
												</ul>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<p class="desc">Established in 2005, Teamglobal is an ISO 9001:2015 (QMS) & ISO 27001:2013 (ISMS) certified company with strong business ethics. Teamglobal specializes in carriage of (LCL) Less than Container load shipments and is the largest operator in India in this category. We have been accredited with the Best Consolidator of the Year – All India 10 times in a row from 2010 to 2019.</p>
												<h4>Global Footprint</h4>
												<p class="desc">Teamglobal is a member of leading global networks like World Wide Alliance, Air Cargo Group and GPLN for the different segments of business it operates. Teamglobal offers transport services between all major international cargo centers using combination by land, sea and air thus making us one stop logistics shop for all our customers Logistics requirements globally.</p>
												{/* <!--<div class="brochure-dwn d-md-flex align-items-start">-->
						  					<!--	<h4 class="dwn-title">Team Global</h4>-->
						  					<!--	<p class="dwn-desc">As Teamglobal, we strive to offer our customers a well defined and a well structure logistics services.</p>-->
						  					<!--	<a href="assets/images/brochure.pdf" target="_blank" class="dwn-btn btn btn-primary"><img src="assets/images/about/download.svg" alt="" /> <span>Download Brochure</span></a>-->
						  					<!--</div>--> */}
												<h4>Service Excellence</h4>
												<p class="desc" style={{ display: "none" }}>A highly motivated and trained team of professionals, financial credibility and integrity are the driving forces of our service standards. Teamglobal Guarantees</p>
												<ul class="excellence-list text-center d-md-flex flex-wrap">
													<li><span><img src={chat} alt="" /></span><strong class="d-block text-capitalize">Speedy <br /> Communication</strong></li>
													<li><span><img src={clock} alt="" /></span><strong class="d-block text-capitalize">Timely <br /> Financial Settlement</strong></li>
													<li><span><img src={process} alt="" /></span><strong class="d-block text-capitalize">Process <br /> Excellence</strong></li>
													<li><span><img src={graph} alt="" /></span><strong class="d-block text-capitalize">Strong focus on <br /> Business Development.</strong></li>
												</ul>
												<h4>Information Technology</h4>
												<p class="desc">Teamglobal takes pride in its IT capabilities and we operate our business using online systems and versatile web module which facilitates quick customer interfacing. Teamglobal has launched TIVA (Teamglobal Interactive Virtual Assistant) offering customer service using latest Chatbot Technology giving info pertaining to Sailing Schedules, Track & Trace, Agent details on Watts App and Mobile App. In addition to these services we offer online business transactions like e-BL, e-DO, e-invoice, e-statement on our Web Portal. These systems are constantly upgraded to adapt to changing statutory and business environments.</p>
												<h4>Process Excellence Center</h4>
												<p class="desc">Teamglobal has achieved uniformity in delivery of our services through ‘Process Excellence Centre’ which is our captive back office. It covers processes of all India Export & Import Documentation, local and international invoicing, settlements, Sailing Schedules, cargo tracking for all our offices within and outside India. This initiative also has a strong supervisory mechanism through which all service parameters are monitored thus ensuring a very high quality uniform service across customers and geographies.</p>
												<h4>Human Resources</h4>
												<p class="desc">At Teamglobal, our Human Resource Management team is a key driver to spread knowledge, skills and ensures positive work attitudes of our team working at all levels. Strong HR processes are in place to attract and retain talent.
													<br />As a business partner of Teamglobal you are assured of efficiency in operational and ethical conduct.</p>

											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane fade" id="tablevel2" role="tabpanel" aria-labelledby="team-tab">
									<div class="team-tab-content">
										<h2 class="team-title text-capitalize text-center">We build business on principles of mutual trust, fairness and Integrity.</h2>
										<div class="team-wrap">
											<div class="row">
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member1} alt="" /></figure>
														<strong class="d-block">Nityam Khosla</strong>
														<span class="user-pos">Director</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member2} alt="" /></figure>
														<strong class="d-block">Vivek Kele</strong>
														<span class="user-pos">Director</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member3} alt="" /></figure>
														<strong class="d-block">Rajesh Bhanushali</strong>
														<span class="user-pos">Director</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member4} alt="" /></figure>
														<strong class="d-block">Hemant Bhanushali</strong>
														<span class="user-pos">Director Sales</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member5} alt="" /></figure>
														<strong class="d-block">Jacob Thomas</strong>
														<span class="user-pos">Director Commercials</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member6} alt="" /></figure>
														<strong class="d-block">Tejan Lakhia</strong>
														<span class="user-pos">Vice President</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member7} alt="" /></figure>
														<strong class="d-block">Mitesh Joshi</strong>
														<span class="user-pos">General Manager - Import Sales</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member8} alt="" /></figure>
														<strong class="d-block">Gaurang Timbadiya</strong>
														<span class="user-pos">General Manager - Finance</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member9} alt="" /></figure>
														<strong class="d-block">Raghavendran B</strong>
														<span class="user-pos">General Manager - Air Freight</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member10} alt="" /></figure>
														<strong class="d-block">Dr. Samta Jain</strong>
														<span class="user-pos">General Manager - HR, IR &amp; Admin</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
												<div class="col-md-4">
													<div class="business-card text-center">
														<figure><img src={member11} alt="" /></figure>
														<strong class="d-block">Shibu Paul Chenathparamban</strong>
														<span class="user-pos">Vice President, WWA Commercial Head</span>
														<p class="user-desc">Submit shipping instructions against confirmed bookings by just amending information online</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane fade" id="tablevel3" role="tabpanel" aria-labelledby="contact-tab">
									<div class="team-tab-content about-tab-content">
										<h2 class="team-title text-capitalize text-center">We build business on principles of mutual trust, fairness and Integrity.</h2>
										<div class="row">
											<div class="col-md-12">
												<figure class="about-banner"><img src={banner} alt="" /></figure>
											</div>
											<div class="col-md-12">
												<h4 class="post-title">Business and Mission</h4>
												<p class=""> We are an integrated logistics service provider specializing in LCL consolidation, Air Freight, Project cargo transportation & Cargo handling infrastructure services.</p>
												<h4 class="post-title">Our Mission</h4>
												<p class="text-capitalize">Continuously offer innovative and reliable logistics solutions meeting our customers changing business requirements with efficiencies of cost and time.</p>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<h4>Quality Policy</h4>
												<p class="desc">Our endeavor is to set new standards in providing cost effective logistics solutions with an emphasis on safe & timely deliveries thereby exceeding our customer’s expectations, while protecting Confidentiality, Integrity and Availability of information.</p>

												<p class="desc">We shall build business on principles of mutual trust, fairness and Integrity.
													<br />This shall be achieved by:</p>
												<p class="desc">
													Adopting right technology
													<br />Building competencies in people
													<br />Addressing service deviations through continual process monitoring and improvement
													<br />Effective customer relationship
													<br />Establishing, implementing and maintaining the quality management system
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane fade" id="tablevel4" role="tabpanel" aria-labelledby="team-tab">
									<div class="team-tab-content about-tab-content">
										<h2 class="team-title text-center">We build business on principles of mutual trust, fairness and Integrity.</h2>
										<div class="row">
											<div class="col-md-12">
												<figure class="about-banner"><img src={banner} alt="" /></figure>
											</div>
											<div class="col-md-12">
												<h4 class="post-title">Work Philosophy</h4>
												<p class="text-capitalize"> At Teamglobal we are committed to our core values and continuously strive to achieve them by committing ourselves to foster a learning environment that is based on Shared values, Openness, Trust and Respect for each other. Our endeavor is to be the best place to work, which is followed by best professional practices for employees to sustain in competitive environment. Innovation and creativity is encouraged and support is provided for initiatives. Employees are encouraged to work without fear and to be innovative and creative. At Teamglobal diversification of workforce is practiced for better relationship between employees, generation of new ideas, for interpersonal growth and also for the growth & development of organization.</p>

											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<h4>Core Values</h4>
												<p class="desc">
													<strong>Professional Commitment:</strong> Professional Commitment means mobilizing the knowledge and know-how of the individual to the best of his ability and also calls for training to keep both up to date. It requires personal commitment and a willingness to take on the tasks assigned and to acquire knowledge necessary to do the job.
													<br /><strong>Respect for Others:</strong> We believe respecting employees is an absolute prerequisite for the professional and personal development of each person. It applies throughout the organization all across branches. It is expressed in a readiness to listen to others, to inform, to explain and to engage in open dialogue with employees.
													<br /><strong>Integrity:</strong> Teamglobal started business with straight forward business principles and have stayed focused to its vision. We have conducted ourselves in an honest and transparent manner to build faith for our stakeholders, employees & society.
													<br /><strong>Solidarity:</strong> It is based on a sense of individual responsibility at work, which prevails over self-centered thinking. It encourages team work and brings out the best in each person, in order to achieve the objectives of the company.
												</p>
												<h4>Fun at TeamGlobal</h4>
												<p class="desc">At Teamglobal as much as we build brand, we believe in building people. Successful relations can only be built and maintained by sharing values and goals and not through authority. As a family we work, play and organize annual Picnic, annual Sports, Indoor games where HR Department helps in creating excitement at workplace by having In-Bay activities, celebration of different festivals, celebration of Women’s Day and many such social and recreational activities.</p>
												<h4>Learning and Development</h4>
												<p class="desc">At Teamglobal Learning and development is a continuous process in all areas. The growth and development of every employee is an endeavor for the company and is a top priority.</p>
												<p class="desc">Continuous assessments of the competence levels of employees are done and various development opportunities and methods are identified and realized to meet the individual development need. Conducive atmosphere is provided for every employee to learn and grow. As a young and rapidly growing organization, our training and development intervention is aligned with the business strategy of the organization for building long term organization. HR Department plays a crucial role in providing learning and development to our people by facilitating trainings in all categories – Functional, Behavioural and Technical.</p>
												<h4>Programmes that we offer</h4>
												<p class="desc">
													<strong>Induction:</strong> A proper and well planned Induction Plan is designed to help new joinees to quickly acquaint themselves with Teamglobal culture and also have a cross-functional overview of various verticals in the company. It is also a platform where Senior Management gets to interact with the new joinees at a personal and professional level. A CFS visit is also scheduled for all new joinees to get more knowledge.
													<br /><strong>Technical Training:</strong> Technical training relevant to each business vertical is identified. It is provided for the skills and knowledge enhancement for a particular subject.
													<br /><strong>Behavioural skills:</strong> Behavioural skills training is provided to help employees understand how people in the organization work, behave and interact and to bring in professionalism in all endeavors.
													<br /><strong>Special Programmes:</strong> Special training Programmes are organized to train employees in various areas for improvement, some Programmes initiated are Leader’s Compass, Financial Empowerment, etc.
													<br /><strong>E-Kautilya (Self learning Portal):</strong> We believe that each employee contributes directly to the growth and success, so this Programme helps them for self growth and development. This Certification Programme is extended to all the full time Employees of Teamglobal. It is an initiative by HR Team in which employers take the virtual session as per the convenience.
													<br /><strong>Counselling &amp; Mentoring Programs:</strong> Our Counselling and Mentoring programs foster supportive relationships that help employees to develop skills, behavior, and insights to enable them to attain their goals. It helps them in both their professional and personal front.
												</p>
												<h4>Employee Friendly Policy</h4>
												<p class="desc">Special care is taken to ensure that employees are satisfied with their roles and responsibilities, as for Teamglobal employees are very important who builds business and happy employees leads to happy customer. The policies at Teamglobal are aimed at providing work life balance to our employees.</p>
												<p class="desc">
													Health checkup camps are organized for employees frequently.
													In addition to base pay, “Performance Linked bonuses’ are also awarded annually.
													Employee referral policy is launched to boost a healthy Teamwork.
													Flexibility is provided to manage work hours (Flexi-time), so employees can meet the needs of personal life.
												</p>
												<h4>Careers</h4>
												<p class="desc">
													<strong>Summer Internship Programme</strong> - Summer internship Programme helps the graduates to learn and have hands on experience along with their studies. It encourages them to initiate their fresh ideas and acquire new skills and knowledge.
													<br />It is a 2 month Programme, where after completion, certificate is provided to individuals as per their performance.
													<br /><strong>Management Trainee Programme</strong> – Management Trainee Programme gives opportunity to individual about understanding of the business. It runs over for a year and after successful completion and based on their performance confirmation is done. It starts with the induction of the individuals, where MT’s understand and goes through business overview and roles in the organization. Each MT is assessed by their supervisors and they guide them for their future endeavors.
												</p>
												<p class="desc"><strong>Experienced professionals</strong> – We have one of the best teams in the logistic industry and we take keen interest in identifying the potentials of the employees. We help them exploring new opportunity, where they can freely express their views & suggestions to the management.</p>

											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane fade" id="tablevel5" role="tabpanel" aria-labelledby="contact-tab">
									<div class="about-tab-content milestones-wrapper">

										<div class="row">
											<div class="col-md-12">
												<h4 class="milest-title text-center">Milestones 2005 - 2018</h4>
												<div class="miles-point">
													<h6>2018</h6>
													<p class="desc text-capitalize">

														<span class="d-block bullet-pt">Teamglobal was honored with "The best LCL consolidator of the year “ (All India) at Mala Awards for the 9th time in a row, August 2018</span>
														<span class="d-block bullet-pt">Awarded as Best LCL Consolidator of the year (Imports) by India Maritime Awards ,June 2018.</span>
														<span class="d-block bullet-pt">Awarded Smart LCL Consolidator Import - Hyderabad (2017-18), Aug 2018</span>
														<span class="d-block bullet-pt">Mr. Nityam Khosla & Mr. Vivek Kele awarded as Dynamic Entrepreneur of the Year in June 2018.</span>
														<span class="d-block bullet-pt">Awarded for Excellence in END USER of IT – Logistics under Small Enterprise Category – 2018 from IMC (Indian Merchant Chambers) - Digital Technology.</span>
														<span class="d-block bullet-pt">Launched the upgraded version of Learning Management Systems “ Kautilya”</span>
														<span class="d-block bullet-pt">Globicon receives the AEO (Authorised Economic Operator) Certification Nov 2018.</span>
														<span class="d-block bullet-pt">Cold storage at Globicon commenced from August 2018</span>
														<span class="d-block bullet-pt">Business activity begins at Cold Room facility at Hyderabad CFS, 2018</span>
														<span class="d-block bullet-pt">Shipment from Rotterdam to Tuticorin for BHEL (2500FRT – Heaviest piece 462MT) July / Aug 2018</span>
														<span class="d-block bullet-pt">LCL new services Started for Gothenburg, Sweden – May 2018 ; for HO-Chi-in City, Vietnam in July 2018 and for Istanbul, Turkey – September 2018.</span>

													</p>
												</div>
												<div class="miles-point">
													<h6>2017</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Project Division successfully handled the delivery of 35 Mtr long column from GMM Pfaudler, Anand, and Gujarat to GSFC Complex, Vadodara, and Gujarat for their expansion project. The Project was successfully delivered without safety incidents in the morning hours : Jan 2017</span>
														<span class="d-block bullet-pt">Coastal Shipping – A New Initiative at Teamglobal started in – Jan 2017</span>
														<span class="d-block bullet-pt">TIVA (Teamglobal Interactive Virtual Assistant). We have launched TIVA on Valentine’s Day 2017 and this is first in our industry, wherein we are providing information such as Track &amp; Trace, Sailing Schedules and Agent Details using popular Social Media Applications like Whatsapp and Facebook Messenger : Feb 2017</span>
														<span class="d-block bullet-pt">Co. launches facility of E-DO Facility : Feb 2017</span>
														<span class="d-block bullet-pt">Co. launches Mobile APP : Mar 2017</span>
														<span class="d-block bullet-pt">HR initiates Learning Management System : Apr 2017</span>
														<span class="d-block bullet-pt">HR Initiatives eKautilya for Mobile Learning : Apr 2017</span>
														<span class="d-block bullet-pt">Co commences operation at Globicon Terminals, India’s first Green Cargo Terminal : Apr 2017</span>
														<span class="d-block bullet-pt">Project Division successfully delivered 64 Mt reactor from GMM Pfaudler, Anand, Gujarat to Ankleshwar GIDC, Bharuch for their expansion project : Jun 2017</span>
														<span class="d-block bullet-pt">The Directors of Teamglobal Logistics Mr. Nityam Khosla &amp; Mr. Vivek Kele received ‘Dynamic Entrepreneur of the Year 2017’ award at the second edition of prestigious India Maritime Awards function in Mumbai : Jun 2017</span>
														<span class="d-block bullet-pt">Teamglobal Chennai office was awarded as ‘Best LCL Consolidator of the year’ at South East CEO Conclave &amp; Awards 2017, Chennai : July 2017</span>
														<span class="d-block bullet-pt">Teamglobal Delhi office moved to a new office premises after 7 years of functioning at Mahipalpur to Suncity Trade tower, Old Gudgeon Road, New Delhi : Aug 2017</span>
														<span class="d-block bullet-pt">Teamglobal was awarded ‘Consolidator of Year’ for the 8th Consecutive time in a row since the year 2010 : Sept 2017 at the prestigious All India MALA Awards 2017</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2016</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Co. Starts Operations in Dhaka and Chittagong (Bangladesh) : Feb 2016</span>
														<span class="d-block bullet-pt">Teamglobal Delhi office successfully handles one more 60 ton Air charter shipment to Zagreb / Republic of Croatia : Feb 2016</span>
														<span class="d-block bullet-pt">Awarded Consolidator of the year at 7th Conquest 2016 Northern India Multimodal Logistics Awards – Northern Region : Mar 2016</span>
														<span class="d-block bullet-pt">Quality Initiative of ISMS 27001 and renewal of ISO 9001 : Nov 2016</span>
														<span class="d-block bullet-pt">Hyderabad Air Team successfully handles Envirotainer RKN t2 (Pivot weight 755Kgs) for one of our Pharma Customer.Shipment that consists of Oxytocin, Injections was exported to IDA Foundation. Amsterdam/Netherlands : Dec 2016</span>
														<span class="d-block bullet-pt">Bangalore Air Team successfully handled a special commodity “Mercedes Benz Simulator Car” Ex Bangalore to Germany : Dec 2016</span>
														<span class="d-block bullet-pt">Chennai Air Team successfully handled Davies Turner Agents special project in Airfreight from Chennai to London : Dec 2016</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2015</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Successfully handles Project Cargo Transport for BHEL for their Yemen Power Project : Jan 2015</span>
														<span class="d-block bullet-pt">Awarded as LCL Consolidator of the year for Kolkata Office at Eastern Star Awards : Jan 2015</span>
														<span class="d-block bullet-pt">Awarded as Consolidator of the year at the 7th South East Cargo &amp; Logistics Awards 2015 – Southern Region : Jul 2015</span>
														<span class="d-block bullet-pt">Co launches self Learning Module as a part of HR initiative – Teamglobal© Kautilya Learning Academy of Professionals : Jul 2015</span>
														<span class="d-block bullet-pt">Awarded Consolidator of Year Mala Award : Sept 2015 – 5th All India MALA 2015 (6 times in a row 2010,11,12,13,14,15)</span>
														<span class="d-block bullet-pt">Implemented HRMS from Spine along with Paperless transaction facility for Conveyance claims : Oct 2015</span>
														<span class="d-block bullet-pt">Teamglobal Delhi office successfully handles 60 ton Air charter shipment to Zagreb / Republic of Croatia : Dec 2015</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2014</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Awarded Consolidator of the Year Mala Award: Sept 2014 at 5th All India MALA 2014(5 times in a row 2010,11,12,13,14)</span>
														<span class="d-block bullet-pt">Co. Starts Operations in Baroda (Gujarat) : Jul 2014</span>
														<span class="d-block bullet-pt">Co implements Disaster Recovery Plan for emergency situation, Vendor – Ctrl S, Mhape, Mumbai : Feb 2014</span>
														<span class="d-block bullet-pt">Co launches facility of Web Bill of Lading : Apr 2014</span>
														<span class="d-block bullet-pt">Co extends services of Air Freight on all India basis making it an all India Product – date change to May 2014</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2013</h6>
													<p class="desc text-capitalize">
														<strong>Karur:</strong>
														<span class="d-block bullet-pt">Teamglobal appoints M/s Eurofreight INC as their agent in Karur. With this development the export cargo originating from Karur region will be sent to Tuticorin Gateway of Teamglobal Logistics. Teamglobal runs several direct services to US, Europe, Scandinavia, Far East Ex the port of Tuticorin and the exporters in Karur will be direct beneficiaries of these direct services.</span>
														<strong>Starts Operations in Ludhiana:</strong>
														<span class="d-block bullet-pt">March 2013Teamglobal extends its activity further in North India by opening their own office in Ludhiana. Ludhiana is a prominent Industrial city in Punjab and specializes in export of several commodities like bicycles, Engineering goods, knitted garments and other specialized items. With opening of this office, Teamglobal has extended its reach further in interiors of North India there by making it one of the biggest LCL NVOCCs in India having a broad nationwide footprint.</span>
														<br /><strong>Jaipur:</strong>
														<span class="d-block bullet-pt">Teamglobal extends is services to Rajasthan (Jaipur, Udaipur, Jodhpur). The shippers in Northern India have been demanding for a quality LCL services for a long time now, after successful launch of operations in Delhi and Ludhiana, now Teamglobal has extended its operations to the state of Rajasthan in North India. Rajasthan has 3 main cargo centers, Jaipur, Udaipur and Jodhpur. Teamglobal will have a fully active office in Jaipur and will have resident reps in Jodhpur and Udaipur to take care of the cargo operations there. Apart from the LCL consolidation service for inbound and out bound shipments, Teamglobal will also offer Full Container Load forwarding and Air Freight services from these new offices in Rajasthan </span>
														<span class="d-block bullet-pt">Co Sets up a Process Excellence Centre (PEC) at Mumbai location to handle back office activities</span>
														<span class="d-block bullet-pt"><strong>Launch of FCL Forwarding Program with dedicated resources on Nation Wide basis :</strong> Jul 2013</span>
														<span class="d-block bullet-pt"><strong>Awarded Consolidator of Year Mala Award:</strong> Sept 2013: 4th All India MALA 2013 (4 times in a row 2010,11,12 & 13)</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2012</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt"> Teamglobal Chennai moves to a new office at Armenian Street, Parrys, Chennai : Jan 2012 –</span>
														<span class="d-block bullet-pt">Given the increasing business activity and no of services being offered on all products of LCL, FCL and Air Freight in Chennai, Teamglobal has shifted it operations to a bigger and modern premises. Chennai is a regional office of Teamglobal Logistics in South India.</span>
														<span class="d-block bullet-pt">Teamglobal reaches top 3 position in LCL Consolidation market in Delhi : Jan 2012 –</span>
														<span class="d-block bullet-pt">Striding on its path of growth, Teamglobal Delhi branch has reached amongst top 3 consolidators in Delhi in the 3rd year of our operations.</span>
														<span class="d-block bullet-pt">Teamglobal participation in CTL July 2011</span>
														<span class="d-block bullet-pt">Delhi LCL Consolidation activity shifts to ICD TKD (TUGLAKABAD) : May 2012 –</span>
														<span class="d-block bullet-pt">Teamglobal Delhi closed its first consolidation box on 5th May 2012 at ICD TKD. Teamglobal is the first Consolidator to have have closed the first LCL box at this renewed facility.</span>
														<span class="d-block bullet-pt">Awarded Consolidator of Year Mala Award : Sep 2012 –</span>
														<span class="d-block bullet-pt">In what can be termed as a wonderful hatrick Teamglobal bagged the coveted Consolidator of the Year award at a glittering function conducted at the prestigious Hotel President in Mumbai.</span>
														<span class="d-block bullet-pt">Vivek Kele, Director Teamglobal, elected as an Honorary Secretary of AMTOI : Sept 2012 –</span>
														<span class="d-block bullet-pt">Our Director Mr. Vivek Kele was unanimously elected as an Hon Secretary of Association of Multimodal Transport Operators of India</span>
														<span class="d-block bullet-pt">Starts Operations in Kenya and Tanzania : Dec 2012 –</span>
														<span class="d-block bullet-pt">In what can be termed as a giant leap forward, Teamglobal has commenced our operations in Kenya and Tanzania to handle the global LCL Consolidation activity. These are the first overseas offices of Teamglobal outside India.</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2011</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Consolidates operations in new office at Times Square, Andheri, Mumbai : Aug 2011</span>
														<span class="d-block bullet-pt">Awarded Consolidator of Year Mala Award : Sep 2011</span>
														<span class="d-block bullet-pt">Started own office in Hyderabad : Oct 2011</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2010</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Co. Starts Operations in Kochi : Feb 2010</span>
														<span class="d-block bullet-pt">Became Member of GPL (Global Projects Logistics) Network : May 2010</span>
														<span class="d-block bullet-pt">Awarded Consolidator of Year Mala Award : Sep 2010</span>
														<span class="d-block bullet-pt">Became member of ACG ( Air Cargo Network) network : Nov 2010</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2009</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Co. shifts Air, Imports, IT and Projects Divn to new premises at Marwah Estate in Mumbai : Jan 2009</span>
														<span class="d-block bullet-pt">Co. Starts Operations in Delhi : Apr 2009</span>
														<span class="d-block bullet-pt">Successfully implemented ERP software along with Ecommerce module : Apr 2009</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2008</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Co. Starts Operations in Pune : Jan 2008</span>
														<span class="d-block bullet-pt">Became member of WWA ( World Wide Alliance) Network : Sep 2008</span>
														<span class="d-block bullet-pt">Gained IATA  affiliation : Oct 2008</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2007</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Co. Starts Operations in Bangalore : Jan 2007</span>
														<span class="d-block bullet-pt">Successfully registered with FMC  : Apr 2007</span>
														<span class="d-block bullet-pt">Co. Starts Operations  in Tuticorin : Apr 2007</span>
														<span class="d-block bullet-pt">Achieved ISO 9001-2000 certification : Apr 2007</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2006</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Co. Starts Operations in Kolkatta : Jan 2006</span>
														<span class="d-block bullet-pt">Successfully registered with D.G. Shipping as an approved MTO : Apr 2006</span>
													</p>
												</div>
												<div class="miles-point">
													<h6>2005</h6>
													<p class="desc text-capitalize">
														<span class="d-block bullet-pt">Teamglobal Logistics Pvt. Ltd., Co. formed on 25th Apr 2005</span>
														<span class="d-block bullet-pt">Co. starts its operations in Mumbai at Ballard Estate : Aug 2005</span>
														<span class="d-block bullet-pt">Co. starts it operations in Chennai : Oct 2005</span>
														<span class="d-block bullet-pt">Co. Starts Agency Corporation with Shipco Transport : Dec 2005</span>
														<span class="d-block bullet-pt">Co. Starts Operations in Ahmedabad : Dec 2005</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane fade" id="tablevel6" role="tabpanel" aria-labelledby="team-tab">
									<div class="about-tab-content">

										<div class="row">
											<div class="col-md-12">
												<h4>Corporate Social Responsibility</h4>
												<h6>Overview</h6>
												<p class="desc">Teamglobal believes in “The power of Giving – to the society”, through the CSR Initiatives with an aim to behave ethically and contribute to the economic, social, intellectual development of the society. TG’s CSR approach comprises of efforts in the areas of Education, Health care, Health awareness camp, Environment, Social Empowerment & Sports & Culture. Teamglobal with its Philanthropist approach has been quietly active in Social pursuits and has always endeavored to be the “Seed of Change”. Some of the initiatives worth mentioning are blood donation camps, donation of clothes, books, medicines to the NGO’s etc.</p>
												<p class="desc">To live up to its vision, Teamglobal launched a Trust “Saksham Sarthi” and the core activities of the Trust will see a reflection of business responsibility towards the society. The process of funding the NGO’s was initiated basis one of the objectives of Trust.</p>
												<p class="desc">Other Core Objectives of the Trust are:</p>
												<p class="desc">
													Educational
													<br />Medical
													<br />Relief to the poor
													<br />General Public Utility.
												</p>
												<p class="desc">To ensure the CSR initiatives are carried out in the right spirit Teamglobal did an extensive search and finalised two NGO’s to get associated with for the year 2016-17 namely:</p>
												<p class="desc">
													<strong>1. Asha Sadan</strong> – engaged in caring and development of abandoned and less privileged girl child & women. Established in the year 1962, for over 90 years, this non-governmental organisation has brought like-minded women together in the service of others, to help tackle social and medical problems faced by poor women and children in the city.
													<br /><strong>2. Aasara</strong> – engaged in upliftment of street children. Established in the year 1996 with a mission to bring meaning in the lives of street children by providing various needs-based services to develop their inherent talent and re-integrate them back to society.
												</p>
												<p class="desc">Core criteria for NGO selection were as follows:</p>
												<p class="desc">
													Education for Girl Child & Women.
													<br />Child and Mother care.
													<br />Diet and Nutrition
													<br />Organizing training programmes for women on tailoring Embroidery designs,
													<br />Vocational Courses.
												</p>
												<h4>Saksham Sarthi’s CSR Activities:</h4>
												<h6>Namasyaa</h6>
												<p class="desc">To make a social cause, a social movement, it is very important to sensitize & mobilize the public. In the effort to do the same, a two-day exhibition was hosted under the brand of “Namasya”. Saksham Sarthi came live through the branded Namasya articles like candles, florals, articrafts, utility items and other decorative pieces, which were exhibited for sale. The objective is to channelize the profit earned for social & developmental purpose.</p>
												<h6>Prameya</h6>
												<p class="desc">Saksham Sarthi initiated out Knowledge sharing activities under the name of “Prameya” which stands for Knowledge in Sanskrit. The first activity came as a Knowledge sharing platform for the Housekeeping, helpers, support staff, drivers of Teamglobal & other corporates of Times Square. Forty participants attended this session; which was hosted in association with a NGO “Shamal”. The session on Financial Awareness like Government policies, saving accounts, Investment schemes and other government schemes, which are Accident & Life Insurance, Pension, PPF, Scheme for the Girl Child, Loans etc. extended time to time.</p>
												<h6>Joy of Giving Season</h6>
												<p class="desc">Teamglobal opened the Joy of Giving season on 07th October, 2016 by inviting members to post their wish list on the wish tree. This was to celebrate both giving & receiving for themselves and their family. Team members put forth 156 wishes on the Wish tree and the company granted the same and some benevolent employees also sponsored some of gifts as well.</p>
												<h6>Association with NGO’s</h6>
												<p class="desc">In pursuit of the corporate social responsibility, Teamglobal registered its Trust “Saksham Sarthi” under The Maharashtra Public Trust Act 1950 & Indian Income Tax Act 1961. To work with the society at the grassroots level the NGO’s working in the area of Education, Street Children, Women & children Empowerment & Vocational Trainings were sought after. After a rigorous assessment & analysis of nearly 20 NGO’s, a search committee consulting the Trustees of Trust & Teamglobal’s HR Team, finally zeroed down upon following 02 NGO’s for association.</p>
												<p class="desc">
													<strong>1. Asha Sadan</strong> – engaged in caring and development of abandoned and less privileged girl child & women. Established in the year 1962, for over 90 years, this non-governmental organisation has brought like-minded women together in the service of others, to help tackle social and medical problems faced by poor women and children in the city.
													<br /><strong>1. Aasara</strong> – engaged in upliftment of street children. Established in the year 1996 with a mission to bring meaning in the lives of street children by providing various needs-based services to develop their inherent talent and re-integrate them back to society.
												</p>
												<p class="desc">Saksham Sarthi listed some activities and task to be sponsored, which were education of street children at Aasara; gifted Desktops 7 also sponsored for their sports & other development endeavors. Asha Sadan was extended support by sponsoring Desktops and providing for livelihood for 175 beneficiaries for a period of 6 months.</p>
												<p class="desc">To promote a culture of volunteering Team members of TG will be associated for weekend/fortnight activities like Skill Dvelopment and trainings. In addition, an internal Audit committee is made to audit the Physical assets from time to time.</p>
												<h6>Direct Initiatives</h6>
												<p class="desc">Saksham Sarthi has also been involved in benefiting some individuals directly. The modusoperandi is to get a list of people who are seeking educational assistance from families of lower income groups. On receipt of application & filtering basis the eligibility & financial status, the sponsorship is extended for higher education.</p>
												<h6>Vocational / Creative Skills to Associates</h6>
												<p class="desc">We aim to provide Vocational / Creative skills to the beneficiaries at our associated NGO’s in the areas of Computer Education, Excel training, Spoken English, Creative Skills like Drawing / Paintings and Rangoli making to state a few of them. We have received nominations from volunteers who wish to extend their support and we will be having the scheduled shared by 01st week of April, 2017.</p>
												<h4>Teamglobal’s CSR Activities</h4>
												<h6>Blood Donation Camp</h6>
												<p class="desc">In association with Rotary Club Powai, a one-day Blood Donation Camp was organized at Teamglobal Mumbai Location. Camp was supported by Sarvodaya Hospital Samarpan Blood Bank. The camp witnessed enthusiastic donors – with donors from Teamglobal fraternity as well as other Corporates from Times Square Building and collected 75 units of blood.</p>
												<p class="desc">The enthusiastic donors were willing to donate blood to serve to the needs of the patience and the camp witnessed many others who came forward to donate yet because of medical reasons were not allowed. Teamglobal has been hosting such activities for the last 05 years.</p>
												<h6>Clothes Donation to NGO Goonj</h6>
												<p class="desc">Teamglobal employees presented clothes to NGO Goonj. It is a regular cycle that members gift all necessary items & same is shared with Goonj.</p>
												<p class="desc">Green Initiatives</p>
												<p class="desc">We believe every individual has a role to play in fighting the harmful consequences of E-waste.</p>
												<p class="desc">To combat this Teamglobal has taken a novel step, by tying up with E-Ward and Co it is ensured that the disposal of the Hazardous E-Waste is done in an Environmental Friendly manner.</p>
												<h4>Our Future Action Plans</h4>
												<p class="desc">
													To further extend the philanthropic activities we have chalked down our Futuristic action plan as under:
													<br />1. Extending the scope of Saksham Sarthi to other Branches, who will project interest to volunteer for the same.
													<br />2. Getting associated with other related NGO’s / Association and social institutions, which are related to the objectives of Saksham Sarthi.
													<br />3. Enhancing the number of TGites to volunteer for the program.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>




			</div>
			{/* <Servicefooter /> */}

		</>
	)
}

export default Profile