import React, { useEffect } from "react";

import book from "../../../assets/images/book-icon.svg";
import service from "../../../assets/images/other-service-icon.svg";


import lcl from "../../../assets/images/lcl-opratior.svg";
import air from "../../../assets/images/air-freight-banner.jpg";
// import air from "../../../assets/images/Air-freight.jpeg"
import digital from "../../../assets/images/digital-offerings.svg";

const AirFreightConsolidation = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Air Freight Consolidations";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Air Freight Consolidations";
  }, []);



  
  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Services</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Air Freight
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize"> air freight</h2>
                  <div className="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      className="btn btn-book btn-primary"
                      rel="noreferrer"

                    >
                      <span>
                        <img src={book} alt=""/>
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" className="btn btn-primary">-->
										<!--	<span>-->
										<!--		<img src="assets/images/Quote-icon.svg">-->
										<!--	</span>Request A Quote-->
										<!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="other-services-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4 left-nav">
                <div className="service-nav ">
                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={service} alt=""/>
                      </span>
                      Other Services
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="sea-cargo-consolidation">
                          Sea Cargo Consolidation
                        </a>
                      </li>
                      <li>
                        <a href="sea-freight-forwarding">
                          Sea Freight Forwarding
                        </a>
                      </li>
                      <li className="active">
                        <a href="air-freight">Air Freight</a>
                      </li>
                      <li className="">
                        <a href="project-cargo-forwarding">
                          Project Cargo Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="container-freight-station">
                          Container Freight Station
                        </a>
                      </li>
                      <li>
                        <a href="coastal-shipping">Coastal Shipping</a>
                      </li>

                      {/* <li>
                        <a href="transporation">Transporation</a>
                      </li> */}
                      {/* <li>
                        <a href="warehousing">Warehousing</a>
                      </li> */}
                      {/* <li>
                        <a href="customs-clearance">Customs Clearance</a>
                      </li> */}
                      {/* <li>
                        <a href="po-management">Po Management</a>
                      </li> */}
                      {/* <li>
                        <a href="pick-pack">Pick & Pack</a>
                      </li> */}
                      {/* <li>
                        <a href="quality-control-inspection">
                        Quality Control & Inspection
                        </a>
                      </li> */}
                    </ul>
                  </div>

                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} alt=""/>
                      </span>
                      Ecommerce
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                          target="_blank"
                          rel="noreferrer"
                        >
                          e-booking
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-SI
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-BL
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                          target="_blank"
                          rel="noreferrer"
                        >
                          proforma invoice
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-CAN
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="e-do"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-DO
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                          target="_blank"
                          rel="noreferrer"
                        >
                          reports
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://tiva.teamglobal.in/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          roadbridge
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="service-block mb-0">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} alt=""/>
                      </span>
                      Tools
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="global-locations">Global Locations</a>
                      </li>
                      <li>
                        <a href="warehouse-locations">Warehouse Locations</a>
                      </li>
                      <li>
                        <a
                          href="inco-terms"
                          style={{ textTransform: "inherit" }}
                        >
                          INCO Terms
                        </a>
                      </li>
                      <li>
                        <a href="shipping-glossary">Shipping Glossary</a>
                      </li>
                      <li>
                        <a href="conutry-restrictions">Country Restrictions</a>
                      </li>
                      <li>
                        <a href="freight-calculator">Freight Calculator</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="consolidation-details">
                  <figure>
                    <img src={air} alt="Air Freight Banner" />
                  </figure>

                  <h4>Air Freight</h4>
                  {/* <p>
                    Air freight is another segment of logistics that Teamglobal
                    is consistently expanding year on year. Being a part of
                    Prestigious Air Cargo Group (ACG) we have access to best in
                    the international className agency network across Globe. Our
                    international agency network of air freight agents ensures
                    that our services are efficient and competitive thereby
                    helping to grow our customers business.
                    <br />
                    <br />
                    Our services include value added solutions to our customers
                    which comprise of Ex-Works Pick Up, Door Deliveries, Third
                    Country and Road Haulage in addition to the air freight leg.
                    We also offer Innovative Tailor made solutions to your
                    logistics problems.
                    <br />
                    <br />
                    We are also building Air Import consolidation services from
                    major cargo origins internationally to destinations in India
                    which gives our customers benefit of cost and efficient
                    cargo handling. Teamglobal also offers Multimodal options
                    like Sea+air, Air-Air (International Transhipment) and Cross
                    Country Solutions to suit our customers need.
                  </p> */}

              
                  <p>Air freight is another segment of logistics that Teamglobal is consistently expanding year on year. Being a part of Prestigious Air Cargo Group (ACG) we have access to best in the international class agency network across Globe. Our international agency network of air freight agents ensures that our services are efficient and competitive thereby helping to grow our customers business.</p>

                  <p>Our services include value added solutions to our customers which comprise of Ex-Works Pick Up, Door Deliveries, Third Country and Road Haulage in addition to the air freight leg. We also offer Innovative Tailor made solutions to your logistics problems.</p>

                  <p>We are also building Air Import consolidation services from major cargo origins internationally to destinations in India which gives our customers benefit of cost and efficient cargo handling. Teamglobal also offers Multimodal options like Sea+air, Air-Air (International Transhipment) and Cross Country Solutions to suit our customers need.</p>
                  

                  {/* <!--<h4>Title 2 - Dummy Content</h4>-->
									<!--<p>We have 135 direct Export services Ex various ports and ICDs in India making us the largest LCL operator in India. Our large customer base and consistent volumes ensures that we are able to maintain a very high schedule integrity.</p><p> We have dedicated Haz Cargo desk which helps our customers ship their haz cargoes faster. We have an escalation matrix in place ensuring quick resolution to service deviations.-->
									<!--</p>-->
									
									
									<!--<h4>Title 3 - Dummy Content</h4>-->
									<!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.<br><br>  We have Direct Services from Global cargo origins to main ports of Nava Sheva and Chennai. The other large ports of Kolkota, Tuticorin and Kochi are covered through international transshipment ports of Colombo, Singapore, Hong Kong and Dubai. We fixed day departures Ex Nava Sheva to New Delhi (ICD Patparganj and ICD Tughlakabad),ICD Ahmedabad and Ex Chennai to ICD Bangalore and ICD Hyderabad.</p>-->
									
									<!--<h4>Why Choose Team Global?</h4>-->
									<!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.</p>-->
								 */}
                </div>

                <div className="most-service">
                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>Contract with major carriers</strong>
                    {/* <!--<p>ICDs in India</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>Pan-India Presence across 18 locations</strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>
                      Offering services globally through ACG network{" "}
                      <a
                        href="http://aircargogroup.com/"
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          position: "absolute",
                          paddingLeft: "10px",
                          color: "#0072BE",
                        }}
                      >
                        {" "}
                        AirCargoGroup
                      </a>{" "}
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>Dedicated team for Air</strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <Servicefooter /> */}
    </>
  );
};

export default AirFreightConsolidation;
