import React, { useEffect } from "react";

import book from "../../../assets/images/book-icon.svg";
import service from "../../../assets/images/other-service-icon.svg";

import handling from "../../../assets/images/cargo-handling-infrastructure-banner.jpg";
import lcl from "../../../assets/images/lcl-opratior.svg";
import digital from "../../../assets/images/digital-offerings.svg";
const ContainerFreightConsolidation = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Container Freight Consolidation";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Container Freight Consolidation";
  }, []);

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Services</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Container Freight Station
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize">
                    {" "}
                    Container Freight Station
                  </h2>
                  <div className="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      className="btn btn-book btn-primary"
                      rel="noreferrer"
                    >
                      <span>
                        <img src={book} alt=""/>
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" className="btn btn-primary">-->
										<!--	<span>-->
										<!--		<img src="assets/images/Quote-icon.svg">-->
										<!--	</span>Request A Quote-->
										<!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="other-services-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4 left-nav">
                <div className="service-nav ">
                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={service} alt=""/>
                      </span>
                      Other Services
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="sea-cargo-consolidation">
                          Sea Cargo Consolidation
                        </a>
                      </li>
                      <li>
                        <a href="sea-freight-forwarding">
                          Sea Freight Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="air-freight">Air Freight</a>
                      </li>
                      <li>
                        <a href="project-cargo-forwarding">
                          Project Cargo Forwarding
                        </a>
                      </li>
                      <li className="active">
                        <a href="container-freight-station">
                          Container Freight Station
                        </a>
                      </li>
                      <li>
                        <a href="coastal-shipping">Coastal Shipping</a>
                      </li>

                      {/* <li>
                        <a href="transporation">Transporation</a>
                      </li> */}
                      {/* <li>
                        <a href="warehousing">Warehousing</a>
                      </li> */}
                      {/* <li>
                        <a href="customs-clearance">Customs Clearance</a>
                      </li> */}
                      {/* <li>
                        <a href="po-management">Po Management</a>
                      </li> */}
                      {/* <li>
                        <a href="pick-pack">Pick & Pack</a>
                      </li> */}
                      {/* <li>
                        <a href="quality-control-inspection">
                        Quality Control & Inspection
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={digital}alt="" />
                      </span>
                      Ecommerce
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                          target="_blank"
                          rel="noreferrer"
                        >
                          e-booking
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-SI
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-BL
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                          target="_blank"
                          rel="noreferrer"
                        >
                          proforma invoice
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-CAN
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="e-do"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-DO
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                          target="_blank"
                          rel="noreferrer"
                        >
                          reports
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://tiva.teamglobal.in/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          roadbridge
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="service-block mb-0">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} alt=""/>
                      </span>
                      Tools
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="global-locations">Global Locations</a>
                      </li>
                      <li>
                        <a href="warehouse-locations">Warehouse Locations</a>
                      </li>
                      <li>
                        <a
                          href="inco-terms"
                          style={{ textTransform: "inherit" }}
                        >
                          INCO Terms
                        </a>
                      </li>
                      <li>
                        <a href="shipping-glossary">Shipping Glossary</a>
                      </li>
                      <li>
                        <a href="conutry-restrictions">Country Restrictions</a>
                      </li>
                      <li>
                        <a href="freight-calculator">Freight Calculator</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="consolidation-details">
                  <figure>
                    <img
                      src={handling}
                      alt="Cargo Handling Infrastructure Banner"
                    />
                  </figure>

                  <h4>Container Freight Station</h4>
                  <p>
                    Teamglobal Logistics’s CFS Division - Globicon Terminals,
                    operates at 3 cities Mumbai, Chennai and Hyderabad.
                    <br />
                    <br />
                    Globicon Terminals, at NhavaSheva is the first Green Cargo
                    Terminal CFS that commenced operations in April 2017.
                    <br />
                    <br />
                    The CFS offers its services in areas of EXIM, Consolidation,
                    De-consolidation, Bonded Cargo, Cold room and Haz Cargo
                    storage. It is committed to excellence in providing the
                    services setting new standards in providing logistics and
                    warehousing solutions.
                    <br />
                    <br />
                    It is the first cargo terminal of its kind to apply for
                    Total Quality Management Systems to cover its operations,
                    data security, health and occupational safety standards. The
                    various certifications already conferred on to Globicon
                    Terminals are
                    <br />
                    <br />• ISO 9001:2015 for Quality Management Systems,
                    <br />• ISO 14001: 2015 for Environmental Management
                    Systems,
                    <br />• ISO 18001:2007 for Occupational Safety Management
                    Certification,
                    <br />• ISO 27001:2013 for Information Security Management
                    System,
                    <br />• CT-PAT Customs Trade Partnership Against Terrorism
                    and
                    <br />• The most coveted AEO – Authorized Economic Operator
                    by the Central Board of Indirect Taxes.
                  </p>

                  <p>
                    The Cargo terminal has a well-designed layout with marked
                    vehicle movement,Functional and Friendly IT
                    systems,Signage’s, Green areas, Parking zones, Security
                    systems, Driver Convenience Zone, Hygienic Canteen facility,
                    24/7 CCTV monitoring system covering the entire perimeter,
                    well equipped machines for its operations.
                  </p>
                  <p>
                    The CFS is also the First Cargo Terminal to introduce its
                    ‘Faceless Initiative’ through its Customer Portal to ‘Ease
                    the way of Doing Business’.
                  </p>
                  <p>
                    Globicon Terminals, operated by Chandra CFS and Terminal
                    Operators at Chennai, was acquired in December 2019.
                  </p>
                  <p>
                    The CFS has an annual handling capacity of 60,000 TEUs,
                    catering to sophisticated export and import cargo handling
                    activities . By acquiring the CFS, Teamglobal extends it’s
                    reach to its customers in the Chennai market.{" "}
                  </p>
                  <p>
                    Globicon Terminals, operated by BATCO Integrated Logistics
                    Pvt Ltd, Hyderabad, is an inland port facility and is the
                    First privately owned road based Inland Port in the state of
                    Telangana with a capacity to handle over 1500 TEUs with
                    adequate handling equipment anda good infrastructure.
                  </p>
                  <p>
                    The facility at Hyderabad offers seamlessly co-ordinated
                    logistic services to the EXIM community in Telangana. BATCO
                    CFS was the first private Container Freight Station to be
                    established in the capital city of Hyderabad as in Inland
                    Extension to the Gateway Ports.
                  </p>
                  <p>
                    BATCO – CFS serves the global operations of business
                    community with custom clearance facility, cargo warehousing,
                    container stuffing / de-stuffing, carting, bonded
                    warehousing, cold pharma zone and several other value added
                    services. The CFS has made rapid progress in its business by
                    providing efficient services to its customers through
                    innovation, decades of logistic experience, technology and
                    infrastructure of International Standards and human touch.
                  </p>

                  {/* <!--<h4>Title 2 - Dummy Content</h4>-->
									<!--<p>We have 135 direct Export services Ex various ports and ICDs in India making us the largest LCL operator in India. Our large customer base and consistent volumes ensures that we are able to maintain a very high schedule integrity.</p><p> We have dedicated Haz Cargo desk which helps our customers ship their haz cargoes faster. We have an escalation matrix in place ensuring quick resolution to service deviations.-->
									<!--</p>-->
									
									
									<!--<h4>Title 3 - Dummy Content</h4>-->
									<!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.<br><br>  We have Direct Services from Global cargo origins to main ports of Nava Sheva and Chennai. The other large ports of Kolkota, Tuticorin and Kochi are covered through international transshipment ports of Colombo, Singapore, Hong Kong and Dubai. We fixed day departures Ex Nava Sheva to New Delhi (ICD Patparganj and ICD Tughlakabad),ICD Ahmedabad and Ex Chennai to ICD Bangalore and ICD Hyderabad.</p>-->
									
									<!--<h4>Why Choose Team Global?</h4>-->
									<!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.</p>-->
								 */}
                </div>

                <div className="most-service">
                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>
                      Facilities in Nhava Sheva, Chennai and Hyderabad, Nhava
                      Sheva facility being the first Green CFS under IGBC with
                      Platinum rating
                    </strong>
                    {/* <!--<p>ICDs in India</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>
                      First CFS provider in India to introduce Faceless
                      operations through its customer portal{" "}
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} alt=""/>
                    </span>
                    <strong>
                      First CFS provider to certify for Total Quality Management
                      systems across all its locations and in process of
                      acquiring AEO status{" "}
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default ContainerFreightConsolidation;
