import React from "react";

// import location from "../../assets/images/location-icn-yellow.svg"
import location from "../../../assets/images/location-icn-yellow.svg";
// import phone from "../../../assets/images/phone-icn-yellow.svg";
// import email from "../../../assets/images/email-icn-yellow.svg";
import phone from "../../../assets/images/phone-icn.svg";
import email from "../../../assets/images/email-icn.svg";
import lofo from "../../../assets/images/lofo-flag.png";
import flag1 from "../../../assets/images/flag01.png";
import flag2 from "../../../assets/images/flag02.png";
import flag3 from "../../../assets/images/flag03.png";
import flag4 from "../../../assets/images/flag04.png";
// import Servicefooter from "../../services/servicesfooter";
// import { Link } from "react-router-dom";
const Kolkata = () => {
  return (
    <>
      <div className="content">
        <section className="contact-head">
          <div className="cargo-consolidation about-txt">
            <div className="container">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-md-flex align-items-center">
                    <h2 className="text-capitalize">Contact Us</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-info-wrp">
          <div className="container">
            <ul className="country-list">
              <li className="list-item">
                <a href="contact" class="text-capitalize ">
                  {" "}
                  <span className="flag-icn">
                    <img src={lofo} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>Corporate Office</span>
                </a>
              </li>
              <li className="list-item">
                <a href="#" class="text-capitalize active">
                  {" "}
                  <span class="flag-icn">
                    <img src={flag1} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>India</span>
                </a>

                <ul className="country-sub-menu">
                  <li>
                    <a href="/ahmedabad">Ahmedabad</a>
                  </li>
                  <li>
                    <a href="bangalore">Bangalore</a>
                  </li>
                  <li>
                    <a href="baroda">Baroda</a>
                  </li>
                  <li>
                    <a href="chennai">Chennai</a>
                  </li>
                  <li>
                    <a href="coimbatore">Coimbatore</a>
                  </li>
                  {/* <li>
                    <a href="gandhidham">Gandhidham</a>
                  </li> */}
                  <li>
                    <a href="hyderabad">Hyderabad</a>
                  </li>
                  {/* <li>
                    <a href="jaipur">Jaipur</a>
                  </li> */}
                  <li>
                    <a href="kochi">Kochi</a>
                  </li>
                  <li>
                    <a href="kolkata">Kolkata</a>
                  </li>
                  <li>
                    <a href="ludhiana">Ludhiana</a>
                  </li>
                  <li>
                    <a href="mumbai">Mumbai</a>
                  </li>
                  <li>
                    <a href="newdelhi">New Delhi</a>
                  </li>
                  <li>
                    <a href="pune">Pune</a>
                  </li>
                  {/* <li>
                    <a href="/surat">Surat</a>
                  </li> */}
                  <li>
                    <a href="tirupur">Tirupur</a>
                  </li>
                  <li>
                    <a href="tuticorin">Tuticorin</a>
                  </li>
                  {/* <!--									<li style="border-top: 1px solid #eaeaea;"><strong>Agencies</strong></li>-->
                                <!--<li><a href="karur">Karur</a></li>-->
                                <!--									<li><a href="moradabad">Moradabad</a></li> --> */}
                </ul>
              </li>
              <li class="list-item">
                <a href="#" class="text-capitalize">
                  {" "}
                  <span class="flag-icn">
                    <img src={flag2} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>bangladesh</span>
                </a>
                <ul class="country-sub-menu">
                  <li>
                    <a href="chittagong">Chittagong</a>
                  </li>
                  <li>
                    <a href="dhaka">Dhaka</a>
                  </li>
                </ul>
              </li>
              <li class="list-item">
                <a href="kenya" class="text-capitalize">
                  {" "}
                  <span class="flag-icn">
                    <img src={flag3} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>Kenya</span>
                </a>
              </li>
              <li class="list-item">
                <a href="tanzania" class="text-capitalize">
                  {" "}
                  <span class="flag-icn">
                    <img src={flag4} alt="" />
                  </span>
                  <span style={{ paddingTop: "2px" }}>Tanzania</span>
                </a>
              </li>
            </ul>

            <div class="corporate-office">
              <div class="corporate-title d-md-flex justify-content-between align-items-center">
                <h4>Kolkata Office, India</h4>
                <a href="#contact-form">
                  <button class="btn btn-primary">Fill The Form</button>
                </a>
              </div>

              <div class="corporate-teamglobal">
                <div class="row">
                  <div class="col-md-6">
                    <div class="address w-100">
                      <figure>
                        <img src={location} alt="" />
                      </figure>
                      <p class="mb-0">
                        Everest House, Room No. 6F, 46C J. L. Nehru Road,
                        Kolkata - 700071, West Bengal - INDIA
                      </p>
                    </div>
                    <div class="address w-100">
                      <figure>
                        <img src={location} alt="" />
                      </figure>
                      <p class="mb-0">
                      10-A, Ho-Chi-Minh Sarani,Bells House, 1St Floor, Flat no-S 101/B1, Kolkata - 700071, West Bengal - INDIA

                      </p>
                    </div>
                    <div class="address border-bottom pb-4 pt-md-4 d-flex align-items-center">
                      <figure class="cust-fig">
                        <img src={phone} alt="" />
                      </figure>
                      <p class="mb-0">
                        <a
                          href="tel:+91 80 40872500"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#385B72" }}
                        >
                          +91 33 4016 7100
                        </a>
                      </p>
                    </div>
                    <div class="address border-bottom pb-4 pt-md-4 d-flex align-items-center">
                      <figure class="cust-fig">
                        <img src={email} alt="" />
                      </figure>
                      <p class="mb-0">
                        <a
                          href="mailto:info@teamglobal.in"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: " #385B72" }}
                        >
                          info@teamglobal.in
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    {/* <!--<figure>-->
											<!--	<img src="assets/images/map-yellow.png">-->
											<!--</figure>--> */}
                    <iframe
                      title="."
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7369.738294353199!2d88.348257!3d22.546574!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027711588dfee3%3A0x44f926811eab7bba!2sEverest%20House%2C%20Jawaharlal%20Nehru%20Rd%2C%20Kankaria%20Estates%2C%20Park%20Street%20area%2C%20Kolkata%2C%20West%20Bengal%20700071!5e0!3m2!1sen!2sin!4v1621358636430!5m2!1sen!2sin"
                      width="100%"
                      height="160"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>

              <div class="corporate-team">
                {/* <!--<h4><span> Directors </span></h4>--> */}
                <div class="row">
                  <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Branch Manager</p>
                      <h2>Mr. Santanu Chakraborty</h2>

                      <a href="tel:919748406063">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 9748406063
                      </a>

                      <a href="mailto:santanu.cal@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        santanu.cal@teamglobal.in
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Operations Head</p>
                      <h2>Ms. Sweta Chowdhury</h2>

                      <a href="tel:919748406066">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 9748406066
                      </a>

                      <a href="mailto:expcsv.cal@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        expcsv.cal@teamglobal.in
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="corporate-team">
                {/* <!--<h4><span>National Head</span></h4>--> */}
                <div class="row">
                  <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Sea Freight Export</p>
                      <h2>Ms. Sweta Chowdhury</h2>

                      <a href="tel:919748406066">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 9748406066
                      </a>

                      <a href="mailto:expcsv.cal@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        expcsv.cal@teamglobal.in
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Sea Freight Import</p>
                      <h2>Mr. Sayed Bastami</h2>

                      <a href="tel:91 9748406069">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 9748406069
                      </a>

                      <a href="mailto:sayedb.cal@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        sayedb.cal@teamglobal.in
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Air Freight</p>
                      <h2>Mr. Pradip Das</h2>

                      <a href="tel:91 9007734552">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 9007734552
                      </a>

                      <a href="mailto:pradipd.cal@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        pradipd.cal@teamglobal.in
                      </a>
                    </div>
                  </div>
                  {/* <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Project Cargo</p>
                      <h2>Mr. Nilesh Chowta</h2>

                      <a href="tel:918286888868">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 8286888868
                      </a>

                      <a href="mailto:nileshc@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        nileshc@teamglobal.in
                      </a>
                    </div>
                  </div> */}

                  <div class="col-md-4 col-lg-3">
                    <div class="teamglobal-card">
                      <p>Coastal Shipping</p>
                      <h2>Mr. G. Sathiyan</h2>

                      <a href="tel:918973941369">
                        <span>
                          <img src={phone} alt="" />
                        </span>{" "}
                        +91 8973941369
                      </a>

                      <a href="mailto:sathiyang.chn@teamglobal.in">
                        <span>
                          <img src={email} alt="" />
                        </span>{" "}
                        sathiyang.chn@teamglobal.in
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="contact-details" id="contact-form">
              <h3>Fill Your Basic Details</h3>

              <form id="fsub" method="post" action="submit-contact">
                <div class="row">
                  <div class="form-group col-md-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div class="form-group col-md-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      title="please enter number only"
                      name="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>
                  <div class="form-group col-md-4 mb-3">
                    <input
                      type="email"
                      class="form-control"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div class=" col-md-4 form-group">
                    <select name="subject" class="form-control" required>
                      <option selected>Subject</option>
                      <option value="Sea-Freight-Export">
                        Sea Freight Export
                      </option>
                      <option value="Sea-Freight-Import">
                        Sea Freight Import
                      </option>
                      <option value="Air-Freight">Air Freight</option>
                      <option value="Project-Cargo">Project Cargo</option>
                      <option value="Container-Freight-Station">
                        Container Freight Station
                      </option>
                      <option value="Coastal-Shipping">Coastal Shipping</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <input
                      type="text"
                      class="form-control"
                      name="message"
                      required
                      placeholder="Your message here…"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <button class="btn btn-primary" name="submit" type="submit">
                      {" "}
                      Submit Details{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default Kolkata;
