import React, { useEffect } from "react";

import book from "../../../assets/images/book-icon.svg";
import service from "../../../assets/images/other-service-icon.svg";

import cargo from "../../../assets/images/sea-cargo-coonsolidation-banner.jpg";

import lcl from "../../../assets/images/lcl-opratior.svg";

import digital from "../../../assets/images/digital-offerings.svg";
import tool from "../../../assets/images/our-tools-icon.svg";
import Servicefooter from "../servicesfooter";

const SeaCargoConsolidation = () => {
  useEffect(() => {
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    document.title = "Sea Cargo Consolidation";

    // console.log("metaDescriptionTag",metaDescriptionTag);
    metaDescriptionTag.content = "Sea Cargo Consolidation";
  }, []);

  return (
    <>
      <div className="content">
        <section className="cargo-consolidation">
          <div className="container">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Services</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sea Cargo Consolidation
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between">
                  <h2 className="text-capitalize"> sea cargo consolidation</h2>
                  <div className="btn-wrap">
                    <a
                      href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                      target="_blank"
                      className="btn btn-book btn-primary"
                    >
                      <span>
                        <img src={book} />
                      </span>
                      Book Now
                    </a>
                    {/* <!--<a href="#" className="btn btn-primary">-->
                                            <!--	<span>-->
                                                <!--		<img src="assets/images/Quote-icon.svg">-->
                                                    <!--	</span>Request A Quote-->
                                            <!--</a>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="other-services-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4 left-nav">
                <div className="service-nav ">
                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={service} />
                      </span>
                      Other Services
                    </h6>

                    <ul className="service-list">
                      <li className="active">
                        <a href="sea-cargo-consolidation">
                          Sea Cargo Consolidation
                        </a>
                      </li>
                      <li>
                        <a href="sea-freight-forwarding">
                          Sea Freight Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="air-freight">Air Freight</a>
                      </li>
                      <li className="">
                        <a href="project-cargo-forwarding">
                          Project Cargo Forwarding
                        </a>
                      </li>
                      <li>
                        <a href="container-freight-station">
                          Container Freight Station
                        </a>
                      </li>
                      <li>
                        <a href="coastal-shipping">Coastal Shipping</a>
                      </li>
                      
                      {/* <li>
                       <a href="transporation">Transporation</a>
                      </li> */}
                      {/* <li>
                       <a href="warehousing">Warehousing</a>
                      </li> */}
                      {/* <li>
                       <a href="customs-clearance">Customs Clearance</a>
                      </li> */}
                      {/* <li>
                       <a href="po-management">Po Management</a>
                      </li> */}
                      {/* <li>
                       <a href="pick-pack">Pick & Pack</a>
                      </li> */}
                      {/* <li>
                        <a href="quality-control-inspection">
                        Quality Control & Inspection
                        </a>
                      </li> */}
                    </ul>
                  </div>

                  <div className="service-block">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} />
                      </span>
                      Ecommerce
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=eBooking"
                          target="_blank"
                        >
                          e-booking
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Shipping%20Instruction%20(SI)"
                          target="_blank"
                        >
                          E-SI
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx"
                          target="_blank"
                        >
                          E-BL
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Pro-forma%20Invoice"
                          target="_blank"
                        >
                          proforma invoice
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Cargo%20Arrival%20Notice%20(CAN)"
                          target="_blank"
                        >
                          E-CAN
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="e-do"
                          target="_blank"
                        >
                          E-DO
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://ecommerce.teamglobal.in/login.aspx?uid=Ready%20Reports"
                          target="_blank"
                        >
                          reports
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-capitalize"
                          href="http://tiva.teamglobal.in/"
                          target="_blank"
                        >
                          roadbridge
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="service-block mb-0">
                    <h6 className="service-title">
                      <span>
                        <img src={digital} />
                      </span>
                      Tools
                    </h6>

                    <ul className="service-list">
                      <li>
                        <a href="global-locations">Global Locations</a>
                      </li>
                      <li>
                        <a href="warehouse-locations">Warehouse Locations</a>
                      </li>
                      <li>
                        <a
                          href="inco-terms"
                          style={{ textTransform: "inherit" }}
                        >
                          INCO Terms
                        </a>
                      </li>
                      <li>
                        <a href="shipping-glossary">Shipping Glossary</a>
                      </li>
                      <li>
                        <a href="conutry-restrictions">Country Restrictions</a>
                      </li>
                      <li>
                        <a href="freight-calculator">Freight Calculator</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="consolidation-details">
                  <figure>
                    <img src={cargo} alt="Sea Cargo Coonsolidation Banner" />
                  </figure>

                  <h4>Outbound Consolidation, E-Console Segment</h4>
                  <p>
                    Our export Consolidation Program is designed to cater to
                    movement of LCL Shipments of all kinds, out of India. We
                    have LCL services out of the major port and ICDs of
                    NhavaSheva, Chennai, Kochi, Tuticorin, Delhi, Bangalore,
                    Ahmedabad and Kolkata.
                    <br />
                    <br />
                    Our expert operational staff carefully handles the LCL
                    cargoes at the warehouses to avoid damages at the time of
                    offloading and stacking. They ensure that the same are
                    properly stuffed into the containers and are adequately
                    secured to ensure safe passage.
                    <br />
                    <br />
                    We have 142 direct Export services Ex various ports and ICDs
                    in India making us the largest LCL operator in India. Our
                    large customer base and consistent volumes ensures that we
                    are able to maintain a very high schedule integrity.
                    <br />
                    <br />
                    We have dedicated Haz Cargo desk which helps our customers
                    ship their haz cargoes faster.We have an escalation matrix
                    in place ensuring quick resolution to service deviations.
                  </p>

                  <h4>Inbound Consolidation I-Console</h4>
                  <p>
                    Our Import Cargo Consolidation Programme is designed to meet
                    growing needs of Importers in India to bring their cargoes
                    in the shortest possible transit time keeping in mind the
                    constraint of Cost & Safety.
                    <br />
                    <br />
                    We have Direct Services from Global cargo origins to main
                    ports of NhavaSheva and Chennai.The other large ports of
                    Kolkota, Tuticorin and Kochi are covered through
                    International transhipment ports of Colombo, Singapore, Hong
                    Kong and Dubai. We fixed day departures Ex NhavaSheva to New
                    Delhi (ICD Patparganj and ICD Tughlakabad),ICD Ahmedabad and
                    Ex Chennai to ICD Bangalore and ICD Hyderabad.
                  </p>

                  {/* <!--<h4>Title 3 - Dummy Content</h4>-->
                                        <!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.<br><br>  We have Direct Services from Global cargo origins to main ports of Nava Sheva and Chennai. The other large ports of Kolkota, Tuticorin and Kochi are covered through international transshipment ports of Colombo, Singapore, Hong Kong and Dubai. We fixed day departures Ex Nava Sheva to New Delhi (ICD Patparganj and ICD Tughlakabad),ICD Ahmedabad and Ex Chennai to ICD Bangalore and ICD Hyderabad.</p>-->
                                        
                                        <!--<h4>Why Choose Team Global?</h4>-->
                                        <!--<p>Our Import Cargo Consolidation Programme is designed to meet growing needs of Importers in India to bring their cargoes in the shortest possible transit time keeping in mind the constraint of Cost. & Safety.</p>-->
                                    */}
                </div>

                <div className="most-service">
                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>
                      2500+ direct weekly services globally including 225
                      services from India
                    </strong>
                    {/* <!--<p>ICDs in India</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>Pan-India Presence across 18 locations</strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>

                  <div className="list-item ">
                    <span>
                      <img src={lcl} />
                    </span>
                    <strong>
                      Presence in 75 countries - expanding global coverage year
                      on year
                      <a
                        href="https://www.wwalliance.com/"
                        target="_blank"
                        style={{
                          paddingLeft: "0px",
                          color: " #0072BE",
                          display: "block",
                        }}
                      >
                        {" "}
                        WWA - WorldWideAlliance
                      </a>
                    </strong>
                    {/* <!--<p>Clients Worldwide, dummy content</p>--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Servicefooter/> */}
    </>
  );
};

export default SeaCargoConsolidation;
