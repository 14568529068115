import React from "react"

import Twlogo from '../../assets/images/TW-Logo.svg';
import Twcargo from '../../assets/images/twcargo-logo.png';
import wwalliance from "../../assets/images/wwwalliance.png";
import aircargogroup from "../../assets/images/air-cargo.png";
import gpun from "../../assets/images/gpun.png";
import globion from "../../assets/images/globicon.png"

import fiata from "../../assets/images/fiata-logo.png";
import location from "../../assets/images/locations.svg";

import lcl from "../../assets/images/lcl-fcl-air-cfs.svg"

const CompanyGroup = () => {

    return (<>

        <section className="companies-group">
            <div className="container">
                <div className="row">
                    <div className="col-12 pl-0 pr-0">
                        <div className="text-center d-md-flex align-items-md-center">
                            <ul className="list-unstyled cust-pl first-ul">
                                <li className="d-block" style={{ marginBottom: "10px" }}>
                                    <strong className="d-block d-md-inline-block text-capitalize" style={{ fontSize: "16px" }}> Group Companies</strong>
                                </li>
                                <li className="d-inline-block">
                                    <a href="https://www.globiconterminals.com/" target="_blank"><img src={globion} alt="globicon" className="img-fluid" /></a>
                                </li>
                                {/* <!--
										<li className="d-inline-block">
										    <a href="https://teamworld.in/" target="_blank"><img src="assets/images/teamworld.png" alt="teamworld"  className="img-fluid"/></a>
										</li>
--> */}
                                <li className="d-inline-block">
                                    <a href="https://teamworld.in/" target="_blank"><img src={Twlogo} alt="teamworld" className="img-fluid tw-logo" /></a>
                                </li>

                                <li className="d-inline-block">
                                    <a href="https://twcargo.net/" target="_blank"><img src={Twcargo} alt="teamworld" className="img-fluid tw-logo" /></a>
                                </li>

                            </ul>
                            <ul className="list-unstyled cust-pl second-ul">

                                <li className="d-block" style={{ marginBottom: "10px" }}>
                                    <strong className="d-block text-capitalize members-of" style={{ fontSize: "16px" }}> Members Of</strong>
                                </li>
                                <li className="d-inline-block">
                                    <a href="https://www.wwalliance.com/" target="_blank"><img src={wwalliance} alt="wwwalliance" className="img-fluid members-logo" /></a>
                                </li>
                                <li className="d-inline-block">
                                    <a href="http://aircargogroup.com/" target="_blank"><img src={aircargogroup} alt="air-cargo" className="img-fluid members-logo" /></a>
                                </li>
                                <li className="d-inline-block">
                                    <a href="https://gpln.net/" target="_blank"><img src={gpun} alt="gpun" className="img-fluid members-logo" /></a>
                                </li>
                                {/* <!--<li className="d-inline-block agln-logo">
										     <a href="http://www.agln.co/" target="_blank"><img src="assets/images/agln_logo.png" alt="agln_logo" className="img-fluid"/></a>
										 </li> --> */}
                                <li className="d-inline-block agln-logo">
                                    <a href="https://fiata.org/" target="_blank"><img src={fiata} alt="FIATA" className="img-fluid fiata-logo" /></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="companies-growth blue-strip">
            <div className="container">
                <div className="row cust-offset">
                    <div className="col-md-4 first-cust-col">
                        <span className="d-flex align-items-center">
                            <img src={location} alt="location" className="img-fluid left-img"/>
                                <span>
                                    <h4 className="growth-title">138 Locations</h4>
                                    <p className="desc"> Globally</p>
                                </span>
                        </span>


                    </div>
                    <div className="col-md-4 sec-cust-col">
                        <span className="d-flex align-items-center">
                            <img src={lcl} alt="location" className="img-fluid"/>
                                <span>
                                    <h4 className="growth-title">LCL, FCL, AIR, CFS</h4>
                                    <p className="desc">We Cover Them All</p>
                                </span>
                        </span>
                    </div>
                    {/* <!--
                    <div className="col-md-4 third-cust-col">
                        <span className="d-flex align-items-center">
                            <img src="assets/images/tg-team.svg" alt="location" className="img-fluid left-img">
                                <span>
                                    <h4 className="growth-title">700+ Team</h4>
                                    <p className="desc"> In Teamglobal</p>
                                </span>
                        </span>
                    </div>
--> */}
                </div>
            </div>
        </section>
    </>)
}

export default CompanyGroup