import React from "react";

// import location from "../../assets/images/location-icn-yellow.svg"
import location from "../../../assets/images/location-icn-yellow.svg";
// import phone from "../../../assets/images/phone-icn-yellow.svg";
// import email from "../../../assets/images/email-icn-yellow.svg";
import phone from '../../../assets/images/phone-icn.svg'
import email from "../../../assets/images/email-icn.svg";
import lofo from "../../../assets/images/lofo-flag.png"
import flag1 from "../../../assets/images/flag01.png"
import flag2 from "../../../assets/images/flag02.png"
import flag3 from "../../../assets/images/flag03.png"
import flag4 from "../../../assets/images/flag04.png"
import Servicefooter from "../../services/servicesfooter";
import { Link } from 'react-router-dom'
const Chittagong = () => {

	return (<>

		<div className="content">

			<section className="contact-head">
				<div className="cargo-consolidation about-txt">
					<div className="container">
						<div className="breadcrumb-wrap">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="index">Home</a></li>
									<li className="breadcrumb-item active" aria-current="page">Contact Us</li>
								</ol>
							</nav>

						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="d-md-flex align-items-center">
									<h2 className="text-capitalize">Contact Us</h2>

								</div>
							</div>
						</div>

					</div>
				</div>
			</section>


			<section className="contact-info-wrp">
				<div className="container">
					<ul className="country-list">
						<li className="list-item">
							<a href="contact" class="text-capitalize "> <span className="flag-icn"><img src={lofo} alt="" /></span>
								<span style={{ paddingTop: "2px" }}>Corporate Office</span></a>
						</li>
						<li className="list-item">
							<a href="#" class="text-capitalize"> <span class="flag-icn"><img src={flag1} alt="" /></span>
								<span style={{ paddingTop: "2px" }}>India</span></a>

							<ul className="country-sub-menu">

								<li><a href="/ahmedabad">Ahmedabad</a></li>
								<li><a href="bangalore">Bangalore</a></li>
								<li><a href="baroda">Baroda</a></li>
								<li><a href="chennai">Chennai</a></li>
								<li><a href="coimbatore">Coimbatore</a></li>
								{/* <li><a href="gandhidham">Gandhidham</a></li> */}
								<li><a href="hyderabad">Hyderabad</a></li>
								{/* <li><a href="jaipur">Jaipur</a></li> */}
								<li><a href="kochi">Kochi</a></li>
								<li><a href="kolkata">Kolkata</a></li>
								<li><a href="ludhiana">Ludhiana</a></li>
								<li><a href="mumbai">Mumbai</a></li>
								<li><a href="newdelhi">New Delhi</a></li>
								<li><a href="pune">Pune</a></li>
								{/* <li>
                    <a href="/surat">Surat</a>
                  </li> */}
								<li><a href="tirupur">Tirupur</a></li>
								<li><a href="tuticorin">Tuticorin</a></li>
								{/* <!--									<li style="border-top: 1px solid #eaeaea;"><strong>Agencies</strong></li>-->
                                <!--<li><a href="karur">Karur</a></li>-->
                                <!--									<li><a href="moradabad">Moradabad</a></li> --> */}

							</ul>

						</li>
						<li class="list-item">
							<a href="#" class="text-capitalize active"> <span class="flag-icn"><img src={flag2} alt="" /></span>
								<span style={{ paddingTop: "2px" }}>bangladesh</span></a>
							<ul class="country-sub-menu">
								<li><a href="chittagong">Chittagong</a></li>
								<li><a href="dhaka">Dhaka</a></li>
							</ul>
						</li>
						<li class="list-item">
							<a href="kenya" class="text-capitalize"> <span class="flag-icn"><img src={flag3} alt="" /></span>
								<span style={{ paddingTop: "2px" }}>Kenya</span></a>
						</li>
						<li class="list-item">
							<a href="tanzania" class="text-capitalize"> <span class="flag-icn"><img src={flag4} alt="" /></span>
								<span style={{ paddingTop: "2px" }}>Tanzania</span></a>
						</li>
					</ul>


					<div class="corporate-office">
						<div class="corporate-title d-md-flex justify-content-between align-items-center">
							<h4>Chittagong Office, Bangladesh</h4>
							<a href="#contact-form"><button class="btn btn-primary">Fill The Form</button></a>
						</div>

						<div class="corporate-teamglobal">
							<div class="row">
								<div class="col-md-6">
									<div class="address w-100">
										<figure>
											<img src={location} />
										</figure>
										<p class="mb-0">Ayub Trade Center(3Rd Floor),1269/B, Sk Mujib Road, Agrabad C/A, Chittagong-4100, Bangladesh

											{/* Tel No : 880 31 712141, 880 31 712142 , Fax No : 880 31 712143 */}
										</p>
									</div>
									<div class="address border-bottom pb-4 pt-md-4 d-flex align-items-center">
										<figure class="cust-fig">
											<img src={phone} />
										</figure>
										<p class="mb-0"><a href="tel:+880 31 712141" target="_blank" style={{ color: "#385B72" }}>+880 31712142 </a> <a href="tel+880 31 712142" target="_blank" style={{ color: "#385B72" }}>/ 42 </a><a href="tel:+880 2333312143" target="_blank" style={{ color: "#385B72" }}>/ 43</a></p>
									</div>
									<div class="address border-bottom pb-4 pt-md-4 d-flex align-items-center">
										<figure class="cust-fig">
											<img src={email} />
										</figure>
										<p class="mb-0"><a href="mailto:info@teamglobal.in" target="_blank" style={{ color: "#385B72" }}>info@teamglobal.in</a></p>
									</div>
								</div>
								<div class="col-md-6">
									{/* <!--<figure>-->
											<!--	<img src="assets/images/map-yellow.png">-->
											<!--</figure>--> */}
									{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7381.684765537438!2d91.811761!3d22.3218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acdf3566ed7b29%3A0x4d119907bc25ded!2sAyub%20Trade%20Centre%2C%201269%2FB%20Sheikh%20Mujib%20Rd%2C%20Chittagong%204100%2C%20Bangladesh!5e0!3m2!1sen!2sin!4v1619006259008!5m2!1sen!2sin" width="100%" height="160" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe> */}
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7381.48194254974!2d91.80705483944212!3d22.325634043311403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd949af0c3d4f%3A0x884ca1c2a397aff2!2sTeamglobal%20Logistics%20BD%20(Pvt)%20Ltd!5e0!3m2!1sen!2sin!4v1717243422086!5m2!1sen!2sin" width="100%" height="160" style={{ border: "0" }} allowfullscreen="" loading="lazy" ></iframe>
								</div>
							</div>
						</div>

						<div class="corporate-team">
							{/* <!--<h4><span> Directors </span></h4>--> */}
							<div class="row">
								<div class="col-md-4 col-lg-3">
									<div class="teamglobal-card">
										<p>Branch Manager</p>
										<h2>Mr. Arindam Chatterjee</h2>

										<a href="tel:880 1908411660">
											<span>
												<img src={phone} />
											</span> +880 1908411660
										</a>

										<a href="mailto:arindamc.bd@teamglobal.co">
											<span>
												<img src={email} />
											</span> arindamc.bd@teamglobal.co
										</a>
									</div>
								</div>


								{/* <!--<div class="col-md-4 col-lg-3">-->
										<!--	<div class="teamglobal-card">-->
										<!--		<p>Director</p>-->
										<!--		<h2>Mr. Rajesh Bhanushali</h2>-->
												 
										<!--		<a href="tel:919867019810" >-->
										<!--			<span>-->
										<!--				<img src={phone}/>-->
										<!--			</span> +91 98670 19810-->
										<!--		</a>-->
												
										<!--		<a href="mailto:rajesh@teamglobal.in">-->
										<!--			<span>-->
										<!--				<img src={email}/>-->
										<!--			</span> rajesh@teamglobal.in-->
										<!--		</a> -->
										<!--	</div>-->
										<!--</div>--> */}
								<div class="col-md-4 col-lg-3">
									<div class="teamglobal-card">
										<p>Operations Head</p>
										<h2>Mr. Monuchaher Iqbal</h2>

										<a href="tel:880 1908411640">
											<span>
												<img src={phone} />
											</span> +880 1908411640
										</a>

										<a href="mailto:iqbalm.bd@teamglobal.co">
											<span>
												<img src={email} />
											</span> iqbalm.bd@teamglobal.co
										</a>
									</div>
								</div>

							</div>
						</div>


						<div class="corporate-team">
							{/* <!--<h4><span>National Head</span></h4>--> */}
							<div class="row">
								<div class="col-md-4 col-lg-3">
									<div class="teamglobal-card">
										<p>Sea Freight Export</p>
										<h2>Mr. SK. Sohel Ahmed</h2>

										<a href="tel:8801908411641">
											<span>
												<img src={phone} />
											</span> +8801908411641
										</a>

										<a href="mailto:sohelm.bd@teamglobal.co">
											<span>
												<img src={email} />
											</span> sohelm.bd@teamglobal.co
										</a>
									</div>
								</div>
								<div class="col-md-4 col-lg-3">
									<div class="teamglobal-card">
										<p>Sea Freight Import</p>
										<h2>Mr. Didarul Alam</h2>

										<a href="tel:880 1908411652">
											<span>
												<img src={phone} />
											</span> +880 1908411652
										</a>

										<a href="mailto:didarula.bd@teamglobal.co">
											<span>
												<img src={email} />
											</span> didarula.bd@teamglobal.co
										</a>
									</div>
								</div>

								<div class="col-md-4 col-lg-3">
									<div class="teamglobal-card">
										<p>Air Freight</p>
										<h2>Mr. Saiful</h2>

										<a href="tel:8801908411671">
											<span>
												<img src={phone} />
											</span> +8801908411671
										</a>

										<a href="mailto:saif.bd@teamglobal.co">
											<span>
												<img src={email} />
											</span> saif.bd@teamglobal.co
										</a>
									</div>
								</div>


							</div>
						</div>

					</div>

					<div class="contact-details" id="contact-form">
						<h3>Fill Your Basic Details</h3>

						<form id="fsub" method="post" action="submit-contact">
							<div class="row">

								<div class="form-group col-md-4 mb-3">
									<input type="text" class="form-control" name="name" placeholder="Name" required />
								</div>
								<div class="form-group col-md-4 mb-3">
									<input type="text" class="form-control" title="please enter number only" name="phone" placeholder="Phone" required />
								</div>
								<div class="form-group col-md-4 mb-3">
									<input type="email" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" placeholder="Email" required />
								</div>
								<div class=" col-md-4 form-group">
									<select name="subject" class="form-control" required>
										<option selected>Subject</option>
										<option value="Sea-Freight-Export">Sea Freight Export</option>
										<option value="Sea-Freight-Import">Sea Freight Import</option>
										<option value="Air-Freight">Air Freight</option>
										<option value="Project-Cargo">Project Cargo</option>
										<option value="Container-Freight-Station">Container Freight Station</option>
										<option value="Coastal-Shipping">Coastal Shipping</option>
									</select>
								</div>
								<div class="form-group col-md-4">
									<input type="text" class="form-control" name="message" required placeholder="Your message here…" />
								</div>

								<div class="form-group col-md-4">
									<button class="btn btn-primary" name="submit" type="submit"> Submit Details </button>
								</div>

							</div>

						</form>



					</div>


				</div>






			</section>




		</div>
		{/* <Servicefooter/> */}
	</>)
}

export default Chittagong